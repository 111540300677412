<template>
  <div>
    <div ref="map" class="map-container"></div>
  </div>
</template>

<script>
import { http } from "@swipekit/lib/http";

export default {
  name: "GoogleMapWithCustomMarkers",
  props: ["apiKey"],
  data() {
    return {
      venues: [],
      rounds: [],
      map: null,
    };
  },
  methods: {
    initMap: async function () {
      // Define a custom overlay class
      class CustomMarker extends google.maps.OverlayView {
        constructor(position, map, html) {
          super();
          this.position = position;
          this.map = map;
          this.html = html;
          this.setMap(map);
        }

        draw() {
          if (!this.div) {
            this.div = document.createElement("div");
            this.div.style.position = "absolute";
            this.div.innerHTML = this.html;

            const panes = this.getPanes();
            panes.overlayLayer.appendChild(this.div);
          }

          const point = this.getProjection().fromLatLngToDivPixel(
            this.position
          );
          if (point) {
            this.div.style.left = `${point.x}px`;
            this.div.style.top = `${point.y}px`;
          }
        }

        remove() {
          if (this.div) {
            this.div.parentNode.removeChild(this.div);
            this.div = null;
          }
        }

        getPosition() {
          return this.position;
        }
      }

      await this.loadVenues();
      await this.loadRounds();

      const mapOptions = {
        zoom: 10,
        center: new google.maps.LatLng(-37.8136, 144.9631), // Centrally located in the US
      };
      this.map = new google.maps.Map(this.$refs.map, mapOptions);

      let locations = this.venues.map((venue) => {
        let name = venue.venue_name.slice(0, 10);
        const html = `<div style="padding: 3px; background: #0a7ff5; border: none;border-radius:3px;font-size:8px;color:#fff;">${name}</div>`; // Customize your HTML here
        const overlay = new CustomMarker(
          new google.maps.LatLng(venue.lat, venue.lng),
          this.map,
          html
        );

        overlay.addListener("click", () => {
          this.onMarkerClick(location.id);
        });
      });

      let round = null;
      let round_id = 336;

      for (let i = 0; i < this.rounds.length; i++) {
        let r = this.rounds[i];

        if (r.round_id === round_id) {
          round = r;
          break;
        }
      }

      if (!round) {
        return;
      }
      this.plotRound(round);
    },
    onMarkerClick: function (locationId) {
      console.log(locationId);
    },
    plotRound: function (round) {
      let additional_data = round.additional_data;

      if (!additional_data) {
        return;
      }

      if (!additional_data.routeProgress) {
        return;
      }

      let polylinePoints = additional_data.routeProgress;

      const polylinePath = polylinePoints.map(
        (point) => new google.maps.LatLng(point.lat, point.lng)
      );
      const polyline = new google.maps.Polyline({
        path: polylinePath,
        geodesic: true,
        strokeColor: "#FF0000",
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });

      polyline.setMap(this.map);
    },
    loadVenues: async function () {
      let res = await http.get("/venue");

      let venues = [];

      if (res.data) {
        venues = res.data;
      }

      this.venues = venues;
    },
    loadRounds: async function () {
      let res = await http.get("/round", {
        params: {
          override: 50,
        },
      });

      let rounds = [];

      if (res.data) {
        rounds = res.data;
      }

      this.rounds = rounds;
    },
  },
  mounted() {
    if (!window.google) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&callback=initMap`;
      script.async = true;
      script.defer = true;
      window.initMap = this.initMap;
      document.head.appendChild(script);
    } else {
      this.initMap();
    }
  },
};
</script>

<style scoped>
.map-container {
  width: 100vw;
  height: 100vh;
}
</style>
