<template>
  <form class="c-form-site-estimate" @submit.prevent="onSubmit">
    <Form
      ref="form"
      :schema="schema"
      v-model="currentForm"
      @status="saveStatus"
      buttonText="Update Area"
      :processing="processing"
    ></Form>
  </form>
</template>

<script>
import ageEncoder from "@swipekit/lib/age-encoder";

const schema = {
  estimate: {
    component: "InputNumber",
    label: "Estimated Patronage Visits",
    min: 0,
    max: 99999,
    step: 20,
  },
};

export default {
  components: {
    Form: () => {
      return import("./index.vue");
    },
  },

  data: function () {
    return {
      processing: false,
      currentForm: {},
      status: null,
    };
  },

  props: {
    form: {},
  },

  watch: {
    form: {
      immediate: true,
      handler: function () {
        if (this.form) {
          let temp = JSON.parse(JSON.stringify(this.form));

          temp.age_group = ageEncoder.decode(temp.age_group);

          temp.hours = [];

          if (temp.additional_data && temp.additional_data.hours) {
            temp.hours = temp.additional_data.hours || [];

            delete temp.additional_data;
          }

          this.currentForm = temp;
        }
      },
    },
  },

  computed: {
    schema: function () {
      let s = schema;

      return s;
    },
  },

  methods: {
    reset: function () {},
    saveStatus: function (saveStatus) {
      this.status = saveStatus;
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      this.processing = true;

      let form = this.currentForm;

      form.age_group = ageEncoder.encode(form.age_group);

      form.additional_data = form.additional_data || {};

      form.additional_data.hours = form.hours || [];

      delete form.hours;

      let newSite = {
        additional_data: form.additional_data,
        age_group: form.age_group,
        gender_skew: form.gender_skew,
        name: form.name,
        site_id: this.form.site_id,
        item_id: form.item_id,
      };

      let response = await this.$store
        .dispatch("venues/updateSite", newSite)
        .catch((err) => {
          if (err.response.data) {
            this.errors = err.response.data;
          }
          this.processing = false;

          console.log(this.errors);

          this.$store.dispatch("message/onFail", this.errors);

          return;
        });

      this.$store.dispatch("message/onSiteUpdated");

      this.processing = false;

      this.$emit("onSubmit", form);
    },
  },

  mounted: function () {},
};
</script>

<style lang="scss">
.c-form-site-estimate {
  .form-group {
    > .btn {
      display: none;
    }
  }
}
</style>
