import CrudStore from "@swipekit/lib/crud-store";

let crud = new CrudStore({
  name: "ads",
  singular: "ad",
});

const state = {
  ...crud.state,

  viewCache: [],

  selection: [],
};
const getters = {
  ...crud.getters,

  byBoard: function (state) {
    let ads = state.resources;
    return function (boardId) {
      let boardBlocks = [];

      for (let i = 0; i < ads.length; i++) {
        if (!ads[i].boards) {
          continue;
        }
        if (ads[i].boards.length === 0) {
          continue;
        }

        for (let j = 0; j < ads[i].boards.length; j++) {
          if (ads[i].boards && ads[i].boards[j].boardId === boardId) {
            boardBlocks.push(ads[i]);
            break;
          }
        }
      }

      return boardBlocks;
    };
  },

  viewCache: function (state) {
    return state.viewCache;
  },

  selection: function (state) {
    return state.selection;
  },
};
const actions = {
  ...crud.actions,

  async doAction(store, obj) {
    let action = obj.action;
    let id = obj.id;
    let form = obj.form || null;
    let response = await crud.api.ads.doAction(id, action, form);

    if (response) {
      if (Array.isArray(response)) {
        for (let i = 0; i < response.length; i++) {
          store.commit("UPDATE", response[i]);
        }
      } else {
        store.commit("UPDATE", response);
      }
    }

    return response;
  },

  async removeBoard(store, boardId) {
    let resources = [];

    let blocks = store.state.resources;
    for (let i = 0; i < blocks.length; i++) {
      let change = false;
      blocks[i].boards = blocks[i].boards.filter((b) => {
        if (b.boardId === boardId) {
          change = true;
          return false;
        } else {
          return true;
        }
      });
      if (change) {
        resources.push(blocks[i]);
      }
    }

    store.commit("BULK_UPDATE", resources);
  },

  async addSelection(store, id) {
    store.state.selection.push(id);
  },

  async removeSelection(store, id) {
    let selection = store.state.selection;

    let i = selection.indexOf(id);
    if (i > -1) {
      selection.splice(i, 1);

      store.state.selection = selection;
    }
  },

  async clearSelection(store) {
    store.state.selection = [];
  },

  async setViewCache(store, filteredBlocks) {
    store.commit("VIEW_CACHE", filteredBlocks);
  },

  consumePie: async (store, pie) => {
    store.commit("SET", pie.workspace.ads || []);
  },
};
const mutations = {
  ...crud.mutations,

  VIEW_CACHE: function (state, filteredBlocks) {
    state.viewCache = filteredBlocks;
  },
};

let store = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default store;
