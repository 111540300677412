<template>
  <div class="form-group c-input-tags">
    <label v-if="label" class="form__label" :for="slug">{{ label }}</label>
    <vue-tags-input
      v-model="tag"
      :placeholder="placeholder"
      :tags="tags"
      :validation="validation"
      :autocompleteItems="items"
      @tags-changed="onTagsChanged"
    >
    </vue-tags-input>
    <InputError v-if="hasError" :message="errorMessage"></InputError>
    <InputDescription v-if="description" :message="description"></InputDescription>
  </div>
</template>

<script>
import InputError from "./input-error";
import InputDescription from "./input-description";
import slugify from "@swipekit/lib/slugify.js";

import VueTagsInput from "@johmun/vue-tags-input";

export default {
  components: {
    InputError,
    InputDescription,
    VueTagsInput,
  },

  data: function () {
    return {
      tag: "",
      tags: [],
      validation: [],
    };
  },

  watch: {
    tags: function () {
      this.$emit("input", this.cleanTags(this.tags));
    },
    value: function () {
      let valueArr = this.value || [];

      let currentArr = this.tags || [];

      if (valueArr.length !== currentArr.length) {
        this.tags = this.value;
      }
    },
  },

  props: {
    label: {
      type: String,
      default: "",
    },
    value: {},
    placeholder: {
      type: String,
      default: "",
    },
    noStyle: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: "",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {},
    autocompleteItems: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },

  computed: {
    slug: function () {
      return slugify(this.label || `input_${Math.floor(Math.random() * 100)}`);
    },
    viewCache: function () {
      return this.$store.getters["blocks/viewCache"];
    },
    items: function () {
      let items = this.autocompleteItems || [];
      let viewCache = this.viewCache || [];

      if (!viewCache) {
        return items;
      }

      for (let i = 0; i < viewCache.length; i++) {
        let tags = viewCache[i].tags;

        if (tags) {
          items = items.concat(tags);
        }
      }

      items = this.trimTags(items);

      return items;
    },
  },

  methods: {
    trimTags: function (tags) {
      tags = [...new Map(tags.map((item) => [item["text"], item])).values()];
      return tags;
    },
    onTagsChanged: function (newTags) {
      this.tags = newTags;
    },
    cleanTags: function (tags) {
      for (let i = 0; i < tags.length; i++) {
        if (tags[i].tiClasses) {
          delete tags[i].tiClasses;
        }
      }

      return tags;
    },
  },

  mounted: function () {
    if (this.value && this.value.length > 0) {
      let tags = this.value;
      for (let i = 0; i < tags.length; i++) {
        tags[i].tiClasses = [];
      }
      this.tags = tags;
    }
  },
};
</script>

<style lang="scss">
.c-input-tags {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: var(--margin-lg);

  .vue-tags-input {
    width: 100%;
    max-width: initial;
    background-color: transparent !important;

    .ti-input {
      border: none;
      min-height: 50px;
      padding: 0;
    }
    .ti-autocomplete {
      margin-left: var(--margin-sm);
      width: calc(100% - var(--margin-sm) * 2);
      border-radius: var(--border-radius);
      background-color: var(--color-bg-4);
      border: var(--color-bg-2) solid 1px;
      overflow: hidden;
      font-size: var(--font-size-xs);

      li {
        //padding: var(--margin-sm) var(--margin);
        margin-bottom: 0;

        &:not(:last-child) {
          border-bottom: var(--color-bg-2) solid 1px;
        }

        &.ti-selected-item {
          background-color: var(--color-primary);
        }
      }
    }
    .ti-tag {
      margin: 2px;
      cursor: pointer;
      padding: var(--margin) var(--spacer-sm);
      padding-right: var(--margin-lg);
      background-color: var(--color-button-primary-light);
      border-radius: var(--border-radius-full);

      display: flex;
      justify-content: center;
      align-items: center;

      letter-spacing: 0.3px;
      font-size: var(--font-size-sm);

      &:hover,
      &:active {
        opacity: 0.85;
      }
    }
    .ti-tags {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      line-height: 1em;
      padding: var(--margin);
      border-radius: var(--border-radius);
      border: var(--color-input-background) solid 1px;
      background-color: transparent;
      list-style: none;
      box-sizing: border-box;

      .ti-new-tag-input-wrapper {
        display: flex;
        flex: 1 0 auto;
        padding: 3px 5px;
        margin: 2px;
        font-size: var(--font-size);
        margin: 0;
        padding: 0;
        background-color: transparent;

        input {
          padding-left: var(--margin);
          -webkit-appearance: none;
          background-color: transparent;
          color: var(--color-font);
          min-height: 32px;
          width: 100%;
          box-sizing: border-box;
          border: none;

          &:focus {
            outline: none;
          }
        }
      }
    }

    [class^="ti-icon-"] {
      font-family: "icomoon" !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
    }
    .ti-icon-close {
      &:before {
        content: "\e901";
      }
    }
  }
}
</style>
