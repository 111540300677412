<template>
  <div class="form-group c-input-select">
    <label v-if="label" class="form__label" :for="slug">{{ label }}</label>
    <select
      v-if="computedOptions"
      :class="[{ 'form-control': noStyle === false }]"
      :id="slug"
      v-model="text"
      :placeholder="placeholder"
    >
      <option
        v-for="option in computedOptions"
        :key="option.value"
        :value="option.value"
        :selected="option.value === text"
      >
        {{ option.key || option.value }}
      </option>
    </select>
    <InputError v-if="hasError" :message="errorMessage"></InputError>
    <strong class="custom-error" v-if="customError">{{ customError }}</strong>
    <InputDescription
      v-if="description"
      :message="description"
    ></InputDescription>
  </div>
</template>

<script>
import InputError from "./input-error";
import InputDescription from "./input-description";
import slugify from "@swipekit/lib/slugify.js";

export default {
  components: {
    InputError,
    InputDescription,
  },

  data: function () {
    return {
      text: "",

      customError: "",
    };
  },

  watch: {},

  props: {
    label: {
      type: String,
      default: "",
    },
    validation: {},
    value: {},
    placeholder: {
      type: String,
      default: "",
    },
    noStyle: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: "",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {},
    type_id: {},
  },

  watch: {
    text: function () {
      this.$emit("input", this.text);
    },
    value: function () {
      let currentValue = this.text;

      let value = this.value;

      if (value !== currentValue) {
        this.text = value;
      }
    },
  },

  computed: {
    options: function () {
      let lists = this.$store.getters["lists/parent"];

      lists = lists.map((list) => {
        return {
          key: list.label,
          value: list.item_id,
        };
      });

      return lists;
    },
    computedOptions: function () {
      let options = this.options;

      let typeId = this.type_id;

      if (!typeId) {
        return options;
      }

      let category = this.$store.getters["listTypes/category"](typeId);

      if (!category) {
        console.log(`THIS VENUE HAS BEEN CATEGORIZED INCORRECTLY!! ${typeId}`);
        this.customError = `This venue has been categorized incorrectly. Please contact operations team to ensure this venue is categorized correctly.`;
        // hack, show blank but submit typeId as the value
        //this.$emit("input", typeId);
        return [];
      }

      let items = this.$store.getters["lists/subCategory"](category.type_id);

      items = JSON.parse(JSON.stringify(items));

      items = items.map((i) => {
        return {
          key: i.label,
          value: i.item_id,
        };
      });

      return items;
    },
    slug: function () {
      return slugify(this.label || `input_${Math.floor(Math.random() * 100)}`);
    },
  },

  mounted: function () {
    if (this.value) {
      this.text = this.value;
    }
    if (typeof this.value === "number" && this.value === 0) {
      this.text = this.value;
    }
  },
};
</script>

<style lang="scss">
.c-input-select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: var(--margin-lg);

  .custom-error {
    color: var(--color-danger);
  }
}
</style>
