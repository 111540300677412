<template>
  <div class="c-profile">
    <PageHeader title="Update Venue"></PageHeader>
    <Venue></Venue>
  </div>
</template>

<script>
import PageHeader from "@swipekit/components/app/page-header.vue";

import Venue from "@swipekit/components/venue";

export default {
  name: "Home",
  components: {
    PageHeader,

    Venue,
  },

  data: function () {
    return {
      form: {},
      processing: {
        upgrade: false,
      },
    };
  },

  computed: {
    isPaid: function () {
      return this.$store.getters["users/isPaid"];
    },
    trialStartedDifference: function () {
      let diff = this.$store.getters["users/trialStartedDifference"];

      return diff;
    },
    isTrialOver: function () {
      return this.$store.getters["users/isTrialOver"];
    },
    baseUrl: function () {
      return this.$store.state.config.baseUrl;
    },
    user: function () {
      return this.$store.getters["users/user"];
    },
    computedPlan: function () {
      let plan = this.user.plan;

      plan = plan.toLowerCase();

      plan = plan.charAt(0).toUpperCase() + plan.slice(1);

      return plan;
    },
    paramId: function () {
      return this.$route.params.id;
    },
    venue: function () {
      let paramId = this.paramId;
      if (!paramId) {
        return null;
      }

      return this.$store.getters["venues/one"](paramId);
    },
  },

  methods: {
    async gotoBillingPortal() {
      let res = await api.users.portal();

      if (res.portalUrl) {
        window.open(res.portalUrl, "_blank").focus();
      }
    },
    async upgrade() {
      this.processing.upgrade = true;
      await this.$store.dispatch("users/upgrade", "monthly");
    },
    logout: function () {
      this.$store.dispatch("users/logout");
    },
  },

  mounted: async function () {
    let venue = this.venue;

    if (venue) {
      let form = JSON.parse(JSON.stringify(venue));

      if (venue.status === 0) {
        await this.$store
        .dispatch("venues/update", {
          venue_id: venue.venue_id,
          status: 1,
        })
        .catch((err) => {
          if (err.response.data) {
            this.errors = err.response.data;
          }

          this.$store.dispatch("message/onFail", this.errors);

          return;
        });
      }

      this.form = form;
    }
  },
};
</script>

<style lang="scss">
.c-profile {
  padding-bottom: var(--spacer);

  .container {
    padding: 0 var(--spacer-sm);
    margin: 0 auto;
  }

  &__header {
    padding-top: var(--spacer);
  }

  &__account {
    padding: var(--spacer);
    border: var(--color-input-background) solid 1px;
    border-radius: var(--border-radius);

    li {
      margin-bottom: var(--margin-lg);
    }

    .c-spinner {
      margin-right: var(--margin-lg);
    }
  }
}
</style>
