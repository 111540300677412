import api from "@swipekit/lib/api.js";

import CrudStore from "@swipekit/lib/crud-store";

let crud = new CrudStore({
  name: "listTypes",
  singular: "listType",
});

const state = {
  ...crud.state,
  isSingleton: false,
};
const getters = {
  ...crud.getters,

  /**
   * Returns only a single resource
   */
  one: function (state) {
    let resources = state.resources;
    return (id) => {
      let resource = null;
      id = parseInt(id);

      // if (isNaN(id)) {
      //   return null;
      // }

      for (let i = 0; i < resources.length; i++) {
        if (resources[i].venue_id === id) {
          resource = resources[i];
          break;
        }
      }

      return resource;
    };
  },

  category: function (state) {
    let resources = state.resources;

    return (id) => {
      let resource = null;

      id = parseInt(id);

      for (let i = 0; i < resources.length; i++) {
        //console.log(resources[i].type_id, id);
        if (resources[i].type_id === id) {
          resource = resources[i];
          break;
        }
      }

      return resource;
    };
  },
};
const actions = {
  ...crud.actions,

  async getLocal(store) {
    let resources = [];
    try {
      resources = await crud.api[crud.resourceName].get("local");
    } catch (err) {
      throw err;
    }

    store.commit("SET", resources);
    return resources;
  },

  async doAction(store, obj) {
    let action = obj.action;
    let id = obj.id;
    let response = await crud.api.api.doAction(id, action);

    return response;
  },

  async fetchAndStore(store) {
    let resources = (await crud.api[crud.resourceName].fetchAndStore()) || [];

    store.commit(resources);
  },

  consumePie: async (store, pie) => {
    if (pie.workspace) {
      let workspace = {
        ...pie.workspace,
      };

      delete workspace.boards;
      delete workspace.ads;
      store.commit("SET", workspace);
    }
  },

  /**
   * Gets all resources from the server
   */
  async get(store, resources = []) {
    if (resources.length === 0) {
      try {
        resources = await api.listTypes.get();
      } catch (err) {
        throw err;
      }
    }
    store.commit("SET", resources);
    return resources;
  },
};
const mutations = {
  ...crud.mutations,
};

let store = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default store;
