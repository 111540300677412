<template>
  <div class="form-group c-input-upload">
    <label v-if="label" class="form__label" :for="slug">{{ label }}</label>
    <div class="c-input-upload__inner">
      <div class="c-input-upload__image">
        <img v-if="fileName" :src="imageUrl" />
        <span></span>
      </div>

      <input
        :class="[{ 'form-control': noStyle === false }]"
        :id="slug"
        type="file"
        @change="onChange"
      />
    </div>
    <InputError v-if="hasError" :message="errorMessage"></InputError>
    <InputDescription
      v-if="description"
      :message="description"
    ></InputDescription>
  </div>
</template>

<script>
import InputError from "./input-error";
import InputDescription from "./input-description";
import slugify from "@swipekit/lib/slugify.js";

export default {
  components: {
    InputError,
    InputDescription,
  },

  data: function () {
    return {
      currentImage: "",
      fileName: "9932831f67-0.jpeg",
    };
  },

  watch: {},

  props: {
    label: {
      type: String,
      default: "",
    },
    validation: {},
    value: {},
    placeholder: {
      type: String,
      default: "",
    },
    noStyle: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: "",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {},
  },

  watch: {
    value: function () {
      let currentValue = this.fileName;

      let value = this.value;

      if (value !== currentValue) {
        this.fileName = value;
      }
    },
  },

  computed: {
    assetUrl: function () {
      return this.$store.getters["config/assetUrl"];
    },
    imageUrl: function () {
      if (this.currentImage) {
        return this.currentImage;
      }

      let url = `${this.assetUrl}/${this.fileName}`;

      return url;
    },
    slug: function () {
      return slugify(this.label || `input_${Math.floor(Math.random() * 100)}`);
    },
  },

  methods: {
    onChange: function (e) {
      let files = e.target.files;

      let file = files[0];

      if (!file) {
        return;
      }

      let reader = new FileReader();

      reader.onload = (ee) => {
        this.currentImage = ee.target.result;
      };

      reader.readAsDataURL(file);

      this.$emit("input", file);
    },
  },

  mounted: function () {
    if (this.value) {
      if (typeof this.value === "object") {
        this.fileName = this.value.name;
      } else {
        this.fileName = this.value;
      }
    }
  },
};
</script>

<style lang="scss">
.c-input-upload {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: var(--margin-lg);

  &__image {
    position: relative;
    width: var(--spacer-lg);
    min-width: var(--spacer-lg);
    height: var(--spacer-lg);
    border-radius: var(--border-radius);
    margin-right: var(--margin-lg);

    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  &__inner {
    display: flex;
    margin-bottom: 10px;

    .form-control {
      width: 100%;
      height: var(--spacer-lg);
      margin-bottom: 0 !important;
      padding: var(--margin-lg);
    }
  }
}
</style>
