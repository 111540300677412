import Vue from "vue";
import App from "./app.vue";
import router from "./router";
import { VueMasonryPlugin } from "vue-masonry";
import store from "@/store";
import VueCarousel from "vue-carousel";
import * as Sentry from "@sentry/vue";
import VTooltip from "v-tooltip";
import PortalVue from "portal-vue";

Vue.use(PortalVue);
Vue.use(VTooltip);
Vue.use(VueCarousel);

Vue.config.productionTip = false;

Vue.use(VueMasonryPlugin);

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  release: `venue-caams@${process.env.VUE_APP_VERSION}`,
  dist: process.env.VUE_APP_BUILD_NUMBER,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
    Sentry.captureConsoleIntegration({
      levels: ["error"],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "https://api-staging.conads.com.au", "https://api.conads.com.au"],
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: process.env.NODE_ENV === "development" ? 0 : 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  logErrors: true,
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
