let schema = {
  visitors: {
    component: "InputNumber",
    label: "On average, how many pregnant mothers do you see per month?",
    min: 0,
    max: 500,
  },

  non_english: {
    component: "InputRadioGroup",
    label: "Do you see patients who speak languages other than English",
    options: [
      {
        key: "Yes",
        value: 1,
      },
      {
        key: "No",
        value: 0,
      },
    ],
  },

  languages: {
    component: "InputText",
    label: "If yes, please select the 3 most common languages",
  },

  clarity: {
    component: "InputRadioGroup",
    label:
      "Do you think these posters are clear for your English speaking audience?",
    options: [
      {
        key: "Confusing",
        value: 0,
      },
      {
        key: "Unclear",
        value: 1,
      },
      {
        key: "Ok",
        value: 2,
      },
      {
        key: "Clear",
        value: 3,
      },
      {
        key: "Very well",
        value: 4,
      },
    ],
  },

  reasonance: {
    component: "InputRadioGroup",
    label: "How well do these images resonate with your audience?",
    options: [
      {
        key: "Confusing",
        value: 0,
      },
      {
        key: "Unclear",
        value: 1,
      },
      {
        key: "Ok",
        value: 2,
      },
      {
        key: "Clear",
        value: 3,
      },
      {
        key: "Very",
        value: 4,
      },
    ],
  },

  changes: {
    component: "InputRadioGroup",
    label: "Would you make any changes",
    options: [
      {
        key: "Yes",
        value: 1,
      },
      {
        key: "No",
        value: 0,
      },
    ],
  },

  notes: {
    component: "InputEditor",
    label: "Notes",
  },
};

export default schema;
