<template>
  <form class="c-form-venue" @submit.prevent="onSubmit">
    <Form
      ref="form"
      :schema="schema"
      v-model="formData"
      @status="saveStatus"
      @formInput="onFormInput"
      buttonText="Update Venue"
      :processing="processing"
    ></Form>
  </form>
</template>

<script>
import Form from "./index.vue";
import venueToDay from "@swipekit/lib/venue-to-day";
import dayToVenue from "@swipekit/lib/day-to-venue";

const schema = {
  main_category_id: {
    component: "InputCategory",
    label: "Category",
    disabled: true,
  },

  // ABN
  business_number: {
    component: "InputText",
    label: "ABN",
    description: "Venue's business ABN. 11 digits. No spaces",
    validations: {
      // required: {
      //   params: null,
      //   message: "An ABN is required",
      // },
      minLength: {
        params: 11,
        message: "Give a proper ABN, minimum 11 characters",
      },
      maxLength: {
        params: 11,
        message: `Give a proper ABN, minimum 11 characters`,
      },
    },
  },

  days: {
    component: "InputHours",
    label: "Please ask the Venue Manager to verify the trading hours",
    hideMaint: true,
  },
};

export default {
  components: {
    Form,
  },

  data: function () {
    let venue = this.$store.getters["venues/one"](this.form.venue_id);

    // if (venue && venue.additional_data && venue.additional_data.contact) {
    //   this.form.first_name = venue.additional_data.contact.first_name || "";
    //   this.form.last_name = venue.additional_data.contact.last_name || "";
    //   this.form.salutation = venue.additional_data.contact.salutation || "";
    //   this.form.title = venue.additional_data.contact.position || "";
    //   this.form.contactPhone = venue.additional_data.contact.phone || "";
    //   this.form.contactMobile = venue.additional_data.contact.mobile || "";
    //   this.form.contactEmail = venue.additional_data.contact.email || "";
    // }

    return {
      processing: false,
      status: null,
      formData: {},
    };
  },

  watch: {
    form: {
      handler: function () {
        let f = JSON.parse(JSON.stringify(this.form));

        let data = venueToDay(f);

        if (data.business_number) {
          data.business_number = data.business_number.replace(/\s/g, "");
        }

        this.formData = data;
      },
      immediate: true,
      deep: true,
    },
  },

  props: {
    form: {},
    isCope: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    schema: function () {
      let s = schema;

      if (this.isCope) {
        delete s.business_number.validations.required;
      } else {
        s.business_number.validations.required = {
          params: null,
          message: "An ABN is required",
        };
      }

      return s;
    },
  },

  methods: {
    onFormInput: function (data) {
      if ((data.keys = "days")) {
        this.formData.always_open = data.value;
      }
    },
    reset: function () {},
    saveStatus: function (saveStatus) {
      this.status = saveStatus;
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      this.processing = true;

      let form = this.formData;

      let additional_data = {};

      let obj = dayToVenue(form.days);

      form = {
        ...obj,
        ...form,
      };

      form = {
        venue_id: form.venue_id,
        business_number: form.business_number,
        additional_data: additional_data,
        days: form.days,
        always_open: form.always_open || false,
      };

      if (!form.days) {
        delete form.days;
      }

      if (typeof form.overview_image === "object") {
        delete form.overview_image;
      }

      let res = await this.$store
        .dispatch("venues/update", form)
        .catch((err) => {
          if (err.response.data) {
            this.errors = err.response.data;
          }
          this.processing = false;

          this.$store.dispatch("message/onFail", this.errors);
        });

      if (!res) {
        return;
      }

      this.$store.dispatch("message/onVenueUpdated");

      this.reset();

      this.$emit("onSubmit");

      this.processing = false;
    },
  },

  mounted: function () {},
};
</script>

<style lang="scss"></style>
