<template>
  <div
    :class="[
      'form-group c-input-hours',
      { 'hide-maint': hideMaint === true },
      { 'show-patronage': showPatronage === true },
      { alwaysOpen: alwaysOpen === true },
    ]"
  >
    <label v-if="label" class="form__label" :for="slug">{{ label }}</label>

    <div class="c-input-hours__question" v-if="askQuestion">
      <span>Are the hours same as the venue trading hours?</span>
      <a class="btn btn-sm" href="#" @click.prevent="onYes"> Yes </a>
    </div>

    <div class="c-input-hours__input">
      <div class="c-input-hours__days c-input-hours__days--header">
        <div class="c-input-hours__cell c-input-hours__cell--days">
          <span> Trading days </span>
          <br />
          <small> Please tick </small>
        </div>
        <div class="c-input-hours__cell c-input-hours__cell--hours">
          <span> Trading hours </span>
          <br />
          <small>From / To</small>
        </div>
        <div
          class="c-input-hours__cell c-input-hours__cell--maint"
          v-if="!hideMaint"
        >
          <span> Maintenance hours </span>
          <br />
          <small>From / To</small>
        </div>
        <div
          class="c-input-hours__cell c-input-hours__cell--patronage"
          v-if="showPatronage"
        >
          <span>Patronage</span>
          <br />
          <small>Estimated number of patron visits per day</small>
        </div>
      </div>
      <div
        class="c-input-hours__days"
        @click="onCheckboxClick(day, $event)"
        v-for="day in days"
        :key="day.slug"
      >
        <div class="c-input-hours__cell c-input-hours__cell--days">
          <div
            :class="[
              'c-input-checkbox__item',
              { active: isSelected(day.slug) },
            ]"
          >
            <input
              type="checkbox"
              name="input-tags"
              v-model="selected"
              :value="day.slug"
            />
            <label>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM17.5694 9.48809C17.839 9.1736 17.8026 8.70012 17.4881 8.43056C17.1736 8.16099 16.7001 8.19741 16.4306 8.51191L10.9576 14.897L7.53033 11.4697C7.23744 11.1768 6.76256 11.1768 6.46967 11.4697C6.17678 11.7626 6.17678 12.2374 6.46967 12.5303L10.4697 16.5303C10.6174 16.6781 10.82 16.7575 11.0288 16.7494C11.2375 16.7414 11.4335 16.6467 11.5694 16.4881L17.5694 9.48809Z"
                  fill="currentColor"
                />
              </svg>

              {{ day.name }}
            </label>
          </div>
        </div>
        <div class="c-input-hours__cell c-input-hours__cell--hours">
          <div class="c-input-hours__time" v-if="isSelected(day.slug)">
            <InputTimepicker
              :value="getTime(day, 'hours', 'start')"
              @input="onTimeUpdate($event, day, 'hours', 'start')"
            ></InputTimepicker>
            <InputTimepicker
              :value="getTime(day, 'hours', 'end')"
              @input="onTimeUpdate($event, day, 'hours', 'end')"
            ></InputTimepicker>
          </div>
        </div>
        <div
          class="c-input-hours__cell c-input-hours__cell--maint"
          v-if="!hideMaint"
        >
          <div class="c-input-hours__time" v-if="isSelected(day.slug)">
            <InputTimepicker
              :value="getTime(day, 'maint', 'start')"
              @input="onTimeUpdate($event, day, 'maint', 'start')"
            ></InputTimepicker>
            <InputTimepicker
              :value="getTime(day, 'maint', 'end')"
              @input="onTimeUpdate($event, day, 'maint', 'end')"
            ></InputTimepicker>
          </div>
        </div>
        <div
          class="c-input-hours__cell c-input-hours__cell--patronage"
          v-if="showPatronage"
        >
          <div class="c-input-hours__time" v-if="isSelected(day.slug)">
            <InputNumber
              :hideButtons="hideButtons"
              :min="0"
              :max="99999"
              :step="5"
              :value="getPatronage(day, 'hours')"
              @input="onPatronageUpdate($event, day, 'hours')"
            >
            </InputNumber>
          </div>
        </div>
      </div>
    </div>
    <span class="c-input-hours__patrons" v-if="showPatronage"
      >Total Patron Visits: {{ totalPatrons }}</span
    >
    <br />
    <InputCheckbox
      v-if="show24"
      :options="options"
      v-model="alwaysOpenComputed"
      label=""
    ></InputCheckbox>

    <!-- <span class="c-input-hours__prefill">
      Prefill
      <select class="form-control" v-model="prefill">
        <option selected disabled>Select an option to prefil hours</option>
        <option value="business-hours-government">
          Business hours - Government (8:30 - 5:00)
        </option>
        <option value="business-hours">Business hours (9:00 - 5:30)</option>
        <option value="bar">Bar / pub hours</option>
        <option value="clear">Clear all days</option>
      </select>
    </span> -->
    <InputError v-if="hasError" :message="errorMessage"></InputError>
    <InputDescription
      v-if="description"
      :message="description"
    ></InputDescription>
  </div>
</template>

<script>
import InputError from "./input-error";
import InputDescription from "./input-description";
import slugify from "@swipekit/lib/slugify.js";
import InputText from "./input-text";
import InputRadio from "./input-radio";
import InputCheckbox from "./input-checkbox";
import InputTimepicker from "./input-timepicker";
import InputNumber from "./input-number";

import moment from "moment";

import venueToDay from "@swipekit/lib/venue-to-day";

export default {
  components: {
    InputError,
    InputDescription,
    InputText,
    InputRadio,
    InputCheckbox,
    InputTimepicker,
    InputNumber,
  },

  data: function () {
    return {
      alwaysOpenComputed: false,
      options: [
        {
          name: "Is this Venue open 24/7 ?",
          slug: 24,
        },
      ],
      prefill: "",
      estimatedPatrons: 0,
      selected: [],
      days: [
        {
          slug: "mon",
          name: "Monday",
        },
        {
          slug: "tue",
          name: "Tuesday",
        },
        {
          slug: "wed",
          name: "Wednesday",
        },
        {
          slug: "thur",
          name: "Thursday",
        },
        {
          slug: "fri",
          name: "Friday",
        },
        {
          slug: "sat",
          name: "Saturday",
        },
        {
          slug: "sun",
          name: "Sunday",
        },
      ],
    };
  },

  props: {
    label: {
      type: String,
      default: "",
    },
    validation: {},
    value: {},
    placeholder: {
      type: String,
      default: "",
    },
    noStyle: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: "",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {},
    hideMaint: {
      type: Boolean,
      default: false,
    },
    showPatronage: {
      type: Boolean,
      default: false,
    },
    hideButtons: {
      type: Boolean,
      default: false,
    },
    askQuestion: {
      type: Boolean,
      default: false,
    },
    show24: {
      type: Boolean,
      default: false,
    },
    form: {},
  },

  watch: {
    prefill: function (prefill) {
      if (prefill === "business-hours-government") {
        this.prefillBusinessHoursGovernment();
      }
      if (prefill === "business-hours") {
        this.prefillBusinessHours();
      }
      if (prefill === "clear") {
        this.prefillClear();
      }
    },
    selected: function () {
      this.$emit("input", this.selected);
    },
    value: function () {
      let currentValue = this.text;

      let value = this.value;

      if (value !== currentValue) {
        this.text = value;
      }
    },
    alwaysOpen: function () {
      if (this.alwaysOpen) {
        let selected = JSON.parse(JSON.stringify(this.selected));

        let days = this.days;

        for (let i = 0; i < days.length; i++) {
          let day = days[i];

          let hasDay = false;

          for (let j = 0; j < selected.length; j++) {
            if (selected[j].slug === day.slug) {
              hasDay = true;
              break;
            }
          }

          if (hasDay) {
            continue;
          }

          selected.push({
            name: day.name,
            slug: day.slug,
          });
        }

        this.$emit("formInput", true);

        this.selected = selected;
      } else {
        this.$emit("formInput", false);
      }
    },
  },

  computed: {
    alwaysOpen: function () {
      let alwaysOpen = this.alwaysOpenComputed;

      if (!alwaysOpen) {
        return false;
      }

      if (alwaysOpen.length === 0) {
        return false;
      }

      if (alwaysOpen.length === 1) {
        return true;
      }
    },
    totalPatrons: function () {
      let total = 0;
      let selected = this.selected;
      for (let i = 0; i < selected.length; i++) {
        if (selected[i].hours_patronage) {
          let int = parseInt(selected[i].hours_patronage);
          if (!isNaN(int)) {
            total += int;
          }
        }
      }
      return total;
    },
    slug: function () {
      return slugify(this.label || `input_${Math.floor(Math.random() * 100)}`);
    },
  },

  methods: {
    onYes: function () {
      let hours = this.form.hours;

      let temp = venueToDay(this.form.venues);

      this.selected = temp.days;
    },
    prefillBusinessHoursGovernment: function () {
      let days = this.days;

      let arr = [];

      for (let i = 0; i < days.length; i++) {
        if (days[i].slug === "sat") {
          continue;
        }
        if (days[i].slug === "sun") {
          continue;
        }
        let hours = {
          hours_start: `2023-03-08T08:30:00.000Z`,
          hours_end: `2023-03-08T17:00:00.000Z`,
        };

        let maint = {
          maint_start: `2023-03-08T08:30:00.000Z`,
          maint_end: `2023-03-08T17:00:00.000Z`,
        };

        let day = {
          ...hours,
          name: days[i].name,
          slug: days[i].slug,
        };

        // show maint basically
        if (!this.hideMaint) {
          day = {
            ...maint,
          };
        }

        arr.push(day);
      }

      this.selected = arr;
    },
    prefillBusinessHours: function () {
      let days = this.days;

      let arr = [];

      for (let i = 0; i < days.length; i++) {
        if (days[i].slug === "sat") {
          continue;
        }
        if (days[i].slug === "sun") {
          continue;
        }
        let hours = {
          hours_start: `2023-03-08T09:00:00.000Z`,
          hours_end: `2023-03-08T17:30:00.000Z`,
        };

        let maint = {
          maint_start: `2023-03-08T09:00:00.000Z`,
          maint_end: `2023-03-08T17:30:00.000Z`,
        };

        let day = {
          ...hours,
          name: days[i].name,
          slug: days[i].slug,
        };

        // show maint basically
        if (!this.hideMaint) {
          day = {
            ...maint,
          };
        }

        arr.push(day);
      }

      this.selected = arr;
    },
    prefillClear: function () {
      this.selected = [];
    },
    getTime: function (day, prefix = "hours", type) {
      let selected = this.selected;

      let isSelected = false;

      let currentDay = null;

      for (let i = 0; i < selected.length; i++) {
        if (selected[i].slug === day.slug) {
          currentDay = selected[i];
          break;
        }
      }

      let p = `${prefix}_${type}`;

      if (!currentDay) {
        return;
      }

      if (currentDay[p]) {
        return currentDay[p];
      } else {
        return;
      }
    },
    getPatronage: function (day, prefix = "hours") {
      let selected = this.selected;

      let isSelected = false;

      let currentDay = null;

      for (let i = 0; i < selected.length; i++) {
        if (selected[i].slug === day.slug) {
          currentDay = selected[i];
          break;
        }
      }

      let type = "patronage";

      let p = `${prefix}_${type}`;

      if (!currentDay) {
        return;
      }

      if (currentDay[p]) {
        return currentDay[p];
      } else {
        return;
      }
    },
    isSelected: function (slug) {
      let selected = this.selected;

      let isSelected = false;

      for (let i = 0; i < selected.length; i++) {
        if (selected[i].slug === slug) {
          isSelected = true;
          break;
        }
      }

      return isSelected;
    },
    onCheckboxClick: function (day, e) {
      let selected = this.selected;

      let isPresent = false;

      for (let i = 0; i < selected.length; i++) {
        let sel = selected[i];

        if (sel.slug === day.slug) {
          isPresent = i;
          break;
        }
      }

      if (isPresent || isPresent === 0) {
        let checkbox = `.c-input-checkbox__item`;
        let condition = e.target.closest(checkbox);
        if (condition) {
          this.selected.splice(isPresent, 1);
        } else {
          // do nothing
        }
      } else {
        this.selected.push(day);
      }
    },
    onPatronageUpdate: function (e, day, prefix = "hours") {
      let type = "patronage";
      let selected = JSON.parse(JSON.stringify(this.selected));

      for (let i = 0; i < selected.length; i++) {
        let sel = selected[i];

        if (sel.slug === day.slug) {
          let p = `${prefix}_${type}`;
          sel[p] = e;

          break;
        }
      }

      this.selected = selected;
    },
    onTimeUpdate: function (e, day, prefix = "hours", type) {
      let time = e.split(":");

      let hour = time[0];

      let minute = time[1];

      let date = moment.utc();

      date.set("hour", hour);

      date.set("minute", minute);

      date.set("second", 0);

      date.set("millisecond", 0);

      let selected = JSON.parse(JSON.stringify(this.selected));

      for (let i = 0; i < selected.length; i++) {
        let sel = selected[i];

        if (sel.slug === day.slug) {
          let p = `${prefix}_${type}`;
          sel[p] = date.toISOString();

          break;
        }
      }

      this.selected = selected;
    },
  },

  created: function () {
    if (this.value) {
      if (this.form.always_open) {
        this.alwaysOpenComputed = [24];
      }
      this.selected = this.value;
    }
  },
};
</script>

<style lang="scss">
.c-input-hours {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: var(--spacer);

  &__input {
    width: 100%;
    border-radius: var(--border-radius);
    border: var(--color-input-background) solid 1px;
  }

  &__days {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0px 0px;
    &:not(:last-child) {
      border-bottom: var(--color-input-background) solid 1px;
    }
  }

  &.hide-maint {
    .c-input-hours__days {
      grid-template-columns: 1fr 1fr;
    }
  }

  &.show-patronage {
    .c-input-hours__days {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &__patrons {
    margin-top: var(--margin);
  }

  &__cell {
    padding: var(--margin);

    &:not(:last-child) {
      border-right: var(--color-input-background) solid 1px;
    }
  }

  &__time {
    display: flex;
    height: 100%;

    > div {
      flex-grow: 1;

      &:first-child {
        .input[type="time"].form-control {
          border-right: none;
        }
      }
    }
  }

  &__question {
    margin-bottom: var(--margin);

    .btn {
      margin-left: var(--margin);
    }
  }

  .form-group {
    height: 100%;
    padding: 0;
  }
  .form-control {
    border-radius: 0;
    margin: 0;
    height: 100%;
    padding: var(--margin);
  }

  .c-input-number {
    width: 100%;
    height: 100%;

    &__inner {
      width: 100%;
      height: 100%;

      .btn {
        padding: 2px 20px;
        height: auto;
        right: 48px;

        &:last-of-type {
          right: 4px;
        }
      }
    }
  }

  .c-input-checkbox__item {
    margin: 0;
    height: 100%;

    > input {
      display: none;
    }

    label {
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;

      svg {
        margin-right: var(--margin);
        color: #fff;
        border: var(--color-bg-6) solid 2px;
        border-radius: var(--border-radius-full);
      }
    }

    &.active {
      color: var(--color-primary);
      svg {
        color: var(--color-primary);
        border: none;
      }
    }
  }

  &.alwaysOpen {
    .c-input-hours__cell--days,
    .c-input-hours__cell--hours,
    .c-input-hours__cell--maint {
      opacity: 0.65;
      pointer-events: none;
    }
  }

  @media screen and (max-width: 1024px) {
    &__days {
      grid-template-columns: 0.75fr 1.25fr 1fr;

      .c-input-number__inner {
        min-width: initial;
        .btn {
          height: 30px;
          right: 4px !important;
          &:last-of-type {
            top: 38px;
            right: 4px;
          }
        }
      }

      .c-input-datepicker .vue__time-picker {
        width: auto;

        input.display-time {
          width: 98px;
        }
      }
    }

    &.show-patronage .c-input-hours__days {
      grid-template-columns: 130px 110px calc(100% - 240px);
    }

    .c-input-checkbox__item {
      svg {
        width: 20px;
        height: 20px;
      }
    }

    &__time {
      flex-direction: column;

      > div {
        &:first-child {
          .form-control {
            border-right: var(--color-input-background) solid 1px;
            border-bottom: none;
          }
        }
      }
    }
  }
}
</style>
