<template>
  <form class="c-form-venue-proof" @submit.prevent="onSubmit">
    <Form
      ref="form"
      :schema="computedSchema"
      v-model="formData"
      @status="saveStatus"
      buttonText="Upload Proof of Posting Photos"
      :processing="processing"
    ></Form>
  </form>
</template>

<script>
import Form from "./index.vue";
import venueToDay from "@swipekit/lib/venue-to-day";
import dayToVenue from "@swipekit/lib/day-to-venue";

const schema = {
  proof_of_post_one: {
    component: "InputCamera",
    label: "First proof photo",
    description: "Take a photo of the first installed sign.",
  },
  proof_of_post_two: {
    component: "InputCamera",
    label: "Second proof photo",
    description: "Take a photo of the second installed sign.",
  },
  proof_of_post_three: {
    component: "InputCamera",
    label: "Third proof photo",
    description: "Take a photo of the third installed sign.",
  },
  proof_of_post_four: {
    component: "InputCamera",
    label: "Fourth proof photo",
    description: "Take a photo of the fourth installed sign.",
  },
};

export default {
  components: {
    Form,
  },

  data: function () {
    return {
      processing: false,
      status: null,
      formData: {},
    };
  },

  watch: {
    form: {
      handler: function () {
        let f = JSON.parse(JSON.stringify(this.form));

        let data = venueToDay(f);

        this.formData = data;
      },
      immediate: true,
      deep: true,
    },
  },

  props: {
    form: {},
  },

  computed: {
    computedSchema: function () {
      let s = schema;

      return s;
    },
  },

  methods: {
    reset: function () {},
    saveStatus: function (saveStatus) {
      this.status = saveStatus;
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      this.processing = true;

      let form = this.formData;

      console.log(form);

      let obj = dayToVenue(form.days);

      form = {
        ...obj,
        ...form,
      };

      form = {
        venue_id: form.venue_id,
        days: form.days,
        additional_data: form.business_number,
        proof_of_post_one: form.proof_of_post_one,
        proof_of_post_two: form.proof_of_post_two,
        proof_of_post_three: form.proof_of_post_three,
        proof_of_post_four: form.proof_of_post_four,
      };

      if (!form.days) {
        delete form.days;
      }

      let res = await this.$store
        .dispatch("venues/update", form)
        .catch((err) => {
          if (err.response.data) {
            this.errors = err.response.data;
          }
          this.processing = false;

          this.$store.dispatch("message/onFail", this.errors);
        });

      if (!res) {
        return;
      }

      this.$store.dispatch("message/onVenueUpdated");

      this.reset();

      this.$emit("onSubmit");

      this.processing = false;
    },
  },

  mounted: function () {},
};
</script>

<style lang="scss"></style>
