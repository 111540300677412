<template>
  <div class="form-group c-input-editor">
    <label v-if="label" class="form__label" :for="slug">{{ label }}</label>
    <div
      class="c-input-editor__content"
      ref="editor"
      @input="onInput"
      contenteditable="true"
    ></div>
    <InputError v-if="hasError" :message="errorMessage"></InputError>
    <InputDescription
      v-if="description"
      :message="description"
    ></InputDescription>
  </div>
</template>

<script>
import InputError from "./input-error";
import InputDescription from "./input-description";
import slugify from "@swipekit/lib/slugify.js";

export default {
  components: {
    InputError,
    InputDescription,
  },

  data: function () {
    return {
      content: "",
    };
  },

  watch: {
    // value: {
    //   handler: function () {
    //     if (this.value) {
    //       console.log(this.$refs);
    //       this.$refs.editor.textContent = this.value;
    //     }
    //   },
    //   immediate: true,
    // },
    content: function () {
      //this.onChange(this.content);
    },
    value: function () {
      let currentValue = this.content;

      let value = this.value;

      if (value !== currentValue) {
        this.content = value;
        this.$refs.editor.innerText = this.value;
      }
    },
  },

  props: {
    label: {
      type: String,
      default: "",
    },
    validation: {},
    value: {},
    placeholder: {
      type: String,
      default: "",
    },
    noStyle: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: "",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {},
  },

  computed: {
    slug: function () {
      return slugify(this.label || `input_${Math.floor(Math.random() * 100)}`);
    },
  },

  methods: {
    onInput: function (val) {
      let content = val.target.innerText;
      this.content = content;
      this.$emit("input", content);
    },
  },

  mounted: function () {
    if (this.value) {
      this.content = this.value;
      this.$refs.editor.innerText = this.value;
    }
  },
};
</script>

<style lang="scss">
.c-input-editor {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: var(--margin-lg);

  .c-editor {
    width: 100%;
  }

  &__content {
    width: 100%;
    height: 100%;
    min-height: 80px;
    padding: var(--margin-lg) var(--margin-lg);

    font-size: var(--font-size-sm);

    border: var(--color-input-background) solid 1px;
    border-radius: var(--border-radius);

    &:focus-visible {
      outline: none;
      box-shadow: inset 0 1px 5px 0 rgba(255, 255, 255, 0.075),
        0 0 0 3px var(--primary-opacity), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    }
  }
}
</style>
