<template>
  <div class="form-group c-input-select">
    <label v-if="label" class="form__label" :for="slug">{{ label }}</label>
    <select
      v-if="options"
      :class="[{ 'form-control': noStyle === false }]"
      :id="slug"
      v-model="text"
      :placeholder="placeholder"
    >
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
        :selected="option.value === text"
      >
        {{ option.key || option.value }}
      </option>
    </select>
    <InputError v-if="hasError" :message="errorMessage"></InputError>
    <InputDescription
      v-if="description"
      :message="description"
    ></InputDescription>
  </div>
</template>

<script>
import InputError from "./input-error";
import InputDescription from "./input-description";
import slugify from "@swipekit/lib/slugify.js";

export default {
  components: {
    InputError,
    InputDescription,
  },

  data: function () {
    return {
      text: "",
    };
  },

  watch: {},

  props: {
    label: {
      type: String,
      default: "",
    },
    validation: {},
    value: {},
    placeholder: {
      type: String,
      default: "",
    },
    noStyle: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: "",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {},
    options: {},
  },

  watch: {
    text: function () {
      this.$emit("input", this.text);
    },
    value: function () {
      let currentValue = this.text;

      let value = this.value;

      if (value !== currentValue) {
        this.text = value;
      }
    },
    options: {
      hander: function () {
        console.log(this.options);
      },
      deep: true,
    },
  },

  computed: {
    slug: function () {
      return slugify(this.label || `input_${Math.floor(Math.random() * 100)}`);
    },
  },

  mounted: function () {
    if (this.value) {
      this.text = this.value;
    }

    if (typeof this.value === "number" && this.value === 0) {
      this.text = this.value;
    }
  },
};
</script>

<style lang="scss">
.c-input-select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: var(--margin-lg);
}
</style>
