import CrudStore from "@swipekit/lib/crud-store";

let crud = new CrudStore({
  name: "boards",
  singular: "board",
});

const state = {
  ...crud.state,
};
const getters = {
  ...crud.getters,

  boardBySlug: function (state) {
    let resources = state.resources;

    return (slug) => {
      let resource = null;

      //id = parseInt(id);

      // if (isNaN(id)) {
      //   return null;
      // }

      for (let i = 0; i < resources.length; i++) {
        if (resources[i].slug === slug) {
          resource = resources[i];
          break;
        }
      }

      return resource;
    };
  },
};
const actions = {
  ...crud.actions,

  async afterLogin(store, pie) {
    let mode = store.rootState.config.mode;

    if (store.rootGetters["users/isAuth"]) {
      if (mode === "EXTENSION") {
        await store.dispatch("getLocal");
      } else {
        if (!pie) {
          await store.dispatch("get");
        } else {
          await store.dispatch("consumePie", pie);
        }
      }
    }
  },

  async getLocal(store) {
    let resources = [];
    try {
      resources = await crud.api[crud.resourceName].get("local");
    } catch (err) {
      throw err;
    }

    store.commit("SET", resources);
    return resources;
  },

  async doAction(store, obj) {
    let action = obj.action;
    let id = obj.id;
    let response = await crud.api.api.doAction(id, action);

    return response;
  },

  async getBoardData(store, obj) {
    let action = obj.action;
    let id = obj.id;
    let response = await crud.api.api.doAction(id, action);

    if (response) {
      let user = response.user;
      let blocks = response.blocks;
      let board = JSON.parse(JSON.stringify(response));
      delete board.user;
      delete board.blocks;

      await store.dispatch("ads/get", blocks, { root: true });
      store.commit("UPDATE", board);
    }

    return response;
  },

  async remove(store, id) {
    let condition = false;
    try {
      condition = await crud.api[crud.resourceName].delete(id);
    } catch (err) {
      console.log(err);
      throw err;
    }
    if (condition) {
      store.commit("REMOVE", id);

      await store.dispatch("ads/removeBoard", id, { root: true });
    }

    return condition;
  },

  async fetchAndStore(store) {
    let resources = (await crud.api[crud.resourceName].fetchAndStore()) || [];

    store.commit(resources);
  },

  consumePie: async (store, pie) => {
    store.commit("SET", pie.workspace.boards || []);
  },
};
const mutations = {
  ...crud.mutations,
};

let store = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default store;
