<template>
  <div class="c-input-multiple">
    <div class="c-input-multiple__inputs">
      <div v-for="i in inputVals.length" :key="i" class="c-input-multiple__item">
        <svg
          @click="remove(i - 1)"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
        <component
          :value="inputVals[i - 1]"
          @input="onInput(i - 1, $event)"
          :placeholder="placeholder"
          :is="inputComponent"
        >
        </component>
      </div>
    </div>
    <a :class="['btn', { disabled: isMax === true }]" @click="addInputVal"> {{ addText }} </a>
  </div>
</template>

<script>
import InputEmail from "./input-email";

export default {
  components: {
    InputEmail,
  },

  data: function () {
    return {
      inputVals: [],
    };
  },

  props: {
    placeholder: {
      type: String,
      default: "acme@gg.com",
    },
    max: {
      type: Number,
      default: -1,
    },
  },

  computed: {
    isMax: function () {
      if (this.max === -1) {
        return false;
      }
      if (this.inputVals.length === this.max) {
        return true;
      }
      return false;
    },
    addText: function () {
      if (this.inputVals.length === 0) {
        return "Add Emails";
      }
      if (this.isMax) {
        return `Only ${this.max} allowed.`;
      }
      return "Add more";
    },
    inputComponent: function () {
      return "InputEmail";
    },
  },

  methods: {
    addInputVal: function () {
      if (this.isMax) {
        return;
      }
      this.inputVals.push("");
    },
    remove: function (i) {
      this.inputVals.splice(i, 1);
    },
    onInput: function (i, val) {
      this.inputVals[i] = val;
      this.$emit("input", this.inputVals);
    },
  },
};
</script>

<style lang="scss">
.c-input-multiple {
  margin-bottom: var(--margin);

  &__item {
    display: flex;
    align-items: center;
    padding-bottom: var(--margin-lg);

    svg {
      color: var(--color-danger);
      margin-right: var(--margin-lg);
      cursor: pointer;
      opacity: 0.85;
      user-select: none;

      &:hover,
      &:active {
        opacity: 1;
      }
    }
    .form-group {
      flex-grow: 1;
      padding-bottom: 0;
    }
    .form-control {
      margin-bottom: 0;
    }
  }
}
</style>
