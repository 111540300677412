<template>
  <div class="form-group c-input-bathroom">
    <label v-if="label" class="form__label" :for="slug">{{ label }}</label>
    <div class="c-input-bathroom__inner">
      <div class="c-add-bathroom">
        <a
          v-if="!addBathroom"
          href="#"
          class="btn btn-primary"
          @click="onAddBathroom"
        >
          Add Bathroom
        </a>
        <FormBathroomCreate
          :sites="sites"
          v-if="init && addBathroom"
          @onSubmit="onBathroomCreate"
        ></FormBathroomCreate>
      </div>
      <div class="c-simple-table" v-if="bathrooms && bathrooms.length > 0">
        <div
          class="c-simple-table__row"
          v-for="(bathroom, i) in bathrooms"
          :key="i"
        >
          <span>
            <svg
              @click="onRemoveSite(bathroom, i)"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </span>
          <span>
            {{ bathroom.name }}
          </span>
        </div>
      </div>
    </div>

    <InputError v-if="hasError" :message="errorMessage"></InputError>
    <InputDescription
      v-if="description"
      :message="description"
    ></InputDescription>
  </div>
</template>

<script>
import InputError from "./input-error";
import InputDescription from "./input-description";
import slugify from "@swipekit/lib/slugify.js";
import FormBathroomCreate from "@swipekit/components/form/form-bathroom-create.vue";
import api from "@swipekit/lib";

export default {
  components: {
    InputError,
    InputDescription,
    FormBathroomCreate,
  },

  data: function () {
    return {
      init: false,
      modalActive: true,
      addBathroom: false,
    };
  },

  props: {
    venue_id: {},
    label: {
      type: String,
      default: "",
    },
    validation: {},
    value: {},
    placeholder: {
      type: String,
      default: "",
    },
    noStyle: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: "",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {},
    sites: {},
  },

  watch: {
    value: function () {
      let currentValue = this.bathrooms;

      let value = this.value;

      if (value !== currentValue) {
        this.bathrooms = value;
      }
    },
  },

  computed: {
    slug: function () {
      return slugify(this.label || `input_${Math.floor(Math.random() * 100)}`);
    },
  },

  methods: {
    onModalClose: function () {
      console.log("gg");
      this.modalActive = false;
    },
    onAddBathroom: function () {
      this.addBathroom = true;
    },
    onBathroomCreate: function (bathroom) {
      this.bathrooms.push(bathroom);

      this.$emit("input", this.bathrooms);
    },
  },

  mounted: function () {
    if (this.value) {
      this.bathrooms = this.value;
    }
    this.$nextTick(() => {
      this.init = true;
    });
  },
};
</script>

<style lang="scss">
.c-input-bathroom {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: var(--margin-lg);

  &__inner {
    width: 100%;
    border: hsl(var(--p), 40%, 75%) solid 1px;
    background-color: hsl(var(--p), 40%, 93%);
    border-radius: var(--border-radius);
    padding: var(--spacer-sm);

    .c-form {
      padding: var(--margin-lg);
      background-color: var(--color-bg-1);
      border-radius: var(--border-radius);
      border: hsl(var(--p), 40%, 75%) solid 1px;
    }
  }
}
</style>
