<template>
  <form class="c-form-site-create" @submit.prevent="onSubmit">
    <Form
      ref="form"
      :schema="schema"
      v-model="form"
      @status="saveStatus"
      @formInput="onFormInput"
      buttonText="Save Area"
      :processing="processing"
    ></Form>
  </form>
</template>

<script>
import ageEncoder from "@swipekit/lib/age-encoder";

const schema = {
  name: {
    component: "InputText",
    label: "Area name",
    properties: {
      columnSize: 6,
    },
    validations: {
      required: {
        params: null,
        message: "An area name is required",
      },
      minLength: {
        params: 4,
        message: "Give a descriptive name to your area. Minimum 4 characters",
      },
      maxLength: {
        params: 64,
        message: `That's a insanely long area name. Maximum 64 characters`,
      },
    },
  },

  item_id: {
    component: "InputCategory",
    label: "Category",
    parentId: null,
    validations: {
      required: {
        params: null,
        message: "Category required",
      },
    },
  },

  hours: {
    component: "InputHours",
    askQuestion: true,
    label: "",
    hideMaint: true,
    showPatronage: true,
    showEstimatedPatrons: false,
    hideButtons: false,
  },

  age_group: {
    component: "InputCheckboxGroup",
    label: "Which age groups frequently visit this venue?",
    options: [
      {
        key: "Under 18",
        value: "UNDER_18",
      },
      {
        key: "18 - 19",
        value: "UNDER_20",
      },
      {
        key: "20 - 24",
        value: "UNDER_25",
      },
      {
        key: "25 - 39",
        value: "UNDER_30",
      },
      {
        key: "30 - 34",
        value: "UNDER_35",
      },
      {
        key: "35 - 39",
        value: "UNDER_40",
      },
      {
        key: "40 - 44",
        value: "UNDER_45",
      },
      {
        key: "45 - 49",
        value: "UNDER_50",
      },
      {
        key: "50 - 54",
        value: "UNDER_55",
      },
      {
        key: "55 - 59",
        value: "UNDER_60",
      },
      {
        key: "60 - 64",
        value: "UNDER_65",
      },
      {
        key: "65 -  69",
        value: "UNDER_70",
      },
      {
        key: "70 and over",
        value: "OVER_70",
      },
    ],
    validations: {
      required: {
        params: null,
        message: "Age group is required",
      },
    },
  },

  gender_skew: {
    component: "InputSelect",
    label: "What is the gender ratio of patrons who visit this venue?",
    options: [
      {
        key: "100:0 (100% male - 0% female)",
        value: 100,
      },
      {
        key: "90:10",
        value: 90,
      },
      {
        key: "80:20",
        value: 80,
      },
      {
        key: "70:30",
        value: 70,
      },
      {
        key: "60:40",
        value: 60,
      },
      {
        key: "50:50",
        value: 50,
      },
      {
        key: "40:60",
        value: 40,
      },
      {
        key: "30:70",
        value: 30,
      },
      {
        key: "20:80",
        value: 20,
      },
      {
        key: "10:90",
        value: 10,
      },
      {
        key: "0:100 (0% male - 100% female)",
        value: 0,
      },
    ],
    validations: {
      required: {
        params: null,
        message: "Gender skew is required",
      },
    },
  },

  SiteBathrooms: {
    component: "InputCheckboxGroup",
    label: "Bathrooms",
    options: [],
  },
};

export default {
  components: {
    Form: () => {
      return import("./index.vue");
    },
  },

  data: function () {
    return {
      processing: false,
      form: {},
      status: null,
      always_open: false,
    };
  },

  props: {
    venue_id: {},
  },

  watch: {
    venue_id: {
      handler: function () {
        const venue_id = this.venue_id;

        let venue = this.$store.getters["venues/one"](venue_id);

        let obj = {
          venues: venue,
        };

        obj = JSON.parse(JSON.stringify(obj));

        this.form = obj;
      },
      immediate: true,
    },
  },

  computed: {
    schema: function () {
      let s = schema;

      let venue = this.$store.getters["venues/one"](this.venue_id);

      let listItem = this.$store.getters["lists/byItemId"](
        venue.main_category_id
      );

      let type_id = listItem.type_id;

      s.item_id.type_id = type_id;

      let bathrooms = venue.bathrooms || [];

      let options = [];

      for (let i = 0; i < bathrooms.length; i++) {
        options.push({
          key: bathrooms[i].name,
          value: bathrooms[i].bathroom_id,
        });
      }

      s.SiteBathrooms.options = options;

      return s;
    },
  },

  methods: {
    onFormInput: function (data) {
      this.always_open = data.value;
    },
    reset: function () {},
    saveStatus: function (saveStatus) {
      this.status = saveStatus;
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      this.processing = true;

      let form = this.form;

      form.age_group = ageEncoder.encode(form.age_group);

      form.additional_data = form.additional_data || {};

      form.additional_data.hours = form.hours || [];

      delete form.hours;

      let newSite = {
        additional_data: form.additional_data,
        age_group: form.age_group,
        gender_skew: form.gender_skew,
        name: form.name,
        venue_id: this.venue_id,
        item_id: form.item_id,
      };

      let response = await this.$store
        .dispatch("venues/createSite", newSite)
        .catch((err) => {
          if (err.response.data) {
            this.errors = err.response.data;
          }
          this.processing = false;

          this.$store.dispatch("message/onFail", this.errors);

          return;
        });

      if (!response) {
        return;
      }

      this.$store.dispatch("message/onSiteCreated");

      this.processing = false;

      this.$emit("onSubmit", form);
    },
  },

  mounted: function () {},
};
</script>

<style></style>
