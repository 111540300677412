<template>
  <div class="c-cope-installation">
    <article
      v-if="
        isCope &&
        venue.additional_data.venue_type &&
        venue.additional_data.venue_type.toLowerCase() === 'mch'
      "
      class="article-text article-text-stretch"
    >
      <table
        border="1"
        cellspacing="0"
        cellpadding="0"
        align="left"
        width="90%"
        style="
          border-collapse: collapse;
          border: none;
          margin-left: 6.75pt;
          margin-right: 6.75pt;
        "
      >
        <tr style="height: 34.95pt">
          <td
            width="69"
            valign="top"
            style="
              width: 51.5pt;
              border: solid 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 34.95pt;
            "
          >
            <p>
              <span
                ><b><span style="font-size: 10pt">Sign Type</span></b></span
              >
            </p>
          </td>
          <td
            width="192"
            valign="top"
            style="
              width: 144.15pt;
              border: solid 1pt;
              border-left: none;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 34.95pt;
            "
          >
            <p>
              <span
                ><b><span style="font-size: 10pt">Message</span></b></span
              >
            </p>
          </td>
          <td
            width="66"
            valign="top"
            style="
              width: 49.6pt;
              border: solid 1pt;
              border-left: none;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 34.95pt;
            "
          >
            <p>
              <span
                ><b><span style="font-size: 10pt">Number</span></b></span
              >
            </p>
          </td>
          <td
            width="174"
            valign="top"
            style="
              width: 130.15pt;
              border: solid 1pt;
              border-left: none;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 34.95pt;
            "
          >
            <p>
              <span
                ><b><span style="font-size: 10pt">Instructions</span></b></span
              >
            </p>
          </td>
        </tr>
        <tr style="height: 26.7pt">
          <td
            width="66"
            valign="top"
            style="
              width: 49.4pt;
              border: solid 1pt;
              border-top: none;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 26.7pt;
            "
          >
            <p style="font-size: 0.7em">A2 Corflute Sign</p>
          </td>
          <td
            width="189"
            valign="top"
            style="
              width: 5cm;
              border-top: none;
              border-left: none;
              border-bottom: solid 1pt;
              border-right: solid 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 26.7pt;
            "
          >
            <p style="font-size: 0.7em">The free app… Women with phone</p>
            <p>
              <img
                width="130"
                src="/media/rtc_cope_act_pr.png"
                align="left"
                hspace="12"
              />
            </p>
          </td>
          <td
            width="57"
            valign="top"
            style="
              width: 42.5pt;
              border-top: none;
              border-left: none;
              border-bottom: solid 1pt;
              border-right: solid 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 26.7pt;
            "
          >
            <p style="font-size: 0.7em">2</p>
          </td>
          <td
            width="168"
            valign="top"
            style="
              width: 126.25pt;
              border-top: none;
              border-left: none;
              border-bottom: solid 1pt;
              border-right: solid 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 26.7pt;
            "
          >
            <p style="font-size: 0.7em">
              >Install in to the patient waiting area.
            </p>
            <p style="font-size: 0.7em">
              The A2 corflute signs need to be installed 1 meter from the
              ground, where they can be seen by the patients.
            </p>
            <p style="font-size: 0.7em">
              All A2 corflute signs have double sided tape so that they can be
              easily installed on to a flat wall surface.
            </p>
          </td>
        </tr>
        <tr style="height: 25.25pt">
          <td
            width="66"
            valign="top"
            style="
              width: 49.4pt;
              border: solid 1pt;
              border-top: none;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 25.25pt;
            "
          >
            <p style="font-size: 0.7em">A4 Poster</p>
          </td>
          <td
            width="189"
            valign="top"
            style="
              width: 5cm;
              border-top: none;
              border-left: none;
              border-bottom: solid 1pt;
              border-right: solid 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 25.25pt;
            "
          >
            <p style="font-size: 0.7em">free app… Women with phone</p>
            <p>
              <img
                width="130"
                src="/media/rtc_cope_act_pr.png"
                align="left"
                hspace="12"
              />
            </p>
          </td>
          <td
            width="57"
            valign="top"
            style="
              width: 42.5pt;
              border-top: none;
              border-left: none;
              border-bottom: solid 1pt;
              border-right: solid 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 25.25pt;
            "
          >
            <p style="font-size: 0.7em">2</p>
          </td>
          <td
            width="168"
            valign="top"
            style="
              width: 126.25pt;
              border-top: none;
              border-left: none;
              border-bottom: solid 1pt;
              border-right: solid 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 25.25pt;
            "
          >
            <p style="font-size: 0.7em">
              Install 1 x A4 poster in to each bathroom.
            </p>
            <p style="font-size: 0.7em">
              Please install into the bathrooms for patients, by the hand-dryer
              or entry location.
            </p>
          </td>
        </tr>
      </table>
      <div style="display: block; clear: both; margin-bottom: 30px">&nbsp;</div>
    </article>

    <article
      v-else-if="
        isCope &&
        venue.additional_data.venue_type &&
        venue.additional_data.venue_type.toLowerCase() === 'hospital'
      "
      class="article-text"
    >
      <table
        border="1"
        cellspacing="0"
        cellpadding="0"
        align="left"
        width="90%"
        style="
          border-collapse: collapse;
          border: none;
          margin-left: 6.75pt;
          margin-right: 6.75pt;
        "
      >
        <tr style="height: 34.95pt">
          <td
            width="69"
            valign="top"
            style="
              width: 51.5pt;
              border: solid 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 34.95pt;
            "
          >
            <p>
              <span
                ><b><span style="font-size: 10pt">Sign Type</span></b></span
              >
            </p>
          </td>
          <td
            width="192"
            valign="top"
            style="
              width: 144.15pt;
              border: solid 1pt;
              border-left: none;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 34.95pt;
            "
          >
            <p>
              <span
                ><b><span style="font-size: 10pt">Message</span></b></span
              >
            </p>
          </td>
          <td
            width="66"
            valign="top"
            style="
              width: 49.6pt;
              border: solid 1pt;
              border-left: none;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 34.95pt;
            "
          >
            <p>
              <span
                ><b><span style="font-size: 10pt">Number</span></b></span
              >
            </p>
          </td>
          <td
            width="174"
            valign="top"
            style="
              width: 130.15pt;
              border: solid 1pt;
              border-left: none;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 34.95pt;
            "
          >
            <p>
              <span
                ><b><span style="font-size: 10pt">Instructions</span></b></span
              >
            </p>
          </td>
        </tr>
        <tr style="height: 26.7pt">
          <td
            width="69"
            valign="top"
            style="
              width: 51.5pt;
              border: solid 1pt;
              border-top: none;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 26.7pt;
            "
          >
            <p style="font-size: 0.7em">A2 Corflute Sign</p>
          </td>
          <td
            width="192"
            valign="top"
            style="
              width: 144.15pt;
              border-top: none;
              border-left: none;
              border-bottom: solid 1pt;
              border-right: solid 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 26.7pt;
            "
          >
            <p style="font-size: 0.7em">The free app… Pregnant lady</p>
            <p>
              <img
                width="130"
                src="/media/rtc_cope_act_f.png"
                align="left"
                hspace="12"
              />
            </p>
          </td>
          <td
            width="66"
            valign="top"
            style="
              width: 49.6pt;
              border-top: none;
              border-left: none;
              border-bottom: solid 1pt;
              border-right: solid 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 26.7pt;
            "
          >
            <p style="font-size: 0.7em">2</p>
          </td>
          <td
            width="174"
            valign="top"
            style="
              width: 130.15pt;
              border-top: none;
              border-left: none;
              border-bottom: solid 1pt;
              border-right: solid 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 26.7pt;
            "
          >
            <p style="font-size: 0.7em">
              Install in to the patient waiting area.
            </p>
            <p style="font-size: 0.7em">
              The A2 corflute signs need to be installed 1 meter from the
              ground, where they can be seen by the patients.
            </p>
            <p style="font-size: 0.7em">
              All A2 corflute signs have double sided tape so that they can be
              easily installed on to a flat wall surface.
            </p>
          </td>
        </tr>
        <tr style="height: 26.7pt">
          <td
            width="69"
            valign="top"
            style="
              width: 51.5pt;
              border: solid 1pt;
              border-top: none;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 26.7pt;
            "
          >
            <p style="font-size: 0.7em">A4 Poster</p>
          </td>
          <td
            width="192"
            valign="top"
            style="
              width: 144.15pt;
              border-top: none;
              border-left: none;
              border-bottom: solid 1pt;
              border-right: solid 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 26.7pt;
            "
          >
            <p style="font-size: 0.7em">The free app… Pregnant lady</p>
            <p>
              <img
                width="130"
                src="/media/rtc_cope_act_f.png"
                align="left"
                hspace="12"
              />
            </p>
          </td>
          <td
            width="66"
            valign="top"
            style="
              width: 49.6pt;
              border-top: none;
              border-left: none;
              border-bottom: solid 1pt;
              border-right: solid 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 26.7pt;
            "
          >
            <p style="font-size: 0.7em">2</p>
          </td>
          <td
            width="174"
            valign="top"
            style="
              width: 130.15pt;
              border-top: none;
              border-left: none;
              border-bottom: solid 1pt;
              border-right: solid 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 26.7pt;
            "
          >
            <p style="font-size: 0.7em">
              Install 1 x A4 poster in to each bathroom.
            </p>
            <p style="font-size: 0.7em">
              Please install into the bathrooms for patients, by the hand-dryer
              or entry location.
            </p>
          </td>
        </tr>
      </table>
    </article>
    <article v-else class="article-text">
      <table
        border="1"
        cellspacing="0"
        cellpadding="0"
        align="left"
        width="100%"
        style="
          border-collapse: collapse;
          border: none;
          margin-left: 6.75pt;
          margin-right: 6.75pt;
        "
      >
        <tr style="height: 34.95pt">
          <td
            width="69"
            valign="top"
            style="
              width: 51.5pt;
              border: solid 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 34.95pt;
            "
          >
            <p><b>Sign Type</b></p>
          </td>
          <td
            width="192"
            valign="top"
            style="
              width: 144.15pt;
              border: solid 1pt;
              border-left: none;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 34.95pt;
            "
          >
            <p><b>Message</b></p>
          </td>
          <td
            width="66"
            valign="top"
            style="
              width: 49.6pt;
              border: solid 1pt;
              border-left: none;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 34.95pt;
            "
          >
            <p><b>Number</b></p>
          </td>
          <td
            width="174"
            valign="top"
            style="
              width: 130.15pt;
              border: solid 1pt;
              border-left: none;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 34.95pt;
            "
          >
            <p><b>Instructions</b></p>
          </td>
        </tr>
        <tr style="height: 26.7pt">
          <td
            width="66"
            valign="top"
            style="
              width: 49.4pt;
              border: solid 1pt;
              border-top: none;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 26.7pt;
            "
          >
            <p style="font-size: 0.7em">A2 Corflute Sign</p>
          </td>
          <td
            width="167"
            valign="top"
            style="
              width: 125.15pt;
              border-top: none;
              border-left: none;
              border-bottom: solid 1pt;
              border-right: solid 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 26.7pt;
            "
          >
            <p style="font-size: 0.7em">The free app… Women with phone</p>
            <p>
              <img
                width="130"
                src="/media/rtc_cope_act_pr.png"
                align="left"
                hspace="12"
              />
            </p>
          </td>
          <td
            width="66"
            valign="top"
            style="
              width: 49.6pt;
              border-top: none;
              border-left: none;
              border-bottom: solid 1pt;
              border-right: solid 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 26.7pt;
            "
          >
            <p style="font-size: 0.7em">1</p>
          </td>
          <td
            width="199"
            valign="top"
            style="
              width: 149.4pt;
              border-top: none;
              border-left: none;
              border-bottom: solid 1pt;
              border-right: solid 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 26.7pt;
            "
          >
            <p style="font-size: 0.7em">
              Install in to the patient waiting area of the Maternal Child
              Health Centre.
            </p>
            <p style="font-size: 0.7em">
              The A2 corflute signs need to be installed 1 meter from the
              ground, where they can be seen by the patients.
            </p>
            <p style="font-size: 0.7em">
              All A2 corflute signs have double sided tape so that they can be
              easily installed on to a flat wall surface.
            </p>
          </td>
        </tr>
        <tr style="height: 26.7pt">
          <td
            width="66"
            valign="top"
            style="
              width: 49.4pt;
              border: solid 1pt;
              border-top: none;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 26.7pt;
            "
          >
            <p style="font-size: 0.7em">A2 Corflute Sign</p>
          </td>
          <td
            width="167"
            valign="top"
            style="
              width: 125.15pt;
              border-top: none;
              border-left: none;
              border-bottom: solid 1pt;
              border-right: solid 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 26.7pt;
            "
          >
            <p style="font-size: 0.7em">The free app… Pregnant lady</p>
            <p>
              <img
                width="130"
                src="/media/rtc_cope_act_f.png"
                align="left"
                hspace="12"
              />
            </p>
            <p>
              <span
                ><i><span style="font-size: 9pt">&nbsp;</span></i></span
              >
            </p>
          </td>
          <td
            width="66"
            valign="top"
            style="
              width: 49.6pt;
              border-top: none;
              border-left: none;
              border-bottom: solid 1pt;
              border-right: solid 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 26.7pt;
            "
          >
            <p>
              <span><span style="font-size: 9pt">1</span></span>
            </p>
          </td>
          <td
            width="199"
            valign="top"
            style="
              width: 149.4pt;
              border-top: none;
              border-left: none;
              border-bottom: solid 1pt;
              border-right: solid 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 26.7pt;
            "
          >
            <p style="font-size: 0.7em">
              Install in to the patient waiting area.
            </p>
            <p style="font-size: 0.7em">
              The A2 corflute signs need to be installed 1 meter from the
              ground, where they can be seen by the patients.
            </p>
            <p style="font-size: 0.7em">
              All A2 corflute signs have double sided tape so that they can be
              easily installed on to a flat wall surface.
            </p>
          </td>
        </tr>
        <tr style="height: 25.25pt">
          <td
            width="66"
            valign="top"
            style="
              width: 49.4pt;
              border: solid 1pt;
              border-top: none;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 25.25pt;
            "
          >
            <p style="font-size: 0.7em">A4 Poster</p>
          </td>
          <td
            width="167"
            valign="top"
            style="
              width: 125.15pt;
              border-top: none;
              border-left: none;
              border-bottom: solid 1pt;
              border-right: solid 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 25.25pt;
            "
          >
            <p style="font-size: 0.7em">The free app… Women with phone</p>
            <p>
              <img
                width="130"
                src="/media/rtc_cope_act_pr.png"
                align="left"
                hspace="12"
              />
            </p>
          </td>
          <td
            width="66"
            valign="top"
            style="
              width: 49.6pt;
              border-top: none;
              border-left: none;
              border-bottom: solid 1pt;
              border-right: solid 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 25.25pt;
            "
          >
            <p style="font-size: 0.7em">1</p>
          </td>
          <td
            width="199"
            valign="top"
            style="
              width: 149.4pt;
              border-top: none;
              border-left: none;
              border-bottom: solid 1pt;
              border-right: solid 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 25.25pt;
            "
          >
            <p style="font-size: 0.7em">
              Please install into the bathrooms for patients, by the hand-dryer
              or entry location
            </p>
          </td>
        </tr>
        <tr style="height: 25.25pt">
          <td
            width="66"
            valign="top"
            style="
              width: 49.4pt;
              border: solid 1pt;
              border-top: none;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 25.25pt;
            "
          >
            <p style="font-size: 0.7em">A4 Poster</p>
          </td>
          <td
            width="167"
            valign="top"
            style="
              width: 125.15pt;
              border-top: none;
              border-left: none;
              border-bottom: solid 1pt;
              border-right: solid 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 25.25pt;
            "
          >
            <p style="font-size: 0.7em">The free app… Pregnant lady</p>
            <p>
              <img
                width="130"
                src="/media/rtc_cope_act_f.png"
                align="left"
                hspace="12"
              />
            </p>
          </td>
          <td
            width="66"
            valign="top"
            style="
              width: 49.6pt;
              border-top: none;
              border-left: none;
              border-bottom: solid 1pt;
              border-right: solid 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 25.25pt;
            "
          >
            <p style="font-size: 0.7em">1</p>
          </td>
          <td
            width="199"
            valign="top"
            style="
              width: 149.4pt;
              border-top: none;
              border-left: none;
              border-bottom: solid 1pt;
              border-right: solid 1pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
              height: 25.25pt;
            "
          >
            <p style="font-size: 0.7em">
              Please install into the bathrooms for patients, by the hand-dryer
              or entry location
            </p>
          </td>
        </tr>
      </table>
      <div style="display: block; clear: both; margin-bottom: 30px">&nbsp;</div>
    </article>
  </div>
</template>

<script>
export default {
  props: {
    isCope: {
      type: Boolean,
      default: false,
    },
    venue: {},
  },
};
</script>

<style lang="scss">
.c-cope-installation {
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
  margin-left: -20px;
  width: calc(100% + 40px);

  .article-text-stretch {
    > table {
      //margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
}
</style>
