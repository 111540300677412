import Vue from "vue";
import VueRouter from "vue-router";

import Venue from "../views/venue";
import Venues from "../views/venues";

import Map from "../views/map";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Venues",
    component: Venues,
  },
  {
    path: "/venue/:id",
    name: "Venue",
    component: Venue,
  },
  {
    path: "/map",
    name: "Map",
    component: Map,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
