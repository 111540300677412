<template>
  <div :class="['form-group c-input-choice', { vertical: vertical === true }]">
    <label v-if="label" class="form__label">{{ label }}</label>
    <div class="c-input-choice__grid">
      <label
        :class="[
          'c-input-choice__item',
          { active: option.value === currentValue },
        ]"
        v-for="option in options"
        :key="option.value"
      >
        <input
          :name="Math.floor(Math.random() * 100)"
          type="checkbox"
          :id="`${slugify(option.value)}`"
          :value="option.value"
          @change="onChange"
        />
        <span>
          {{ option.label }}
        </span>
        <p v-if="option.description">
          {{ option.description }}
        </p>
      </label>
    </div>
    <InputError v-if="hasError" :message="errorMessage"></InputError>
    <InputDescription
      v-if="description"
      :message="description"
    ></InputDescription>
  </div>
</template>

<script>
import InputError from "./input-error";
import InputDescription from "./input-description";
import slugify from "@swipekit/lib/slugify.js";

export default {
  components: {
    InputError,
    InputDescription,
  },

  data: function () {
    return {
      currentValue: "",
    };
  },

  props: {
    label: {
      type: String,
      default: "",
    },
    validation: {},
    value: {},
    placeholder: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: function () {
        return [];
      },
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    noStyle: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: "",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {},
  },

  computed: {
    slug: function () {
      return slugify(this.label);
    },
  },

  methods: {
    onChange: function (e) {
      let value = e.target.value;
      this.currentValue = value;
      this.$emit("input", value);
    },
    slugify: function (str) {
      return slugify(str);
    },
  },

  mounted: function () {
    this.currentValue = this.value;
  },
};
</script>

<style lang="scss">
.c-input-choice {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: var(--margin-lg);

  &__grid {
    display: flex;
  }

  &__item {
    display: flex;
    margin-bottom: var(--margin);
    padding: var(--spacer-sm);
    border: var(--g6) solid 1px;
    font-size: var(--font-size);
    background-color: var(--g12);
    line-height: 1;
    cursor: pointer;

    &:not(:last-child) {
      border-right-color: transparent;
    }

    &:first-child {
      border-top-left-radius: var(--border-radius);
      border-bottom-left-radius: var(--border-radius);
    }

    &:last-child {
      border-top-right-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
    }

    &:before {
      content: "";
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-right: var(--margin);
      border-radius: 50%;
      border: var(--black) solid 2px;
      background-color: var(--white);
    }

    input {
      display: none;
    }

    p {
      margin-top: var(--margin);
      margin-bottom: 0;
      font-size: var(--font-size-sm);
      line-height: 1.45;
      opacity: 0.75;
    }

    &.active {
      background-color: var(--primary);
      color: var(--white);
      border-color: transparent;

      &:before {
        border-color: var(--white);
        background-color: var(--primary);
      }
    }
  }

  &.vertical {
    .c-input-choice__grid {
      display: block;
    }
    .c-input-choice__item {
      margin-bottom: 0;

      &:not(:last-child) {
        border-right-color: var(--g6);
        border-bottom-color: transparent;
      }

      &:first-child {
        border-bottom-left-radius: 0;
        border-top-left-radius: var(--border-radius);
        border-top-right-radius: var(--border-radius);
      }

      &:last-child {
        border-top-right-radius: 0;
        border-bottom-left-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
      }
    }
  }
}
</style>
