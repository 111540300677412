<template>
  <div class="form-group c-input-signature">
    <label v-if="label" class="form__label" :for="slug">{{ label }}</label>
    <canvas ref="canvas" width="320" height="200"> </canvas>
    <InputError v-if="hasError" :message="errorMessage"></InputError>
    <InputDescription
      v-if="description"
      :message="description"
    ></InputDescription>
    <a v-if="signature_svg" href="#" @click="onReset">Reset signature</a>
  </div>
</template>

<script>
import InputError from "./input-error";
import InputDescription from "./input-description";
import slugify from "@swipekit/lib/slugify.js";
import SignaturePad from "signature_pad";

export default {
  components: {
    InputError,
    InputDescription,
  },

  data: function () {
    return {
      signature: null,
      signature_svg: "",
    };
  },

  watch: {},

  props: {
    label: {
      type: String,
      default: "",
    },
    validation: {},
    value: {},
    placeholder: {
      type: String,
      default: "",
    },
    noStyle: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: "",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {},
  },

  watch: {
    // value: function () {
    //   if (this.value) {
    //     console.log(typeof this.value);
    //     let signature = this.signature.fromData(this.value);
    //   }
    // },
  },

  computed: {
    slug: function () {
      return slugify(this.label || `input_${Math.floor(Math.random() * 100)}`);
    },
  },

  methods: {
    onEndStroke: function () {
      let signature = this.signature.toData();
      let signature_svg = this.signature.toDataURL();

      this.signature_svg = signature_svg;

      let payload = {
        signature,
        signature_svg,
      };
      this.$emit("input", payload);
    },

    onReset: function () {
      this.signature.clear();
      this.signature_svg = "";

      this.$emit("input", null);
    },
  },

  mounted: function () {
    this.signature = new SignaturePad(this.$refs.canvas);

    this.signature.addEventListener("endStroke", this.onEndStroke);

    if (this.value && typeof this.value === "object") {
      this.signature.fromData(this.value);
    }
  },

  beforeDestroy: function () {
    this.signature.off();
  },
};
</script>

<style lang="scss">
.c-input-signature {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: var(--margin-lg);

  canvas {
    border-radius: var(--border-radius);
    background-color: var(--color-bg-3);
    border: var(--color-bg-5) dashed 1px;
  }

  a {
    font-size: var(--font-size-sm);
    text-decoration: underline;
    color: var(--font-color);
  }
}
</style>
