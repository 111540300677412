<template>
  <div class="form-group c-input-checkbox-group">
    <label v-if="label" class="form__label" :for="slug">{{ label }}</label>
    <div class="c-input-checkbox-group__inner">
      <div
        v-for="option in computedOptions"
        :key="option.value"
        :class="[
          'c-input-checkbox-group__item',
          { active: selected.includes(option.value) },
        ]"
        @click="toggleSlug(option.value)"
      >
        <input
          type="checkbox"
          name="input-options"
          v-model="selected"
          :value="option.value"
        />
        <label>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM17.5694 9.48809C17.839 9.1736 17.8026 8.70012 17.4881 8.43056C17.1736 8.16099 16.7001 8.19741 16.4306 8.51191L10.9576 14.897L7.53033 11.4697C7.23744 11.1768 6.76256 11.1768 6.46967 11.4697C6.17678 11.7626 6.17678 12.2374 6.46967 12.5303L10.4697 16.5303C10.6174 16.6781 10.82 16.7575 11.0288 16.7494C11.2375 16.7414 11.4335 16.6467 11.5694 16.4881L17.5694 9.48809Z"
              fill="currentColor"
            />
          </svg>

          {{ option.key }}
        </label>
      </div>
    </div>
    <InputError v-if="hasError" :message="errorMessage"></InputError>
    <InputDescription
      v-if="description"
      :message="description"
    ></InputDescription>
  </div>
</template>

<script>
import InputError from "./input-error";
import InputDescription from "./input-description";
import slugify from "@swipekit/lib/slugify.js";

export default {
  components: {
    InputError,
    InputDescription,
  },

  data: function () {
    return {
      selected: [],
    };
  },

  watch: {
    selected: function () {
      this.onChange({
        target: {
          value: this.selected,
        },
      });
    },
    // value: function () {
    //   let value = this.value;

    //   if (value && value.length > 0 && value.length !== this.selected.length) {
    //     let temp = value.map((opt) => {
    //       if (typeof opt === "string") {
    //         return opt;
    //       }
    //       if (typeof opt === "number") {
    //         return opt;
    //       }
    //       return opt.value;
    //     });
    //     console.log("UPDATE");
    //     console.log(temp);
    //     this.selected = temp;
    //   }
    // },
  },

  props: {
    label: {
      type: String,
      default: "",
    },
    validation: {},
    value: {},
    placeholder: {
      type: String,
      default: "",
    },
    noStyle: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: "",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {},
    options: {},
  },

  computed: {
    slug: function () {
      return slugify(this.label || `input_${Math.floor(Math.random() * 100)}`);
    },
    computedOptions: function () {
      let arr = [];
      let options = this.options;
      for (let i = 0; i < options.length; i++) {
        if (typeof options[i] === "string") {
          arr.push({
            key: options[i],
            value: options[i],
          });
        } else {
          arr.push(options[i]);
        }
      }
      return arr;
    },
  },

  methods: {
    toggleSlug: function (slug) {
      if (this.selected.includes(slug)) {
        let i = this.selected.indexOf(slug);
        if (i > -1) {
          this.selected.splice(i, 1);
        }
      } else {
        this.selected.push(slug);
      }
    },
    slugify: function (str) {
      return str;
    },
    onChange: function (e) {
      let value = e.target.value;

      if (value) {
        // clean array;
        value = value.filter((n) => n);
      }
      this.$emit("input", value);
    },
  },

  created: function () {
    let value = this.value;

    //console.log(value);

    if (value && value.length > 0) {
      let temp = value.map((opt) => {
        if (typeof opt === "string") {
          return opt;
        }
        if (typeof opt === "number") {
          return opt;
        }
        return opt.value;
      });
      this.selected = temp;
    }
  },
};
</script>

<style lang="scss">
.c-input-checkbox-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: var(--margin-lg);

  &__inner {
    display: flex;
    flex-direction: column;
    width: 100%;

    border: var(--color-input-background) solid 1px;
    border-radius: var(--border-radius);
    overflow: hidden;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0px 0px;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: var(--margin-lg);

    cursor: pointer;

    user-select: none;

    input {
      display: none;
    }

    &:hover {
      background-color: var(--color-bg-4);
    }

    label {
      margin: 0;
      display: flex;
      align-items: center;
      font-size: var(--font-size);

      cursor: pointer;

      svg {
        margin-right: var(--margin);
        min-width: 24px;
        color: #fff;
        border: var(--color-bg-6) solid 2px;
        border-radius: var(--border-radius-full);
      }
    }

    &.active {
      svg {
        color: var(--color-primary);
        border: none;
      }
    }
  }

  .c-input-description {
    margin-top: var(--margin);
  }
}
</style>
