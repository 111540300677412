<template>
  <div class="form-group c-input-datepicker">
    <label v-if="label" class="form__label" :for="slug">{{ label }}</label>
    <!-- <input
      :class="[{ 'form-control': noStyle === false }]"
      :id="slug"
      type="time"
      v-model="text"
      :placeholder="placeholder"
    /> -->
    <vue-timepicker v-model="text" :minute-interval="15"></vue-timepicker>
    <InputError v-if="hasError" :message="errorMessage"></InputError>
    <InputDescription
      v-if="description"
      :message="description"
    ></InputDescription>
  </div>
</template>

<script>
import InputError from "./input-error";
import InputDescription from "./input-description";
import slugify from "@swipekit/lib/slugify.js";
import VueTimepicker from "vue2-timepicker";

import moment from "moment";

function roundTimeQuarterHour(time) {
  var timeToReturn = new Date(time);

  timeToReturn.setMilliseconds(
    Math.round(timeToReturn.getMilliseconds() / 1000) * 1000
  );
  timeToReturn.setSeconds(Math.round(timeToReturn.getSeconds() / 60) * 60);
  timeToReturn.setMinutes(Math.round(timeToReturn.getMinutes() / 15) * 15);
  return timeToReturn;
}

export default {
  components: {
    InputError,
    InputDescription,
    VueTimepicker,
  },

  data: function () {
    return {
      text: "",
    };
  },

  props: {
    label: {
      type: String,
      default: "",
    },
    validation: {},
    value: {},
    placeholder: {
      type: String,
      default: "",
    },
    noStyle: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: "",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {},
  },

  watch: {
    text: function () {
      this.$emit("input", this.text);
    },
    // value: function () {
    //   let currentValue = this.text;

    //   let value = this.value;

    //   if (value !== currentValue) {
    //     this.text = value;
    //   }
    // },
  },

  computed: {
    slug: function () {
      return slugify(this.label || `input_${Math.floor(Math.random() * 100)}`);
    },
  },

  mounted: function () {
    if (this.value) {
      const d = roundTimeQuarterHour(this.value);

      const temp = d.toISOString();

      let tmp = moment.utc(temp);
      let str = tmp.format("HH:mm");
      this.text = str;
      return;
      //let iso = format(this.value, "yyyy-MM-dd'T'HH:mm:ss'Z'");
    }
  },
};
</script>

<style lang="scss">
.c-input-datepicker {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: var(--margin-lg);

  .vue__time-picker {
    display: inline-block;
    position: relative;
    font-size: 1em;
    width: 10em;
    font-family: sans-serif;
    vertical-align: middle;
  }
  .vue__time-picker * {
    box-sizing: border-box;
  }
  .vue__time-picker input.display-time {
    border: 1px solid #d2d2d2;
    width: 10em;
    height: 2.2em;
    padding: 0.3em 0.5em;
    font-size: 1em;
  }
  .vue__time-picker input.has-custom-icon {
    padding-left: 1.8em;
  }
  .vue__time-picker input.display-time.invalid:not(.skip-error-style) {
    border-color: #cc0033;
    outline-color: #cc0033;
  }
  .vue__time-picker input.display-time:disabled,
  .vue__time-picker input.display-time.disabled {
    color: #d2d2d2;
  }
  .vue__time-picker .controls {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 3;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: stretch;
    /* Prevent browser focusing on the controls layer */
    pointer-events: none;
  }
  .vue__time-picker .controls > * {
    cursor: pointer;

    width: auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding: 0 0.35em;
    color: #d2d2d2;
    line-height: 100%;
    font-style: normal;
    /* Resume pointer-events on children components */
    pointer-events: initial;
    transition: color 0.2s, opacity 0.2s;
  }
  .vue__time-picker .controls > *:hover {
    color: #797979;
  }
  .vue__time-picker .controls > *:focus,
  .vue__time-picker .controls > *:active {
    outline: 0;
  }
  .vue__time-picker .controls .char {
    font-size: 1.1em;
    line-height: 100%;
    /* Vertical align fixes for webkit browsers only */
    -webkit-margin-before: -0.15em;
  }
  .vue__time-picker .custom-icon {
    z-index: 2;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 1.8em;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    /* pass down mouse events to the <input> underneath */
    pointer-events: none;
  }
  .vue__time-picker .custom-icon img,
  .vue__time-picker .custom-icon svg,
  .vue__time-picker .controls img,
  .vue__time-picker .controls svg {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    border: 0;
    outline: 0;
    max-width: 1em;
    height: auto;
  }
  .vue__time-picker .time-picker-overlay {
    z-index: 4;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .vue__time-picker .dropdown,
  .vue__time-picker-dropdown {
    position: absolute;
    z-index: 5;
    top: calc(2.2em + 2px);
    left: 0;
    background: #fff;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
    width: 10em;
    height: 10em;
    font-weight: normal;
  }
  /* Dropdown class when "append-to-body" is on */
  .vue__time-picker-dropdown {
    position: fixed;
    z-index: 100;
  }
  .vue__time-picker .dropdown.drop-up,
  .vue__time-picker-dropdown.drop-up {
    top: auto;
    bottom: calc(2.2em + 1px);
  }
  .vue__time-picker .dropdown .select-list,
  .vue__time-picker-dropdown .select-list {
    width: 10em;
    height: 10em;
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: space-between;
  }
  .vue__time-picker .dropdown .select-list:focus,
  .vue__time-picker .dropdown .select-list:active,
  .vue__time-picker-dropdown .select-list:focus,
  .vue__time-picker-dropdown .select-list:active {
    outline: 0;
  }
  .vue__time-picker .dropdown ul,
  .vue__time-picker-dropdown ul {
    padding: 0;
    margin: 0;
    list-style: none;
    outline: 0;
    flex: 1 1 0.00001px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .vue__time-picker .dropdown ul.minutes,
  .vue__time-picker .dropdown ul.seconds,
  .vue__time-picker .dropdown ul.apms,
  .vue__time-picker-dropdown ul.minutes,
  .vue__time-picker-dropdown ul.seconds,
  .vue__time-picker-dropdown ul.apms {
    border-left: 1px solid #fff;
  }
  .vue__time-picker .dropdown ul li,
  .vue__time-picker-dropdown ul li {
    list-style: none;
    text-align: center;
    padding: 0.3em 0;
    color: #161616;
  }
  .vue__time-picker .dropdown ul li:not(.hint):not([disabled]):hover,
  .vue__time-picker .dropdown ul li:not(.hint):not([disabled]):focus,
  .vue__time-picker-dropdown ul li:not(.hint):not([disabled]):hover,
  .vue__time-picker-dropdown ul li:not(.hint):not([disabled]):focus {
    background: rgba(0, 0, 0, 0.08);
    color: #161616;
    cursor: pointer;
  }
  .vue__time-picker .dropdown ul li:not([disabled]).active,
  .vue__time-picker .dropdown ul li:not([disabled]).active:hover,
  .vue__time-picker .dropdown ul li:not([disabled]).active:focus,
  .vue__time-picker-dropdown ul li:not([disabled]).active,
  .vue__time-picker-dropdown ul li:not([disabled]).active:hover,
  .vue__time-picker-dropdown ul li:not([disabled]).active:focus {
    background: #41b883;
    color: #fff;
  }
  .vue__time-picker .dropdown ul li[disabled],
  .vue__time-picker .dropdown ul li[disabled]:hover,
  .vue__time-picker-dropdown ul li[disabled],
  .vue__time-picker-dropdown ul li[disabled]:hover {
    background: transparent;
    opacity: 0.3;
    cursor: not-allowed;
  }
  .vue__time-picker .dropdown .hint,
  .vue__time-picker-dropdown .hint {
    color: #a5a5a5;
    cursor: default;
    font-size: 0.8em;
  }
}
</style>
