import api from "@swipekit/lib/api";
import { differenceInDays, parseISO, subDays } from "date-fns/parseISO";
import * as Sentry from "@sentry/vue";

let store = {
  namespaced: true,
  state: {
    user: {},
    isExtension: false,
    trialLength: 10,
  },
  getters: {
    userName: function (state, getters) {
      if (!state.user) {
        return "";
      }
      let f = state.user.users.first_name;
      let l = state.user.users.last_name;
      return `${f} ${l}`;
    },
    user: function (state) {
      return state.user;
    },
    isAuth: function (state) {
      return state.user && state.user.user_id;
    },
    trialStarted: function (state) {
      if (!state.user || !state.user.meta || !state.user.trialStartedAt) {
        return;
      }

      return state.user.trialStartedAt;
    },
    trialStartedDate: function (state, getters) {
      if (!getters.trialStarted) return;

      return parseISO(getters.trialStarted);
    },
    trialStartedDifference: function (state, getters) {
      if (!getters.trialStartedDate) return;

      let trialEndDate = subDays(getters.trialStartedDate, state.trialLength);

      return differenceInDays(getters.trialStartedDate, trialEndDate);
    },
    isTrialOver: function (state, getters) {
      if (!state.user) return;

      return state.user.plan === "DEACTIVATED";

      // let diff = getters.trialStartedDifference;

      // if (diff > state.trialLength) {
      //   return true;
      // }
      // return false;
    },
    isPaid: function (state) {
      return state.user.plan === "PRO" || state.user.plan === "TESTER";
    },
    isAdmin: function (state) {
      return state.user && state.user.type === "ADMIN";
    },
  },
  mutations: {
    SET: function (state, user) {
      if (user) {
        state.user = user;
      } else {
        state.user = {};
      }
    },
  },
  actions: {
    async init(store) {
      await store.dispatch("headlessLogin").catch(console.error);

      let sentryUser = null;

      if (store.state.user) {
        sentryUser = {
          id: store.state.user.user_id,
          email: store.state.user.users.email,
          username: store.state.user.users.user_name,
          ip_address: "{{auto}}",
        };
        Sentry.setUser(sentryUser);
      }

      console.log("store.state.user", store.state.user);

      console.log("sentryUser", sentryUser);

      return true;
    },

    async getUser(store) {
      let users = await api.users.get();
      if (users.length > 0) {
        store.commit("SET", users[0]);
      }
    },

    async setUser(store) {},

    async create(store, ad) {},

    async signup(store, form) {
      let config = store.rootState.config;
      form.meta = {
        browser: config.browser,
        mode: config.mode,
        screenX: config.screenX,
        screenY: config.screenY,
        os: config.os,
      };
      let response = await api.users.signup(form);

      store.commit("SET", response);

      return response;
    },

    async update(store, form) {
      form.id = store.state.user.id;
      let response = await api.users.update(form);

      store.commit("SET", response);

      //store.dispatch("afterLogin", null, { root: true });

      return response;
    },

    async updateMarketing(store) {
      let user = store.state.user;

      let marketing = user.marketing || {};

      let config = store.rootState.config;

      let screen = `${config.screenX}x${config.screenY}`;

      marketing.screen = screen;
      marketing.timezone = config.timezone;
      marketing.utcOffset = config.utcOffset;

      let form = {
        id: user.id,
        marketing: marketing,
      };

      user = await api.users.update(form).catch((err) => {
        console.log(err);
      });

      if (user) {
        store.commit("SET", user);
      }
    },

    /**
     * Basically, gets the user if they are logged in
     * If not logged in, returns null
     * If logged in, sets the user
     *
     * Run this ideally, before init
     */
    async headlessLogin(store) {
      let user = null;
      if (!store.state.isExtension) {
        user = await api.users.headlessLogin();
      } else {
        user = await api.users.headlessChromeLogin();
      }
      if (user) {
        store.commit("SET", user);
      }

      //store.dispatch("afterLogin", null, { root: true });

      return user;
    },

    async resetPassword(store, form) {
      return api.users.resetPassword(form);
    },

    async updatePassword(store, form) {
      let user = await api.users.updatePassword(form);

      if (user) {
        store.commit("SET", user);
      }

      return user;
    },

    /**
     * Logs in user using the form pass as payload
     * If user logs in successfully, they are committed to the store
     */
    async login(store, form) {
      let user = await api.users.login(form);

      if (user) {
        store.commit("SET", user);
      }

      await store.dispatch("sendTokenToChromeExtension");

      return user;
    },

    async sendTokenToChromeExtension(store, auth = true) {
      let token = auth ? (await api.users.getToken()) || "" : "";

      if (!chrome.runtime) {
        return;
      }
      chrome.runtime.sendMessage(
        store.rootState.extensionId,
        { type: `auth`, jwt: token },
        (response) => {}
      );
    },

    async logout(store) {
      // Sometimes the server returns a 403, not sure how to handle it for now but the frontend assumes that the cookie is now invalid/user's session has been destroyed successfully
      let success = await api.users.logout();

      store.dispatch("message/onLogout", null, { root: true });

      // Somewhere over here also call afterLogout on the main store so other modules can flush their state out
      //if (success) {
      store.dispatch("afterLogout", null, { root: true });
      store.commit("SET");
      store.dispatch("sendTokenToChromeExtension", false);
      //}
    },

    async upgrade(store, plan) {
      let data = await api.users.upgrade(plan);

      if (data && data.url) {
        window.location = data.url;
      }
    },

    async getPie(store) {
      if (!store.state.user) {
        return;
      }

      let pie = await api.users.pie();

      return pie;
    },

    async acceptInvite(store, form) {
      let response = await api.users.acceptInvite(form);

      if (response && response.data) {
        store.commit("SET", response.data);
      }

      return response.data;
    },
  },
  modules: {},
};

export default store;
