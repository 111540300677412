<template>
  <div class="c-input-description">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <circle cx="12" cy="12" r="10"></circle>
      <line x1="12" y1="16" x2="12" y2="12"></line>
      <line x1="12" y1="8" x2="12.01" y2="8"></line>
    </svg>
    <span>{{ message }}</span>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.c-input-description {
  display: flex;
  align-items: center;
  padding-bottom: var(--margin-lg);
  margin: 0 0 0;
  font-size: var(--font-size-sm);
  color: var(--g4);

  svg {
    min-width: 24px;
    margin-right: var(--margin-sm);
    opacity: 0.65;
  }
}
</style>
