import api from "@swipekit/lib/api.js";

import CrudStore from "@swipekit/lib/crud-store";

let crud = new CrudStore({
  name: "workspaces",
  singular: "workspace",
});

const state = {
  ...crud.state,
  isSingleton: true,
};
const getters = {
  ...crud.getters,

  isAdmin: function (state, getters, rootState, rootGetters) {
    let workspace = state.resource;
    let user = rootGetters["users/user"];

    if (!user) {
      return false;
    }

    if (user.id !== workspace.adminId) {
      return false;
    }

    return true;
  },
};
const actions = {
  ...crud.actions,

  async getLocal(store) {
    let resources = [];
    try {
      resources = await crud.api[crud.resourceName].get("local");
    } catch (err) {
      throw err;
    }

    store.commit("SET", resources);
    return resources;
  },

  async doAction(store, obj) {
    let action = obj.action;
    let id = obj.id;
    let response = await crud.api.api.doAction(id, action);

    return response;
  },

  async fetchAndStore(store) {
    let resources = (await crud.api[crud.resourceName].fetchAndStore()) || [];

    store.commit(resources);
  },

  async invite(store, form) {
    let workspace = await api.workspaces.invite(form).catch((err) => {
      throw err;
    });

    if (workspace) {
      store.commit("SET", workspace);
    }

    return workspace;
  },

  async removeUser(store, form) {
    let workspace = await api.workspaces.removeUser(form);

    if (workspace) {
      store.commit("SET", workspace);
    }
  },

  consumePie: async (store, pie) => {
    if (pie.workspace) {
      let workspace = {
        ...pie.workspace,
      };

      delete workspace.boards;
      delete workspace.ads;
      store.commit("SET", workspace);
    }
  },
};
const mutations = {
  ...crud.mutations,
};

let store = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default store;
