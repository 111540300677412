<template>
  <div class="c-venue-empty-state">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.c-venue-empty-state {
  text-align: center;
  border: var(--color-bg-5) solid 1px;
  background-color: var(--color-bg-3);
  padding: var(--spacer);
  border-radius: var(--border-radius);

  p:last-child {
    margin-bottom: 0;
  }
}
</style>
