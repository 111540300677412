<template>
  <div class="c-venue-contract-blank">
    <FormContractBlank
      :venue_id="venue.venue_id"
      @onSubmit="onSubmit"
    ></FormContractBlank>
  </div>
</template>

<script>
import FormContractBlank from "@swipekit/components/form/form-contract-blank";

export default {
  components: {
    FormContractBlank,
  },

  props: {
    venue: {},
    venue_id: {},
  },

  computed: {
    contract: function () {
      let id = 1228;

      let contract = this.$store.getters["localisedTexts/one"](id);

      if (!contract) {
        return "";
      }

      return contract.text;
    },
    venue_name: function () {
      return this.venue.venue_name;
    },
    date: function () {
      const today = new Date();
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();

      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;

      const formattedToday = dd + "/" + mm + "/" + yyyy;

      return formattedToday;
    },
  },

  methods: {
    onSubmit: function (e) {
      this.$emit("onSubmit", e);
    },
  },
};
</script>

<style lang="scss">
.c-venue-contract-blank {
  background-color: var(--color-bg-3);
  padding: var(--spacer-sm);

  table {
    width: 100%;
  }

  h1 {
    font-size: var(--font-size-xl);
  }
  h2 {
    font-size: var(--font-size-lg);
    margin-bottom: var(--margin);
  }
}
</style>
