import Vue from "vue";
import Vuex from "vuex";

import { store } from "@swipekit/lib/store";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    extensionId: "gojmmkhaiojimnnjhhilmhjmhdbdagod",
    extensionActive: false,
  },
  getters: {
    extensionId: function (state) {
      return state.extensionId;
    },
    standalone: function (state) {
      return false;
    },
    extensionState: function (state) {
      return state.extensionActive;
    },
  },
  mutations: {},
  actions: {
    async init(store, jwt = null) {
      let pie = null;

      // only for testing
      if (!jwt) {
        jwt = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7InVzZXJfaWQiOjE0fSwiaWF0IjoxNzAxNzU1Mjk4fQ.3GKSUxUusYvtr0wKtSWlHzzMCramFPELT7fYyTS4c_w`;
      }

      await store.dispatch("config/init", { jwt: jwt });

      await store.dispatch("app/init");

      await store.dispatch("users/init");

      await store.dispatch("events/init");

      await store.dispatch("message/init");

      // ---

      if (store.rootGetters["users/isAuth"]) {
        await store.dispatch("afterLogin", pie);
      }

      await store.dispatch("app/setInit"); // <!-- Very Important, app literally loads after this dispatch

      return true;
    },

    async afterLogin(store, pie) {
      await store.dispatch("venues/afterLogin", pie);

      await store.dispatch("lists/afterLogin", pie);

      await store.dispatch("listTypes/afterLogin", pie);

      await store.dispatch("localisedTexts/afterLogin", pie);

      return true;
    },

    async afterLogout(store) {
      await store.dispatch("venues/afterLogout");

      await store.dispatch("lists/afterLogout");

      await store.dispatch("listTypes/afterLogout");

      await store.dispatch("localisedTexts/afterLogout");

      return true;
    },

    async triggerExtension(store, obj) {
      if (typeof chrome === "undefined") {
        return;
      }
      if (!chrome) {
        return;
      }
      if (!chrome.runtime) {
        return;
      }
      return new Promise((resolve, reject) => {
        chrome.runtime.sendMessage(
          store.state.extensionId,
          obj,
          function (response) {
            resolve(response);
          }
        );
      });
    },

    async setExtensionState(store, condition) {
      store.state.extensionActive = condition;
    },
  },
  modules: {
    config: store.config,
    app: store.app,
    ads: store.ads,
    boards: store.boards,
    users: store.users,
    events: store.events,
    message: store.message,
    venues: store.venues,
    lists: store.lists,
    listTypes: store.listTypes,
    localisedTexts: store.localisedTexts,
  },
});
