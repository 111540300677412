<template>
  <vue-final-modal
    v-slot="{ params, close }"
    :clickToClose="closable"
    :escToClose="closable"
    :value="value"
    :classes="['modal-container']"
    :overlay-class="[{ 'non-closable': closable === false }]"
    content-class="modal-content"
    :transition="computedTransition"
    @input="onClose"
    @closed="onClose"
  >
    <span class="modal__title">
      <slot name="title"></slot>
    </span>
    <div class="modal__content">
      <slot v-bind:params="params"></slot>
    </div>
    <svg
      v-if="closable"
      @click="close"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM8.53033 7.46967C8.23744 7.17678 7.76256 7.17678 7.46967 7.46967C7.17678 7.76256 7.17678 8.23744 7.46967 8.53033L10.9393 12L7.46967 15.4697C7.17678 15.7626 7.17678 16.2374 7.46967 16.5303C7.76256 16.8232 8.23744 16.8232 8.53033 16.5303L12 13.0607L15.4697 16.5303C15.7626 16.8232 16.2374 16.8232 16.5303 16.5303C16.8232 16.2374 16.8232 15.7626 16.5303 15.4697L13.0607 12L16.5303 8.53033C16.8232 8.23744 16.8232 7.76256 16.5303 7.46967C16.2374 7.17678 15.7626 7.17678 15.4697 7.46967L12 10.9393L8.53033 7.46967Z"
        fill="currentColor"
      />
    </svg>
  </vue-final-modal>
</template>

<script>
/**
 * Modal HOC
 *
 * Use this as a base for your custom modals
 */
import {ModalsContainer, VueFinalModal} from "vue-final-modal";

export default {
  name: "Modal",

  components: {
    VueFinalModal,
    ModalsContainer,
  },

  data: function () {
    return {
      isActive: true,
    };
  },

  watch: {
    value: {
      handler: function () {
        this.isActive = this.value;
      },
      immediate: true,
    },
  },

  props: {
    closable: {
      type: Boolean,
      default: true,
    },
    value: {},
  },

  computed: {
    computedTransition: function () {
      return {
        "enter-active-class": "transition duration-20000 ease-in-out transform",
        "enter-class": "translate-y-full",
        "enter-to-class": "translate-y-0",
        "leave-active-class": "transition duration-20000 ease-in-out transform",
        "leave-to-class": "translate-y-full",
        "leave-class": "translate-y-0",
      };
    },
  },

  methods: {
    activate: function () {
      this.isActive = true;
    },
    deactivate: function () {
      this.isActive = false;
    },
    onClose: function () {
      this.$emit("input", this.isActive);
    },
  },
};
</script>

<style lang="scss">
.vfm {
  .vfm__overlay {
    background-color: hsla(var(--s), 6%, 40%, 0.6) !important;
    background-position: center center;
    backdrop-filter: blur(10px);

    &.non-closable {
      opacity: 1;
    }
  }
  .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow-y: auto;
    padding: 20px 0;
  }

  .modal-content {
    margin: auto;
    position: relative;
    width: 720px;
    padding: var(--space-md) var(--space-lg);
    background-color: var(--color-bg-3);
    //border-radius: var(--border-radius);

    box-shadow: 0px 1.2px 2.2px rgba(0, 0, 0, 0.039), 0px 2.9px 5.3px rgba(0, 0, 0, 0.057),
      0px 5.4px 10px rgba(0, 0, 0, 0.07), 0px 9.6px 17.9px rgba(0, 0, 0, 0.083), 0px 18px 33.4px rgba(0, 0, 0, 0.101),
      0px 43px 80px rgba(0, 0, 0, 0.14);

    border-bottom-width: 3px;
    border-radius: var(--border-radius-lg);

    > svg {
      position: absolute;
      top: var(--spacer-sm);
      right: var(--spacer-sm);
      width: 32px;
      height: 32px;
      opacity: 1;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }
  }

  @media screen and (max-width: 996px) {
    .modal-content {
      margin: auto;
      width: calc(100% - 20px);
    }
  }
}
</style>
