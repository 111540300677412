<template>
  <div class="c-venue">
    <div class="c-venue__header">
      <VenueCard :venue="venue" :contacts="contacts"></VenueCard>
    </div>
    <div class="c-venue__tabs">
      <template v-for="tab in tabs">
        <a
          v-if="!tab.hidden"
          href="#"
          :class="[
            { active: tab.slug === currentSection },
            { disabled: tab.disabled === true },
          ]"
          :key="tab.slug"
          @click.prevent="onTabClick(tab)"
        >
          {{ getName(tab.name) }}
        </a>
      </template>
    </div>
    <div class="c-venue__sections" ref="venue-sections">
      <!-- Outside venue -->
      <div
        :class="[
          'c-venue__section',
          { active: currentSection === 'outside-venue' },
        ]"
      >
        <article class="article-text">
          <p>
            Before entering, take a street view photo of the Venue entrance.
          </p>
          <p>If possible, include the Venue signage.</p>
        </article>
        <FormVenuePhoto
          :isCope="isCope"
          :form="venue"
          @onSubmit="onOutsideVenueSubmit"
        ></FormVenuePhoto>
      </div>

      <!-- Cope Installation instructions -->
      <div
        :class="[
          'c-venue__section',
          { active: currentSection === 'installation' },
        ]"
      >
        <article class="article-text">
          <h3>COPE Installation Details</h3>
        </article>
        <br />
        <CopeInstallation :isCope="isCope" :venue="venue"></CopeInstallation>

        <FormVenueProof
          :form="venue"
          @onSubmit="onVenueProofSubmit"
        ></FormVenueProof>
        <hr />
        <a class="btn btn-primary" href="#" @click.prevent="onInstallationNext"
          >Next</a
        >
      </div>

      <!-- Bathrooms -->
      <div
        :class="[
          'c-venue__section',
          { active: currentSection === 'bathrooms' },
        ]"
      >
        <!--
        <div>
          <article class="article-text">
            <p>
              Go to the ground floor (if there's more than one floor) and to the
              first bathroom. Always start on the ground floor and work your way
              up.
            </p>
            <br />
          </article>
          <h4>Add new area</h4>
          <a
            v-if="!addNewSite"
            class="btn btn-primary btn-icon"
            @click="addNewSite = true"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <line x1="12" y1="5" x2="12" y2="19"></line>
              <line x1="5" y1="12" x2="19" y2="12"></line>
            </svg>
            Add new Area
          </a>
          <FormSiteCreate
            v-if="addNewSite === true"
            :venue_id="venue.venue_id"
            @onSubmit="onSiteSubmit"
          ></FormSiteCreate>
        </div>
        <hr />
        -->

        <article class="article-text">
          <p>
            Go to the ground floor (if there is more than one floor) and to the
            first bathroom. Always start on the ground floor and work your way
            up.
          </p>
        </article>
        <div v-if="!bathroomUpdate">
          <h4>Add new bathroom</h4>
          <a
            v-if="!addNewBathroom"
            class="btn btn-primary btn-icon"
            @click="addNewBathroom = true"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <line x1="12" y1="5" x2="12" y2="19"></line>
              <line x1="5" y1="12" x2="19" y2="12"></line>
            </svg>
            Add bathroom
          </a>
          <FormBathroomCreate
            v-if="addNewBathroom === true"
            :venue_id="venue.venue_id"
            @onSubmit="onBathroomSubmit"
          ></FormBathroomCreate>
        </div>
        <div v-if="bathroomUpdate">
          <h4>Update {{ bathroomUpdate.name }} bathroom</h4>
          <FormBathroomUpdate
            v-if="bathroomUpdate"
            :key="bathroomUpdate.bathroom_id"
            :form="bathroomUpdate"
            :venue_id="venue.venue_id"
            @onSubmit="onBathroomUpdate"
          >
          </FormBathroomUpdate>
        </div>
        <hr />

        <h4>Current Bathrooms</h4>
        <p>List of all bathrooms attached to this venue</p>
        <div class="c-simple-table" v-if="bathrooms && bathrooms.length > 0">
          <div
            class="c-simple-table__row"
            v-for="(bathroom, i) in bathrooms"
            :key="i"
          >
            <span>{{ bathroom.name }}</span>
            <a class="btn" @click.prevent="onBathroomUpdateRequest(bathroom)">
              Edit bathroom
            </a>
          </div>
        </div>
        <EmptyState v-else>
          <p>No bathrooms are attached to this venue.</p>
        </EmptyState>
        <hr />

        <br />

        <div class="c-venue__navigation">
          <a
            class="btn btn-primary btn-full"
            href="#"
            @click.prevent="onBathroomNext"
            >Next</a
          >
          <a class="btn btn-full" href="#" @click.prevent="onPrevious">
            Previous
          </a>
        </div>
      </div>

      <!-- Venue areas -->
      <div
        :class="['c-venue__section', { active: currentSection === 'sites' }]"
      >
        <div>
          <h4>Add new area</h4>
          <a
            v-if="!addNewSite"
            class="btn btn-primary btn-icon"
            @click="addNewSite = true"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <line x1="12" y1="5" x2="12" y2="19"></line>
              <line x1="5" y1="12" x2="19" y2="12"></line>
            </svg>
            Add new Area
          </a>
          <FormSiteCreate
            v-if="addNewSite === true"
            :venue_id="venue.venue_id"
            :form="{}"
            @onSubmit="onSiteSubmit"
          ></FormSiteCreate>
        </div>
        <hr />
        <!--
        <h4>Current Areas</h4>
        <p>List of all areas attached to this venue</p>
        <div class="c-simple-table" v-if="sites && sites.length > 0">
          <div class="c-simple-table__row" v-for="(site, i) in sites" :key="i">
            <span>{{ site.name }}</span>
            <a class="btn" @click.prevent="onSiteUpdateRequest(site)">
              Edit area
            </a>
          </div>
        </div>
        <EmptyState v-else>
          <p>No areas are attached to this venue.</p>
        </EmptyState>
        <hr />
        -->
        <a class="btn btn-primary btn-full" href="#" @click.prevent="onSiteNext"
          >Next</a
        >
      </div>

      <!-- Contact -->
      <div
        :class="['c-venue__section', { active: currentSection === 'contact' }]"
      >
        <article class="article-text">
          <p>Please update the venue licensee/venue owner details.</p>
        </article>
        <br />
        <template v-if="!addNewContact">
          <h4>Current Contacts</h4>
          <p>List of all contacts attached to this venue</p>
          <div class="c-simple-table" v-if="contacts && contacts.length > 0">
            <div
              class="c-simple-table__row"
              v-for="(contact, i) in contacts"
              :key="i"
            >
              <span>{{ contact.first_name }} {{ contact.last_name }}</span>
              <a class="btn" @click.prevent="onContactUpdateRequest(contact)">
                Edit contact
              </a>
            </div>
          </div>
          <EmptyState v-else>
            <p>No contacts are attached to this venue.</p>
          </EmptyState>
          <hr />
        </template>
        <div v-if="!contactUpdate">
          <h4>Add new contact</h4>
          <a
            v-if="!addNewContact"
            class="btn btn-primary btn-icon"
            @click="addNewContact = true"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <line x1="12" y1="5" x2="12" y2="19"></line>
              <line x1="5" y1="12" x2="19" y2="12"></line>
            </svg>
            Add a new contact
          </a>
          <FormContactCreate
            :isCope="isCope"
            v-if="addNewContact === true"
            :venue_id="venue.venue_id"
            :form="presetContact"
            @onSubmit="onCreateContact"
          ></FormContactCreate>
        </div>
        <div v-if="contactUpdate">
          <h4>Update contact</h4>
          <FormContactUpdate
            :isCope="isCope"
            v-if="contactUpdate"
            :form="contactUpdate"
            @onSubmit="onUpdateContact"
          >
          </FormContactUpdate>
        </div>

        <hr />
        <div class="c-venue__navigation">
          <a
            class="btn btn-full btn-primary"
            href="#"
            @click.prevent="onContactNext"
            >Next</a
          >
          <a class="btn btn-full" @click.prevent="onPrevious"> Previous </a>
        </div>
      </div>

      <!-- Venue Details -->
      <div
        :class="['c-venue__section', { active: currentSection === 'venue' }]"
      >
        <FormVenue
          :form="venue"
          :isCope="isCope"
          @onSubmit="onVenueSubmit"
        ></FormVenue>
      </div>

      <!-- Venue Demographics -->
      <div
        :class="[
          'c-venue__section',
          { active: currentSection === 'venue-demographics' },
        ]"
      >
        <div v-if="siteUpdate">
          <FormSiteUpdate
            v-if="siteUpdate"
            :form="siteUpdate"
            @onSubmit="onSiteUpdate"
          >
          </FormSiteUpdate>
        </div>
        <hr v-if="siteUpdate" />
        <template v-if="!siteUpdate">
          <article class="article-text">
            <p>
              Please ask the Venue licensee/owner how many patron visits attend
              the Venue every week.
            </p>
          </article>
          <!-- <FormEstimate></FormEstimate> -->
          <h4>Current Bathroom Areas</h4>
          <div class="c-simple-table" v-if="sites && sites.length > 0">
            <div
              class="c-simple-table__row"
              v-for="(site, i) in sites"
              :key="i"
            >
              <span>{{ site.name }}</span>
              <a class="btn" @click.prevent="onSiteUpdateRequest(site)">
                Edit Area Demographics
              </a>
            </div>
          </div>
          <EmptyState v-else>
            <p>No Areas are attached to this venue.</p>
          </EmptyState>
          <hr />
        </template>

        <div v-if="!siteUpdate">
          <h4>Add new area</h4>
          <a
            v-if="!addNewSite"
            class="btn btn-primary btn-icon"
            @click="addNewSite = true"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <line x1="12" y1="5" x2="12" y2="19"></line>
              <line x1="5" y1="12" x2="19" y2="12"></line>
            </svg>
            Add new Area
          </a>
          <FormSiteCreate
            v-if="addNewSite === true"
            :venue_id="venue.venue_id"
            @onSubmit="onSiteSubmit"
          ></FormSiteCreate>
        </div>
        <br />

        <div class="c-venue__navigation">
          <a
            class="btn btn-primary btn-full"
            href="#"
            @click.prevent="onVenueDemographicsSubmit"
            >Next</a
          >
          <a class="btn btn-full" @click.prevent="onPrevious"> Previous </a>
        </div>
      </div>

      <!-- Cope form -->
      <div
        :class="[
          'c-venue__section',
          { active: currentSection === 'cope-form' },
        ]"
      >
        <article
          v-if="
            this.isCope &&
            venue.additional_data.venue_type &&
            venue.additional_data.venue_type.toLowerCase() === 'mch'
          "
          class="article-text"
        >
          <h3>Message Feedback Data</h3>
          <div>
            <img
              style="max-width: 25%; float: left; margin-left: 30px"
              src="/media/rtc_cope_act_pr.png"
            />
          </div>
          <div style="display: block; clear: both; margin-bottom: 30px">
            &nbsp;
          </div>
        </article>

        <article
          v-else-if="
            this.isCope &&
            venue.additional_data.venue_type &&
            venue.additional_data.venue_type.toLowerCase() === 'hospital'
          "
          class="article-text"
        >
          <h3>Message Feedback Data</h3>
          <div>
            <img
              style="max-width: 25%; float: left"
              src="/media/rtc_cope_act_f.png"
            />
          </div>
          <div style="display: block; clear: both; margin-bottom: 30px">
            &nbsp;
          </div>
        </article>
        <article v-else class="article-text">
          <h3>Message Feedback Data</h3>
          <div>
            <img
              style="max-width: 25%; float: left"
              src="/media/rtc_cope_act_f.png"
            />
          </div>
          <div>
            <img
              style="max-width: 25%; float: left; margin-left: 30px"
              src="/media/rtc_cope_act_pr.png"
            />
          </div>
          <div style="display: block; clear: both; margin-bottom: 30px">
            &nbsp;
          </div>
        </article>
        <FormCopeCreate :form="venue" @onSubmit="onCopeSubmit"></FormCopeCreate>
      </div>

      <!-- Contract -->
      <div
        :class="['c-venue__section', { active: currentSection === 'contract' }]"
      >
        <VenueContractCope
          v-if="isCope"
          :venue="venue"
          :venue_id="venue.venue_id"
          @onSubmit="onContractSubmit"
        ></VenueContractCope>
        <VenueContract
          v-else-if="
            venue &&
            venue.additional_data &&
            venue.additional_data.renew_agreement
          "
          :venue="venue"
          :venue_id="venue.venue_id"
          @onSubmit="onContractSubmit"
        ></VenueContract>
        <VenueContractBlank
          v-else
          :venue="venue"
          :venue_id="venue.venue_id"
          @onSubmit="onContractSubmit"
        >
        </VenueContractBlank>
      </div>

      <!-- Finish -->
      <div
        :class="['c-venue__section', { active: currentSection === 'finish' }]"
      >
        <div class="c-venue__finish">
          <h3>Venue Details Completed</h3>
          <a
            :class="[
              'btn btn-full btn-primary',
              { disabled: processing === true },
            ]"
            href="#"
            @click.prevent="onVenueComplete"
            >Back to the Homepage</a
          >
        </div>
      </div>
    </div>
    <Modal404 :active="show404Modal"></Modal404>
  </div>
</template>

<script>
import FormVenuePhoto from "@swipekit/components/form/form-venue-photo";
import FormVenueProof from "@swipekit/components/form/form-venue-proof";
import FormVenue from "@swipekit/components/form/form-venue";
import FormContactCreate from "@swipekit/components/form/form-contact-create";
import FormContactUpdate from "@swipekit/components/form/form-contact-update";
import FormSiteCreate from "@swipekit/components/form/form-site-create";
import FormSiteUpdate from "@swipekit/components/form/form-site-update";
import FormBathroomCreate from "@swipekit/components/form/form-bathroom-create";
import FormBathroomUpdate from "@swipekit/components/form/form-bathroom-update";
import FormCopeCreate from "@swipekit/components/form/form-cope-create";
import FormEstimate from "@swipekit/components/form/form-estimate";

import VenueContract from "./contract";
import VenueContractCope from "./contract-cope";
import VenueContractBlank from "./contract-blank";
import VenueCard from "./card";

import EmptyState from "./empty-state";

import CopeInstallation from "./cope-installation.vue";

import api from "@swipekit/lib/api";

import Modal404 from "@swipekit/components/modal/modal-404";

export default {
  components: {
    FormVenuePhoto,
    FormVenueProof,
    FormVenue,
    FormContactCreate,
    FormContactUpdate,
    FormSiteCreate,
    FormSiteUpdate,
    FormBathroomCreate,
    FormBathroomUpdate,
    FormCopeCreate,
    VenueContract,
    VenueContractCope,
    VenueContractBlank,
    VenueCard,
    EmptyState,
    CopeInstallation,
    FormEstimate,
    Modal404,
  },

  data: function () {
    return {
      currentSection: "outside-venue",

      venue: null,
      sites: null,
      bathrooms: null,
      contract: null,
      contact: null,

      // experimental
      contacts: null,

      presetContact: {},

      flags: {
        sites: true,
        bathrooms: true,
        venue: false,
        contract: true,
      },

      addNewSite: false,
      siteUpdate: null,

      addNewBathroom: true,
      bathroomUpdate: null,

      addNewContact: false,
      contactUpdate: null,

      venueAvailsFinished: false,
      processing: false,

      show404Modal: false,
    };
  },

  watch: {
    originalVenue: {
      handler: function () {
        if (!this.originalVenue) {
          this.show404Modal = true;
        } else {
          this.show404Modal = false;
        }
        let temp = {
          ...this.originalVenue,
        };

        if (temp.venue_sites) {
          this.sites = temp.venue_sites || [];
        }

        if (temp.bathrooms) {
          this.bathrooms = temp.bathrooms || [];
        }

        if (temp.media) {
          temp.overview_image = temp.media;
        }

        delete temp.bathrooms;

        delete temp.venue_sites;

        delete temp.contract;

        this.venue = temp;

        this.loadContacts();
      },
      immediate: true,
    },
    currentSection: function () {
      this.scrollTop();

      if (this.currentSection === "contact") {
        this.checkContactAdditionalData();
      }
    },
  },

  computed: {
    isCope: function () {
      let venue = this.venue;

      if (venue.additional_data && venue.additional_data.isCope) {
        return true;
      }

      return false;
    },
    tabs: function () {
      let tabs = [
        {
          name: "Outside Venue",
          slug: "outside-venue",
        },
      ];

      if (this.isCope) {
        tabs.push({
          name: "Contact Details",
          slug: "contact",
        });

        tabs.push({
          name: "COPE Install",
          slug: "installation",
        });

        tabs.push({
          name: "Venue",
          slug: "venue",
        });

        tabs.push({
          name: "Cope Form",
          slug: "cope-form",
        });

        tabs.push({
          name: "Contract",
          slug: "contract",
          disabled: !this.flags.contract,
        });
      } else {
        // tabs.push({
        //   name: "Venue Areas",
        //   slug: "sites",
        //   disabled: !this.flags.sites,
        // });

        tabs.push({
          name: "Contact Details",
          slug: "contact",
        });

        tabs.push({
          name: "Bathrooms",
          slug: "bathrooms",
          disabled: !this.flags.bathrooms,
        });

        tabs.push({
          name: "Venue Details",
          slug: "venue",
        });

        tabs.push({
          name: "Venue Demographics",
          slug: "venue-demographics",
        });

        tabs.push({
          name: "Contract",
          slug: "contract",
          disabled: !this.flags.contract,
        });

        tabs.push({
          name: "All done",
          slug: "finish",
          hidden: true,
        });
      }

      return tabs;
    },
    paramId: function () {
      return this.$route.params.id;
    },
    originalVenue: function () {
      let paramId = this.paramId;
      if (!paramId) {
        return null;
      }

      return this.$store.getters["venues/one"](paramId);
    },
  },

  methods: {
    getName: function (tabName) {
      if (tabName !== "Contract") {
        return tabName;
      }
      if (this.isCope) {
        return tabName;
      }
      if (
        this.venue &&
        this.venue.additional_data &&
        this.venue.additional_data.renew_agreement
      ) {
        return tabName;
      }
      return "Sign Off";
    },
    scrollTop: function () {
      let el = this.$refs["venue-sections"];
      el.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
    checkContactAdditionalData: function () {
      // dont create contacts if contact is already there
      if (this.contacts.length > 0) {
        return;
      }
      let additional_data = this.venue.additional_data;
      if (!additional_data) {
        return;
      }
      if (!additional_data.contact) {
        return;
      }

      this.presetContact = additional_data.contact;
      this.addNewContact = true;
    },
    onPrevious: function () {
      let slug = null;
      for (let i = 0; i < this.tabs.length; i++) {
        let tab = this.tabs[i];

        if (this.currentSection === tab.slug) {
          slug = this.tabs[i - 1].slug;
          break;
        }
      }

      if (!slug) {
        console.log("SLUG NOT MATCH");
        return;
      }

      this.currentSection = slug;
    },
    async loadContacts() {
      let venue = this.venue;
      let contacts = await api.contacts.getByVenue(venue);
      this.contacts = contacts;
    },
    onOutsideVenueSubmit: function () {
      if (this.isCope) {
        this.flags.cope = true;
        this.currentSection = "contact";
      } else {
        this.flags.cope = true;
        this.currentSection = "contact";
      }
    },
    onVenueProofSubmit: function () {
      this.currentSection = "venue";
    },
    onVenueProofSubmit: function () {
      this.flags.venue = true;
      this.currentSection = "venue";
    },
    onVenueSubmit: function () {
      if (this.isCope) {
        this.flags.cope = true;
        this.currentSection = "cope-form";
      } else {
        this.flags.contract = true;
        this.currentSection = "venue-demographics";
      }
    },
    onVenueDemographicsSubmit: function () {
      this.currentSection = "contract";
    },
    onCopeSubmit: function () {
      this.flags.contract = true;
      this.currentSection = "contract";
    },
    onSiteUpdate: function (updatedSite) {
      this.siteUpdate = null;

      this.sites = this.sites.map((site) => {
        if (site.site_id === updatedSite.site_id) {
          return updatedSite;
        }

        return site;
      });
    },
    onSiteNext: function () {
      this.flags.bathrooms = true;
      this.currentSection = "bathrooms";
    },
    onInstallationNext: function () {
      this.currentSection = "venue";
    },
    onContactNext: function () {
      if (!this.isCope) {
        this.currentSection = "bathrooms";
        return;
      }

      this.currentSection = "installation";
    },
    onSiteSubmit: function (site) {
      //this.sites.push(site);

      this.addNewSite = false;
    },
    onContactSubmit: function (contact) {
      this.addNewContact = false;
    },
    onSiteUpdateRequest: function (site) {
      this.siteUpdate = site;
    },
    onBathroomUpdateRequest: function (bathroom) {
      this.bathroomUpdate = bathroom;

      this.$nextTick(() => {
        this.scrollTop();
      });
    },
    onContactUpdateRequest: function (contact) {
      this.contactUpdate = contact;
    },
    onBathroomSubmit: function () {
      this.flags.sites = true;
      this.addNewBathroom = false;
    },
    onBathroomUpdate: function () {
      this.scrollTop();

      this.bathroomUpdate = null;
    },
    onBathroomNext: function () {
      this.flags.sites = true;
      this.addNewBathroom = false;
      this.currentSection = "venue";
    },
    onTabClick: function (tab) {
      this.currentSection = tab.slug;
    },
    onContractSubmit: function () {
      this.currentSection = "finish";

      this.setVenueComplete();
      // show something to signify this venue avails have been done
    },
    // runs after a contract is signed
    async setVenueComplete() {
      let venue = this.venue;

      let additional_data = venue.additional_data || {};

      let date = new Date();

      date = date.toISOString();

      additional_data.venueAvailsComplete = true;
      additional_data.venueAvailsCompleteAt = date;

      venue = {
        venue_id: venue.venue_id,
        status: 2,
        additional_data: additional_data,
      };

      console.log("UPDATING VENUE");

      await this.$store.dispatch("venues/completeVenue", venue);
    },
    // runs after the 'back to home' button is clicked
    async onVenueComplete() {
      try {
        this.processing = true;

        let venue = this.venue;

        let additional_data = venue.additional_data || {};

        additional_data.performVenueAvails = true;

        venue = {
          venue_id: venue.venue_id,
          additional_data: additional_data,
        };

        await this.$store.dispatch("venues/completeVenue", venue);

        window.location.href = "caams://close";

        setTimeout(() => {
          try {
            window.close();
          } catch (e) {
            console.error("Failed to close window", e);
            throw e;
          }
        }, 500);
      } catch (e) {
        console.error("Failed to complete venue", e);
        throw e;
      }
      //this.$router.push({ name: "Venues" });
    },

    onCreateContact: function (contact) {
      this.contacts.push(contact);

      this.addNewContact = false;
    },

    onUpdateContact: function (contact) {
      let contacts = this.contacts;

      for (let i = 0; i < contacts.length; i++) {
        if (contacts[i].contact_id === contact.contact_id) {
          contacts[i] = contact;
        }
      }

      this.contacts = contacts;
      this.contacts = this.contacts.slice();
    },
  },

  mounted: function () {},
};
</script>

<style lang="scss">
.c-venue {
  &__header {
    padding: var(--spacer-sm);

    h1,
    h2 {
      margin-bottom: 0;
    }
  }
  &__tabs {
    width: 100%;
    overflow-x: auto;
    display: flex;
    /*    padding: 0 var(--spacer); */

    a {
      flex-grow: 1;
      display: block;
      color: var(--color-font);
      padding: var(--margin-lg) var(--spacer-sm);
      border-bottom: var(--color-bg-4) solid 3px;
      text-decoration: none !important;

      &.active {
        color: var(--color-primary);
        border-bottom-color: var(--color-primary);
        background-color: hsla(var(--p), 60%, 50%, 0.05);
        font-weight: 500;
      }

      &:not(.disabled):hover {
        color: var(--color-primary);
        border-bottom-color: var(--color-primary);
        background-color: hsla(var(--p), 60%, 50%, 0.05);
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.65;
      }
    }
  }

  &__section {
    padding: var(--spacer-sm);
    display: none;

    &.active {
      display: block;
    }

    .c-simple-table__row {
      grid-template-columns: 3fr 1fr;
    }
  }

  &__navigation {
    display: grid;
    grid-column: 1fr;
    grid-row: 1fr 1fr;
    grid-row-gap: 10px;
  }

  &__finish {
    margin: var(--spacer) 0;

    h3 {
      text-align: center;
      margin-bottom: var(--spacer);
    }
  }
}
</style>
