<template>
  <div class="c-venue-contract">
    <p
      class="MsoNormal"
      style="text-align: justify; text-justify: inter-ideograph"
    >
      <b style="mso-bidi-font-weight: normal"
        ><u
          ><span
            lang="EN-GB"
            style="font-family: 'Calibri', sans-serif; mso-ansi-language: EN-GB"
            ><span
              ><span style="text-decoration: none">&nbsp;</span></span
            ></span
          ></u
        ></b
      >
    </p>

    <p
      class="MsoNormal"
      style="
        text-align: justify;
        text-justify: inter-ideograph;
        line-height: 115%;
      "
    >
      <span class="CASub-Heading"
        ><b
          ><span
            lang="EN-GB"
            style="
              font-size: 16pt;
              line-height: 115%;
              font-family: 'Calibri', sans-serif;
              mso-ansi-language: EN-GB;
            "
            >PROOF AND PERMISSION OF POSTING<span></span></span></b
      ></span>
    </p>

    <p
      class="CABodyCopy"
      style="
        text-align: justify;
        text-justify: inter-ideograph;
        line-height: 115%;
      "
    >
      <span class="CASub-Heading"
        ><span
          lang="EN-GB"
          style="
            font-size: 12pt;
            line-height: 115%;
            font-family: 'Calibri', sans-serif;
            mso-fareast-font-family: 'MS Mincho';
            mso-fareast-theme-font: minor-fareast;
            mso-bidi-font-weight: bold;
          "
          ><span>&nbsp;</span></span
        ></span
      >
    </p>

    <p
      class="CABodyCopy"
      style="
        text-align: justify;
        text-justify: inter-ideograph;
        line-height: 115%;
      "
    >
      <span class="CASub-Heading"
        ><span
          lang="EN-GB"
          style="
            font-size: 12pt;
            line-height: 115%;
            font-family: 'Calibri', sans-serif;
            mso-fareast-font-family: 'MS Mincho';
            mso-fareast-theme-font: minor-fareast;
            mso-bidi-font-weight: bold;
          "
          >We confirm the agreed terms on which
        </span></span
      ><span
        lang="EN-GB"
        style="
          font-size: 12pt;
          line-height: 115%;
          font-family: 'Calibri', sans-serif;

          mso-bidi-font-weight: bold;
        "
        >{{ venue_name }}</span
      ><b style="mso-bidi-font-weight: normal"
        ><span
          lang="EN-GB"
          style="
            font-size: 12pt;
            line-height: 115%;
            font-family: 'Calibri', sans-serif;
          "
        >
        </span></b
      ><span
        lang="EN-GB"
        style="
          font-size: 12pt;
          line-height: 115%;
          font-family: 'Calibri', sans-serif;

          mso-bidi-font-weight: bold;
        "
      >
        will display 4 Centre for Perinatal Health messages in our patient
        waiting rooms and bathrooms.
        <span style="mso-spacerun: yes">&nbsp;</span><span></span
      ></span>
    </p>

    <p
      class="CABodyCopy"
      style="
        text-align: justify;
        text-justify: inter-ideograph;
        line-height: 115%;
      "
    >
      <span
        lang="EN-GB"
        style="
          font-size: 12pt;
          line-height: 115%;
          font-family: 'Calibri', sans-serif;

          mso-bidi-font-weight: bold;
        "
        ><span>&nbsp;</span></span
      >
    </p>

    <p
      class="CABodyCopy"
      style="
        text-align: justify;
        text-justify: inter-ideograph;
        line-height: 115%;
      "
    >
      <span
        lang="EN-GB"
        style="
          font-size: 12pt;
          line-height: 115%;
          font-family: 'Calibri', sans-serif;

          mso-bidi-font-weight: bold;
        "
        >Kindly acknowledge your acceptance of these terms by signing where
        indicated below. </span
      ><span class="CASub-Heading"
        ><span
          lang="EN-GB"
          style="
            font-size: 12pt;
            line-height: 115%;
            font-family: 'Calibri', sans-serif;
            mso-fareast-font-family: 'MS Mincho';
            mso-fareast-theme-font: minor-fareast;

            mso-bidi-font-weight: bold;
          "
          ><span></span></span
      ></span>
    </p>

    <p
      class="CABodyCopy"
      style="
        text-align: justify;
        text-justify: inter-ideograph;
        line-height: 115%;
      "
    >
      <span class="CASub-Heading"
        ><span
          lang="EN-GB"
          style="
            font-size: 12pt;
            line-height: 115%;
            font-family: 'Calibri', sans-serif;
            mso-fareast-font-family: 'MS Mincho';
            mso-fareast-theme-font: minor-fareast;

            mso-bidi-font-weight: bold;
          "
          ><span>&nbsp;</span></span
        ></span
      >
    </p>

    <table
      class="MsoTableGrid"
      border="1"
      cellspacing="0"
      cellpadding="0"
      style="
        border-collapse: collapse;
        border: none;
        mso-border-alt: solid windowtext 0.5pt;
        mso-yfti-tbllook: 1184;
        mso-padding-alt: 0cm 5.4pt 0cm 5.4pt;
      "
    >
      <tbody>
        <tr style="mso-yfti-irow: 0; mso-yfti-firstrow: yes">
          <td
            width="180"
            valign="top"
            style="
              width: 134.65pt;
              border: solid windowtext 1pt;
              mso-border-alt: solid windowtext 0.5pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
            "
          >
            <p
              class="CABodyCopy"
              style="
                text-align: justify;
                text-justify: inter-ideograph;
                line-height: 115%;
              "
            >
              <span class="CASub-Heading"
                ><b style="mso-bidi-font-weight: normal"
                  ><span
                    lang="EN-GB"
                    style="
                      font-size: 12pt;
                      line-height: 115%;
                      font-family: 'Calibri', sans-serif;
                      mso-fareast-font-family: 'MS Mincho';
                      mso-fareast-theme-font: minor-fareast;
                    "
                    >I</span
                  ></b
                ></span
              ><span class="CASub-Heading"
                ><b style="mso-bidi-font-weight: normal"
                  ><span
                    lang="EN-GB"
                    style="
                      font-size: 12pt;
                      line-height: 115%;
                      font-family: 'Calibri', sans-serif;
                      mso-fareast-font-family: 'MS Mincho';
                      mso-fareast-theme-font: minor-fareast;
                    "
                    >tem</span
                  ></b
                ></span
              ><span class="CASub-Heading"
                ><b style="mso-bidi-font-weight: normal"
                  ><span
                    lang="EN-GB"
                    style="
                      font-size: 12pt;
                      line-height: 115%;
                      font-family: 'Calibri', sans-serif;
                      mso-fareast-font-family: 'MS Mincho';
                      mso-fareast-theme-font: minor-fareast;
                    "
                    ><span></span></span></b
              ></span>
            </p>
          </td>
          <td
            width="510"
            valign="top"
            style="
              width: 382.75pt;
              border: solid windowtext 1pt;
              border-left: none;
              mso-border-left-alt: solid windowtext 0.5pt;
              mso-border-alt: solid windowtext 0.5pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
            "
          >
            <p
              class="CABodyCopy"
              style="
                text-align: justify;
                text-justify: inter-ideograph;
                line-height: 115%;
              "
            >
              <span class="CASub-Heading"
                ><b style="mso-bidi-font-weight: normal"
                  ><span
                    lang="EN-GB"
                    style="
                      font-size: 12pt;
                      line-height: 115%;
                      font-family: 'Calibri', sans-serif;
                      mso-fareast-font-family: 'MS Mincho';
                      mso-fareast-theme-font: minor-fareast;
                    "
                    >R</span
                  ></b
                ></span
              ><span class="CASub-Heading"
                ><b style="mso-bidi-font-weight: normal"
                  ><span
                    lang="EN-GB"
                    style="
                      font-size: 12pt;
                      line-height: 115%;
                      font-family: 'Calibri', sans-serif;
                      mso-fareast-font-family: 'MS Mincho';
                      mso-fareast-theme-font: minor-fareast;
                    "
                    >evision</span
                  ></b
                ></span
              ><span class="CASub-Heading"
                ><b style="mso-bidi-font-weight: normal"
                  ><span
                    lang="EN-GB"
                    style="
                      font-size: 12pt;
                      line-height: 115%;
                      font-family: 'Calibri', sans-serif;
                      mso-fareast-font-family: 'MS Mincho';
                      mso-fareast-theme-font: minor-fareast;
                    "
                    ><span></span></span></b
              ></span>
            </p>
          </td>
        </tr>
        <tr style="mso-yfti-irow: 1">
          <td
            width="180"
            valign="top"
            style="
              width: 134.65pt;
              border: solid windowtext 1pt;
              border-top: none;
              mso-border-top-alt: solid windowtext 0.5pt;
              mso-border-alt: solid windowtext 0.5pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
            "
          >
            <p class="CABodyCopy" style="line-height: 115%">
              <span class="CASub-Heading"
                ><span
                  lang="EN-GB"
                  style="
                    font-size: 12pt;
                    line-height: 115%;
                    font-family: 'Calibri', sans-serif;
                    mso-fareast-font-family: 'MS Mincho';
                    mso-fareast-theme-font: minor-fareast;

                    mso-bidi-font-weight: bold;
                  "
                  >L</span
                ></span
              ><span class="CASub-Heading"
                ><span
                  lang="EN-GB"
                  style="
                    font-size: 12pt;
                    line-height: 115%;
                    font-family: 'Calibri', sans-serif;
                    mso-fareast-font-family: 'MS Mincho';
                    mso-fareast-theme-font: minor-fareast;
                  "
                  >icensor – also rereferred to as “The Venue”</span
                ></span
              ><span class="CASub-Heading"
                ><span
                  lang="EN-GB"
                  style="
                    font-size: 12pt;
                    line-height: 115%;
                    font-family: 'Calibri', sans-serif;
                    mso-fareast-font-family: 'MS Mincho';
                    mso-fareast-theme-font: minor-fareast;

                    mso-bidi-font-weight: bold;
                  "
                  ><span></span></span
              ></span>
            </p>
          </td>
          <td
            width="510"
            valign="top"
            style="
              width: 382.75pt;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
              mso-border-top-alt: solid windowtext 0.5pt;
              mso-border-left-alt: solid windowtext 0.5pt;
              mso-border-alt: solid windowtext 0.5pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
            "
          >
            <p
              class="CABodyCopy"
              style="
                text-align: justify;
                text-justify: inter-ideograph;
                line-height: 115%;
              "
            >
              <span
                lang="EN-GB"
                style="
                  font-size: 12pt;
                  line-height: 115%;
                  font-family: 'Calibri', sans-serif;

                  mso-bidi-font-weight: bold;
                "
                >{{ venue_name }}</span
              ><span class="CASub-Heading"
                ><span
                  lang="EN-GB"
                  style="
                    font-size: 12pt;
                    line-height: 115%;
                    font-family: 'Calibri', sans-serif;
                    mso-fareast-font-family: 'MS Mincho';
                    mso-fareast-theme-font: minor-fareast;

                    mso-bidi-font-weight: bold;
                  "
                  ><span></span></span
              ></span>
            </p>
          </td>
        </tr>
        <tr style="mso-yfti-irow: 2">
          <td
            width="180"
            valign="top"
            style="
              width: 134.65pt;
              border: solid windowtext 1pt;
              border-top: none;
              mso-border-top-alt: solid windowtext 0.5pt;
              mso-border-alt: solid windowtext 0.5pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
            "
          >
            <p
              class="CABodyCopy"
              style="
                text-align: justify;
                text-justify: inter-ideograph;
                line-height: 115%;
              "
            >
              <span class="CASub-Heading"
                ><span
                  lang="EN-GB"
                  style="
                    font-size: 12pt;
                    line-height: 115%;
                    font-family: 'Calibri', sans-serif;
                    mso-fareast-font-family: 'MS Mincho';
                    mso-fareast-theme-font: minor-fareast;

                    mso-bidi-font-weight: bold;
                  "
                  >L</span
                ></span
              ><span class="CASub-Heading"
                ><span
                  lang="EN-GB"
                  style="
                    font-size: 12pt;
                    line-height: 115%;
                    font-family: 'Calibri', sans-serif;
                    mso-fareast-font-family: 'MS Mincho';
                    mso-fareast-theme-font: minor-fareast;
                  "
                  >icensor ABN</span
                ></span
              ><span class="CASub-Heading"
                ><span
                  lang="EN-GB"
                  style="
                    font-size: 12pt;
                    line-height: 115%;
                    font-family: 'Calibri', sans-serif;
                    mso-fareast-font-family: 'MS Mincho';
                    mso-fareast-theme-font: minor-fareast;

                    mso-bidi-font-weight: bold;
                  "
                  ><span></span></span
              ></span>
            </p>
          </td>
          <td
            width="510"
            valign="top"
            style="
              width: 382.75pt;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
              mso-border-top-alt: solid windowtext 0.5pt;
              mso-border-left-alt: solid windowtext 0.5pt;
              mso-border-alt: solid windowtext 0.5pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
            "
          >
            <p
              class="CABodyCopy"
              style="
                text-align: justify;
                text-justify: inter-ideograph;
                line-height: 115%;
              "
            >
              <span
                lang="EN-GB"
                style="
                  font-size: 12pt;
                  line-height: 115%;
                  font-family: 'Calibri', sans-serif;

                  mso-bidi-font-weight: bold;
                "
                ><span>&nbsp;</span></span
              >
            </p>
          </td>
        </tr>
        <tr style="mso-yfti-irow: 3">
          <td
            width="180"
            valign="top"
            style="
              width: 134.65pt;
              border: solid windowtext 1pt;
              border-top: none;
              mso-border-top-alt: solid windowtext 0.5pt;
              mso-border-alt: solid windowtext 0.5pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
            "
          >
            <p
              class="CABodyCopy"
              style="
                text-align: justify;
                text-justify: inter-ideograph;
                line-height: 115%;
              "
            >
              <span class="CASub-Heading"
                ><span
                  lang="EN-GB"
                  style="
                    font-size: 12pt;
                    line-height: 115%;
                    font-family: 'Calibri', sans-serif;
                    mso-fareast-font-family: 'MS Mincho';
                    mso-fareast-theme-font: minor-fareast;

                    mso-bidi-font-weight: bold;
                  "
                  >I</span
                ></span
              ><span class="CASub-Heading"
                ><span
                  lang="EN-GB"
                  style="
                    font-size: 12pt;
                    line-height: 115%;
                    font-family: 'Calibri', sans-serif;
                    mso-fareast-font-family: 'MS Mincho';
                    mso-fareast-theme-font: minor-fareast;
                  "
                  >nstaller</span
                ></span
              ><span class="CASub-Heading"
                ><span
                  lang="EN-GB"
                  style="
                    font-size: 12pt;
                    line-height: 115%;
                    font-family: 'Calibri', sans-serif;
                    mso-fareast-font-family: 'MS Mincho';
                    mso-fareast-theme-font: minor-fareast;

                    mso-bidi-font-weight: bold;
                  "
                  ><span></span></span
              ></span>
            </p>
          </td>
          <td
            width="510"
            valign="top"
            style="
              width: 382.75pt;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
              mso-border-top-alt: solid windowtext 0.5pt;
              mso-border-left-alt: solid windowtext 0.5pt;
              mso-border-alt: solid windowtext 0.5pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
            "
          >
            <p
              class="CABodyCopy"
              style="
                text-align: justify;
                text-justify: inter-ideograph;
                line-height: 115%;
              "
            >
              <span class="CASub-Heading"
                ><span
                  lang="EN-GB"
                  style="
                    font-size: 12pt;
                    line-height: 115%;
                    font-family: 'Calibri', sans-serif;
                    mso-fareast-font-family: 'MS Mincho';
                    mso-fareast-theme-font: minor-fareast;

                    mso-bidi-font-weight: bold;
                  "
                  >C</span
                ></span
              ><span class="CASub-Heading"
                ><span
                  lang="EN-GB"
                  style="
                    font-size: 12pt;
                    line-height: 115%;
                    font-family: 'Calibri', sans-serif;
                    mso-fareast-font-family: 'MS Mincho';
                    mso-fareast-theme-font: minor-fareast;
                  "
                  >onvenience Advertising (Aust.) Pty Ltd
                </span></span
              ><span class="CASub-Heading"
                ><span
                  lang="EN-GB"
                  style="
                    font-size: 12pt;
                    line-height: 115%;
                    font-family: 'Calibri', sans-serif;
                    mso-fareast-font-family: 'MS Mincho';
                    mso-fareast-theme-font: minor-fareast;

                    mso-bidi-font-weight: bold;
                  "
                  ><span></span></span
              ></span>
            </p>
          </td>
        </tr>
        <tr style="mso-yfti-irow: 4">
          <td
            width="180"
            valign="top"
            style="
              width: 134.65pt;
              border: solid windowtext 1pt;
              border-top: none;
              mso-border-top-alt: solid windowtext 0.5pt;
              mso-border-alt: solid windowtext 0.5pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
            "
          >
            <p
              class="CABodyCopy"
              style="
                text-align: justify;
                text-justify: inter-ideograph;
                line-height: 115%;
              "
            >
              <span class="CASub-Heading"
                ><span
                  lang="EN-GB"
                  style="
                    font-size: 12pt;
                    line-height: 115%;
                    font-family: 'Calibri', sans-serif;
                    mso-fareast-font-family: 'MS Mincho';
                    mso-fareast-theme-font: minor-fareast;

                    mso-bidi-font-weight: bold;
                  "
                  >Licensee ACN<span></span></span
              ></span>
            </p>
          </td>
          <td
            width="510"
            valign="top"
            style="
              width: 382.75pt;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
              mso-border-top-alt: solid windowtext 0.5pt;
              mso-border-left-alt: solid windowtext 0.5pt;
              mso-border-alt: solid windowtext 0.5pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
            "
          >
            <p
              class="CABodyCopy"
              style="
                text-align: justify;
                text-justify: inter-ideograph;
                line-height: 115%;
              "
            >
              <span class="CASub-Heading"
                ><span
                  lang="EN-GB"
                  style="
                    font-size: 12pt;
                    line-height: 115%;
                    font-family: 'Calibri', sans-serif;
                    mso-fareast-font-family: 'MS Mincho';
                    mso-fareast-theme-font: minor-fareast;

                    mso-bidi-font-weight: bold;
                  "
                  >006 271 218<span></span></span
              ></span>
            </p>
          </td>
        </tr>
        <tr style="mso-yfti-irow: 5">
          <td
            width="180"
            valign="top"
            style="
              width: 134.65pt;
              border: solid windowtext 1pt;
              border-top: none;
              mso-border-top-alt: solid windowtext 0.5pt;
              mso-border-alt: solid windowtext 0.5pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
            "
          >
            <p
              class="CABodyCopy"
              style="
                text-align: justify;
                text-justify: inter-ideograph;
                line-height: 115%;
              "
            >
              <span class="CASub-Heading"
                ><span
                  lang="EN-GB"
                  style="
                    font-size: 12pt;
                    line-height: 115%;
                    font-family: 'Calibri', sans-serif;
                    mso-fareast-font-family: 'MS Mincho';
                    mso-fareast-theme-font: minor-fareast;

                    mso-bidi-font-weight: bold;
                  "
                  >Commencement Date <span></span></span
              ></span>
            </p>
          </td>
          <td
            width="510"
            valign="top"
            style="
              width: 382.75pt;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
              mso-border-top-alt: solid windowtext 0.5pt;
              mso-border-left-alt: solid windowtext 0.5pt;
              mso-border-alt: solid windowtext 0.5pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
            "
          >
            <p
              class="CABodyCopy"
              style="
                text-align: justify;
                text-justify: inter-ideograph;
                line-height: 115%;
              "
            >
              <span
                lang="EN-GB"
                style="
                  font-size: 12pt;
                  line-height: 115%;
                  font-family: 'Calibri', sans-serif;

                  mso-bidi-font-weight: bold;
                "
                >{{ date }}</span
              ><span class="CASub-Heading"
                ><span
                  lang="EN-GB"
                  style="
                    font-size: 12pt;
                    line-height: 115%;
                    font-family: 'Calibri', sans-serif;
                    mso-fareast-font-family: 'MS Mincho';
                    mso-fareast-theme-font: minor-fareast;

                    mso-bidi-font-weight: bold;
                  "
                  ><span></span></span
              ></span>
            </p>
          </td>
        </tr>
        <tr style="mso-yfti-irow: 6">
          <td
            width="180"
            valign="top"
            style="
              width: 134.65pt;
              border: solid windowtext 1pt;
              border-top: none;
              mso-border-top-alt: solid windowtext 0.5pt;
              mso-border-alt: solid windowtext 0.5pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
            "
          >
            <p
              class="CABodyCopy"
              style="
                text-align: justify;
                text-justify: inter-ideograph;
                line-height: 115%;
              "
            >
              <span
                lang="EN-GB"
                style="
                  font-size: 12pt;
                  line-height: 115%;
                  font-family: 'Calibri', sans-serif;
                  mso-fareast-font-family: 'MS Mincho';
                  mso-fareast-theme-font: minor-fareast;
                "
                >Term <span></span
              ></span>
            </p>
          </td>
          <td
            width="510"
            valign="top"
            style="
              width: 382.75pt;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
              mso-border-top-alt: solid windowtext 0.5pt;
              mso-border-left-alt: solid windowtext 0.5pt;
              mso-border-alt: solid windowtext 0.5pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
            "
          >
            <p
              class="CABodyCopy"
              style="
                text-align: justify;
                text-justify: inter-ideograph;
                line-height: 115%;
              "
            >
              <span
                lang="EN-GB"
                style="
                  font-size: 12pt;
                  line-height: 115%;
                  font-family: 'Calibri', sans-serif;
                  mso-fareast-font-family: 'MS Mincho';
                  mso-fareast-theme-font: minor-fareast;
                "
                >12 </span
              ><span
                lang="EN-GB"
                style="
                  font-size: 12pt;
                  line-height: 115%;
                  font-family: 'Calibri', sans-serif;
                  mso-fareast-font-family: 'MS Mincho';
                  mso-fareast-theme-font: minor-fareast;

                  mso-bidi-font-weight: bold;
                "
                >months. <span></span
              ></span>
            </p>
            <p
              class="CABodyCopy"
              style="
                text-align: justify;
                text-justify: inter-ideograph;
                line-height: 115%;
              "
            >
              <span
                lang="EN-GB"
                style="
                  font-size: 10pt;
                  line-height: 115%;
                  font-family: 'Calibri', sans-serif;
                  mso-fareast-font-family: 'MS Mincho';
                  mso-fareast-theme-font: minor-fareast;
                  mso-bidi-font-weight: bold;
                "
                >Upon expiry of this Term, this agreement will</span
              ><span
                lang="EN-GB"
                style="
                  font-size: 10pt;
                  line-height: 115%;
                  font-family: 'Calibri', sans-serif;
                  mso-fareast-font-family: 'MS Mincho';
                  mso-fareast-theme-font: minor-fareast;
                "
              >
                automatically extend for a further term of 12 months on the same
                terms and conditions of this current agreement unless a party,
                at least 30 days prior before the agreement is due to end, give
                the other party notice to end the relationship. </span
              ><span
                lang="EN-GB"
                style="
                  font-size: 12pt;
                  line-height: 115%;
                  font-family: 'Calibri', sans-serif;
                  mso-fareast-font-family: 'MS Mincho';
                  mso-fareast-theme-font: minor-fareast;
                "
                ><span></span
              ></span>
            </p>
          </td>
        </tr>
        <tr style="mso-yfti-irow: 7">
          <td
            width="180"
            valign="top"
            style="
              width: 134.65pt;
              border: solid windowtext 1pt;
              border-top: none;
              mso-border-top-alt: solid windowtext 0.5pt;
              mso-border-alt: solid windowtext 0.5pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
            "
          >
            <p
              class="MsoNormal"
              style="
                text-align: justify;
                text-justify: inter-ideograph;
                line-height: 115%;
              "
            >
              <span
                lang="EN-GB"
                style="
                  font-family: 'Calibri', sans-serif;
                  mso-ansi-language: EN-GB;
                  mso-bidi-font-weight: bold;
                "
                >Educational Posters <span></span
              ></span>
            </p>
            <p
              class="CABodyCopy"
              style="
                text-align: justify;
                text-justify: inter-ideograph;
                line-height: 115%;
              "
            >
              <span class="CASub-Heading"
                ><span
                  lang="EN-GB"
                  style="
                    font-size: 12pt;
                    line-height: 115%;
                    font-family: 'Calibri', sans-serif;
                    mso-fareast-font-family: 'MS Mincho';
                    mso-fareast-theme-font: minor-fareast;

                    mso-bidi-font-weight: bold;
                  "
                  ><span>&nbsp;</span></span
                ></span
              >
            </p>
          </td>
          <td
            width="510"
            valign="top"
            style="
              width: 382.75pt;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
              mso-border-top-alt: solid windowtext 0.5pt;
              mso-border-left-alt: solid windowtext 0.5pt;
              mso-border-alt: solid windowtext 0.5pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
            "
          >
            <p
              class="MsoNormal"
              style="
                text-align: justify;
                text-justify: inter-ideograph;
                line-height: 115%;
              "
            >
              <span
                lang="EN-GB"
                style="
                  font-family: 'Calibri', sans-serif;
                  mso-ansi-language: EN-GB;
                  mso-bidi-font-weight: bold;
                "
                >Free educational posters installed into the waiting
                <span class="GramE"
                  >areas,
                  <span style="mso-spacerun: yes">&nbsp;</span>consulting</span
                >
                rooms or bathrooms of the Client. Placement information can be
                found in the Placement Information section of this document.
                <span></span
              ></span>
            </p>
            <p
              class="CABodyCopy"
              style="
                text-align: justify;
                text-justify: inter-ideograph;
                line-height: 115%;
              "
            >
              <span class="CASub-Heading"
                ><span
                  lang="EN-GB"
                  style="
                    font-size: 12pt;
                    line-height: 115%;
                    font-family: 'Calibri', sans-serif;
                    mso-fareast-font-family: 'MS Mincho';
                    mso-fareast-theme-font: minor-fareast;

                    mso-bidi-font-weight: bold;
                  "
                  ><span>&nbsp;</span></span
                ></span
              >
            </p>
            <p
              class="CABodyCopy"
              style="
                margin-left: 36pt;
                text-align: justify;
                text-justify: inter-ideograph;
                text-indent: -36pt;
                line-height: 115%;
              "
            >
              <span class="CASub-Heading"
                ><span
                  lang="EN-GB"
                  style="
                    font-size: 12pt;
                    line-height: 115%;
                    font-family: 'Calibri', sans-serif;
                    mso-fareast-font-family: 'MS Mincho';
                    mso-fareast-theme-font: minor-fareast;

                    mso-bidi-font-weight: bold;
                  "
                  >Creative 1<i>
                    - The free app… pregnant lady<span></span></i></span
              ></span>
            </p>
            <p
              class="CABodyCopy"
              style="
                margin-left: 36pt;
                text-align: justify;
                text-justify: inter-ideograph;
                text-indent: -36pt;
                line-height: 115%;
              "
            >
              <span class="CASub-Heading"
                ><span
                  lang="EN-GB"
                  style="
                    font-size: 12pt;
                    line-height: 115%;
                    font-family: 'Calibri', sans-serif;
                    mso-fareast-font-family: 'MS Mincho';
                    mso-fareast-theme-font: minor-fareast;

                    mso-bidi-font-weight: bold;
                  "
                  >Creative 2<i>
                    – The free <span class="GramE">app..</span> woman with
                    phone<span></span></i></span
              ></span>
            </p>
          </td>
        </tr>
        <tr style="mso-yfti-irow: 8">
          <td
            width="180"
            valign="top"
            style="
              width: 134.65pt;
              border: solid windowtext 1pt;
              border-top: none;
              mso-border-top-alt: solid windowtext 0.5pt;
              mso-border-alt: solid windowtext 0.5pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
            "
          >
            <p
              class="CABodyCopy"
              style="
                text-align: justify;
                text-justify: inter-ideograph;
                line-height: 115%;
              "
            >
              <span class="CASub-Heading"
                ><span
                  lang="EN-GB"
                  style="
                    font-size: 12pt;
                    line-height: 115%;
                    font-family: 'Calibri', sans-serif;
                    mso-fareast-font-family: 'MS Mincho';
                    mso-fareast-theme-font: minor-fareast;

                    mso-bidi-font-weight: bold;
                  "
                  >I</span
                ></span
              ><span class="CASub-Heading"
                ><span
                  lang="EN-GB"
                  style="
                    font-size: 12pt;
                    line-height: 115%;
                    font-family: 'Calibri', sans-serif;
                    mso-fareast-font-family: 'MS Mincho';
                    mso-fareast-theme-font: minor-fareast;
                  "
                  >nsurance</span
                ></span
              ><span class="CASub-Heading"
                ><span
                  lang="EN-GB"
                  style="
                    font-size: 12pt;
                    line-height: 115%;
                    font-family: 'Calibri', sans-serif;
                    mso-fareast-font-family: 'MS Mincho';
                    mso-fareast-theme-font: minor-fareast;

                    mso-bidi-font-weight: bold;
                  "
                  ><span></span></span
              ></span>
            </p>
          </td>
          <td
            width="510"
            valign="top"
            style="
              width: 382.75pt;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
              mso-border-top-alt: solid windowtext 0.5pt;
              mso-border-left-alt: solid windowtext 0.5pt;
              mso-border-alt: solid windowtext 0.5pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
            "
          >
            <p
              class="CABodyCopy"
              style="
                text-align: justify;
                text-justify: inter-ideograph;
                line-height: 115%;
              "
            >
              <span class="CASub-Heading"
                ><span
                  lang="EN-GB"
                  style="
                    font-size: 12pt;
                    line-height: 115%;
                    font-family: 'Calibri', sans-serif;
                    mso-fareast-font-family: 'MS Mincho';
                    mso-fareast-theme-font: minor-fareast;

                    mso-bidi-font-weight: bold;
                  "
                  >Convenience Advertising has public liability insurance of
                  $20m. <span></span></span
              ></span>
            </p>
          </td>
        </tr>
        <tr style="mso-yfti-irow: 9; mso-yfti-lastrow: yes">
          <td
            width="180"
            valign="top"
            style="
              width: 134.65pt;
              border: solid windowtext 1pt;
              border-top: none;
              mso-border-top-alt: solid windowtext 0.5pt;
              mso-border-alt: solid windowtext 0.5pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
            "
          >
            <p
              class="CABodyCopy"
              style="
                text-align: justify;
                text-justify: inter-ideograph;
                line-height: 115%;
              "
            >
              <span class="CASub-Heading"
                ><span
                  lang="EN-GB"
                  style="
                    font-size: 12pt;
                    line-height: 115%;
                    font-family: 'Calibri', sans-serif;
                    mso-fareast-font-family: 'MS Mincho';
                    mso-fareast-theme-font: minor-fareast;

                    mso-bidi-font-weight: bold;
                  "
                  >Confidentiality<span
                    style="background: yellow; mso-highlight: yellow"
                    ><span></span></span></span
              ></span>
            </p>
          </td>
          <td
            width="510"
            valign="top"
            style="
              width: 382.75pt;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
              mso-border-top-alt: solid windowtext 0.5pt;
              mso-border-left-alt: solid windowtext 0.5pt;
              mso-border-alt: solid windowtext 0.5pt;
              padding: 0cm 5.4pt 0cm 5.4pt;
            "
          >
            <p
              class="CABodyCopy"
              style="
                text-align: justify;
                text-justify: inter-ideograph;
                line-height: 115%;
              "
            >
              <span class="CASub-Heading"
                ><span
                  lang="EN-GB"
                  style="
                    font-size: 12pt;
                    line-height: 115%;
                    font-family: 'Calibri', sans-serif;
                    mso-fareast-font-family: 'MS Mincho';
                    mso-fareast-theme-font: minor-fareast;

                    mso-bidi-font-weight: bold;
                  "
                  >W</span
                ></span
              ><span class="CASub-Heading"
                ><span
                  lang="EN-GB"
                  style="
                    font-size: 12pt;
                    line-height: 115%;
                    font-family: 'Calibri', sans-serif;
                    mso-fareast-font-family: 'MS Mincho';
                    mso-fareast-theme-font: minor-fareast;
                  "
                  >e agree to display this material for Mothers and expecting
                  Mothers, to support them on their perinatal life stage and
                  journey. <span style="mso-spacerun: yes">&nbsp;</span
                  ><span style="mso-bidi-font-weight: bold"
                    ><span></span></span></span
              ></span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>

    <p
      class="MsoNormal"
      style="
        text-align: justify;
        text-justify: inter-ideograph;
        line-height: 115%;
      "
    >
      <span
        lang="EN-GB"
        style="
          font-family: 'Calibri', sans-serif;
          mso-ansi-language: EN-GB;
          mso-bidi-font-weight: bold;
        "
        ><span>&nbsp;</span></span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        text-align: justify;
        text-justify: inter-ideograph;
        line-height: 115%;
      "
    >
      <b style="mso-bidi-font-weight: normal"
        ><span
          lang="EN-GB"
          style="font-family: 'Calibri', sans-serif; mso-ansi-language: EN-GB"
          >Acceptance of terms -
        </span></b
      ><span
        lang="EN-GB"
        style="
          font-family: 'Calibri', sans-serif;

          mso-ansi-language: EN-GB;
          mso-bidi-font-weight: bold;
        "
        >{{ venue_name }}</span
      ><b style="mso-bidi-font-weight: normal"
        ><span
          lang="EN-GB"
          style="font-family: 'Calibri', sans-serif; mso-ansi-language: EN-GB"
          ><span></span></span
      ></b>
    </p>

    <p
      class="MsoNormal"
      style="
        text-align: justify;
        text-justify: inter-ideograph;
        line-height: 115%;
      "
    >
      <span
        lang="EN-GB"
        style="
          font-family: 'Calibri', sans-serif;
          mso-ansi-language: EN-GB;
          mso-bidi-font-weight: bold;
        "
        ><span>&nbsp;</span></span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        text-align: justify;
        text-justify: inter-ideograph;
        line-height: 115%;
      "
    >
      <span
        lang="EN-GB"
        style="
          font-family: 'Calibri', sans-serif;
          mso-ansi-language: EN-GB;
          mso-bidi-font-weight: bold;
        "
        >I have read and fully understood the terms set out in this proof and
        permission of posting agreement and submit acceptance on behalf of
        <span>{{ venue_name }}</span
        >. <span style="mso-spacerun: yes">&nbsp;</span>I am duly authorised to
        submit acceptance. <span></span
      ></span>
    </p>

    <p
      class="MsoNormal"
      style="
        text-align: justify;
        text-justify: inter-ideograph;
        line-height: 115%;
      "
    >
      <span
        lang="EN-GB"
        style="
          font-family: 'Calibri', sans-serif;
          mso-ansi-language: EN-GB;
          mso-bidi-font-weight: bold;
        "
        ><span>&nbsp;</span></span
      >
    </p>

    <p
      class="MsoNormal"
      style="
        text-align: justify;
        text-justify: inter-ideograph;
        line-height: 115%;
      "
    >
      <FormContract
        :venue_id="venue.venue_id"
        @onSubmit="onSubmit"
      ></FormContract>
      <span
        lang="EN-GB"
        style="
          font-family: 'Calibri', sans-serif;

          mso-ansi-language: EN-GB;
          mso-bidi-font-weight: bold;
        "
      >
      </span
      ><b style="mso-bidi-font-weight: normal"
        ><i
          ><span
            lang="EN-GB"
            style="font-family: 'Calibri', sans-serif; mso-ansi-language: EN-GB"
            ><span></span></span></i
      ></b>
    </p>

    <p
      class="MsoNormal"
      style="
        text-align: justify;
        text-justify: inter-ideograph;
        line-height: 115%;
      "
    >
      <span
        lang="EN-GB"
        style="
          font-family: 'Calibri', sans-serif;
          mso-ansi-language: EN-GB;
          mso-bidi-font-weight: bold;
        "
        ><br style="mso-special-character: line-break" />
        <!--[if !supportLineBreakNewLine]--><br
          style="mso-special-character: line-break" />
        <!--[endif]--><span class="CASub-Heading"
          ><span
            style="
              mso-ansi-font-size: 12pt;
              line-height: 115%;
              font-family: 'Calibri', sans-serif;
            "
            ><span></span></span></span
      ></span>
    </p>
  </div>
</template>

<script>
import FormContract from "@swipekit/components/form/form-cope-contract";

export default {
  components: {
    FormContract,
  },

  props: {
    venue: {},
  },

  computed: {
    venue_name: function () {
      return this.venue.venue_name;
    },
    venue_id: function () {
      return this.venue.venue_id;
    },
    date: function () {
      const today = new Date();
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();

      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;

      const formattedToday = dd + "/" + mm + "/" + yyyy;

      return formattedToday;
    },
  },

  methods: {
    onSubmit: function (e) {
      this.$emit("onSubmit", e);
    },
  },
};
</script>

<style lang="scss">
.c-venue-contract {
  background-color: var(--color-bg-3);
  padding: var(--spacer-sm);

  table {
    width: 100%;
  }
}
</style>
