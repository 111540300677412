function daysToVenue(days) {
  let obj = {};

  for (const p in days) {
    let day = days[p];

    for (const q in day) {
      let key = `${p}_${q}`;
      obj[key] = day[q];
    }
  }

  return obj;
}

export default daysToVenue;
