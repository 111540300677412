<template>
  <div class="c-venues">
    <div class="c-simple-table">
      <!-- add filtered search -->
      <div class="c-simple-table__row">
        <span>&nbsp;</span>
        <span>Search Venues:&nbsp;&nbsp;<input type="text" style="max-width: 200px;width:30%;" v-model="search"></span>
      </div>
      <div
        class="c-simple-table__row"
        v-for="venue in computedVenues"
        :key="venue.venue_id"
      >
        <span @click="onOpenVenue(venue.venue_id)"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <line x1="7" y1="17" x2="17" y2="7"></line>
            <polyline points="7 7 17 7 17 17"></polyline></svg
        ></span>
        <span> {{ venue.venue_name }} | {{ venue.company_name }} </span>
        <span>
          <a href="#" class="btn"> Open Google maps </a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    venues: function () {
      return this.$store.getters["venues/all"];
    },
    computedVenues: function () {
      return this.venues.filter(venue => {
        return venue.venue_name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          || venue.company_name.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      })
      return this.venues;
    },
  },
  data: function () {
    return {
      search: '',
    };
  },
  methods: {
    onOpenVenue: function (id) {
      this.$router.push(`/venue/${id}`);
    },
  },
};
</script>

<style lang="scss">
.c-venues {
  .c-simple-table {
    &__row {
      display: grid;
      grid-template-columns: 32px 1fr 180px;
      gap: 0px 0px;
    }

    svg {
      color: var(--color-primary);
    }
  }
}
</style>
