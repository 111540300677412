<template>
  <form class="c-form-venue" @submit.prevent="onSubmit">
    <Form
      ref="form"
      :schema="computedSchema"
      v-model="formData"
      @status="saveStatus"
      buttonText="Save COPE Feedback"
      :processing="processing"
    ></Form>
  </form>
</template>

<script>
import schema from "./cope-schema";
import Form from "./index.vue";
import venueToDay from "@swipekit/lib/venue-to-day";
import dayToVenue from "@swipekit/lib/day-to-venue";

export default {
  components: {
    Form,
  },

  data: function () {
    return {
      processing: false,
      status: null,
      formData: {},
    };
  },

  watch: {
    form: {
      handler: function () {
        let f = JSON.parse(JSON.stringify(this.form));

        let data = venueToDay(f);

        this.formData = data;
      },
      immediate: true,
      deep: true,
    },
  },

  props: {
    form: {},
  },

  computed: {
    computedSchema: function () {
      let s = schema;

      let venue = this.$store.getters["venues/one"](this.form.venue_id);

      if (venue && venue.additional_data && venue.additional_data.cope_data) {
        this.form.visitors = venue.additional_data.cope_data.visitors || 0;
        this.form.non_english = venue.additional_data.cope_data.non_english;
        this.form.languages = venue.additional_data.cope_data.languages || "";
        this.form.clarity = venue.additional_data.cope_data.clarity;
        this.form.reasonance = venue.additional_data.cope_data.reasonance;
        this.form.changes = venue.additional_data.cope_data.changes;
      }

      return s;
    },
  },

  methods: {
    reset: function () {},
    saveStatus: function (saveStatus) {
      this.status = saveStatus;
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      this.processing = true;

      let form = this.formData;

      let cope_feedback = {
        visitors: form.visitors,
        non_english: form.non_english,
        languages: form.languages,
        clarity: form.clarity,
        reasonance: form.reasonance,
        changes: form.changes,
        notes: form.notes,
      };

      let additional_data = {
        cope_feedback: cope_feedback,
      };

      console.log("Form data : ", form);
      console.log("Feedback data : ", cope_feedback);

      let obj = dayToVenue(form.days);

      form = {
        ...obj,
        ...form,
      };

      form = {
        venue_id: form.venue_id,
        business_number: form.business_number,
        additional_data: additional_data,
        days: form.days,
      };

      if (!form.days) {
        delete form.days;
      }

      let res = await this.$store
        .dispatch("venues/update", form)
        .catch((err) => {
          if (err.response.data) {
            this.errors = err.response.data;
          }
          this.processing = false;

          this.$store.dispatch("message/onFail", this.errors);
        });

      if (!res) {
        return;
      }

      this.$store.dispatch("message/onVenueUpdated");

      this.reset();

      this.$emit("onSubmit");

      this.processing = false;
    },
  },

  mounted: function () {},
};
</script>

<style lang="scss"></style>
