<template>
  <div class="form-group c-input-number">
    <label v-if="label" class="form__label" :for="slug">{{ label }}</label>
    <InputDescription
      v-if="description"
      :message="description"
    ></InputDescription>
    <div class="c-input-number__inner">
      <input
        :class="[{ 'form-control': noStyle === false }]"
        :id="slug"
        type="number"
        :value="currentValue"
        @change="onChange"
        :placeholder="placeholder"
        :min="min"
        :max="max"
        inputmode="numeric"
      />
      <span
        v-if="!hideButtons"
        :class="['btn', { 'btn-primary': !isMin }]"
        tabindex="0"
        @click="onDecrement"
        >-</span
      >
      <span
        v-if="!hideButtons"
        :class="['btn', { 'btn-primary': !isMax }]"
        tabindex="0"
        @click="onIncrement"
        >+</span
      >
    </div>
    <InputError v-if="hasError" :message="errorMessage"></InputError>
  </div>
</template>

<script>
import InputError from "./input-error";
import InputDescription from "./input-description";
import slugify from "@swipekit/lib/slugify.js";

export default {
  components: {
    InputError,
    InputDescription,
  },

  data: function () {
    return {
      currentValue: 0,
    };
  },

  watch: {
    value: {
      handler: function () {
        if (this.value) {
          let temp = parseInt(this.value);
          if (!isNaN(temp)) {
            this.currentValue = temp;
          }
        }
      },
      immediate: true,
    },
  },

  props: {
    label: {
      type: String,
      default: "",
    },
    validation: {},
    value: {},
    placeholder: {
      type: String,
      default: "",
    },
    noStyle: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: "",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {},
    min: {
      type: Number,
      default: -10,
    },
    max: {
      type: Number,
      default: 10,
    },
    step: {
      type: Number,
      default: 1,
    },
    hideButtons: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isMax: function () {
      if (this.currentValue >= this.max) {
        return true;
      }
      return false;
    },
    isMin: function () {
      if (this.currentValue <= this.min) {
        return true;
      }
      return false;
    },
    slug: function () {
      return slugify(this.label);
    },
  },

  methods: {
    onChange: function (e) {
      let value = e.target.value;
      this.$emit("input", value);
    },
    onIncrement: function () {
      if (this.isMax) {
        return;
      }
      this.currentValue += this.step;

      this.$emit("input", this.currentValue);
    },
    onDecrement: function () {
      if (this.isMin) {
        return;
      }
      this.currentValue -= this.step;
      this.$emit("input", this.currentValue);
    },
  },
};
</script>

<style lang="scss">
.c-input-number {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: var(--margin-lg);

  &__inner {
    position: relative;
    min-width: 200px;

    .btn {
      position: absolute;
      top: 4px;
      right: 40px;
      width: 30px;
      height: calc(100% - 18px);
      justify-content: center;
      align-items: center;
      font-weight: 600;
      border-radius: var(--border-radius);
      padding: 8px 16px;
      &:last-of-type {
        right: 4px;
      }
    }
  }
}
</style>
