import api from "@swipekit/lib/api.js";

import CrudStore from "@swipekit/lib/crud-store";

let crud = new CrudStore({
  name: "venues",
  singular: "venue",
});

const state = {
  ...crud.state,
  isSingleton: false,
};
const getters = {
  ...crud.getters,

  /**
   * Returns only a single resource
   */
  one: function (state) {
    let resources = state.resources;
    return (id) => {
      let resource = null;
      id = parseInt(id);

      // if (isNaN(id)) {
      //   return null;
      // }

      for (let i = 0; i < resources.length; i++) {
        if (resources[i].venue_id === id) {
          resource = resources[i];
          break;
        }
      }

      return resource;
    };
  },
};
const actions = {
  ...crud.actions,

  async getLocal(store) {
    let resources = [];
    try {
      resources = await crud.api[crud.resourceName].get("local");
    } catch (err) {
      throw err;
    }

    store.commit("SET", resources);
    return resources;
  },

  async doAction(store, obj) {
    let action = obj.action;
    let id = obj.id;
    let response = await crud.api.api.doAction(id, action);

    return response;
  },

  async fetchAndStore(store) {
    let resources = (await crud.api[crud.resourceName].fetchAndStore()) || [];

    store.commit(resources);
  },

  consumePie: async (store, pie) => {
    if (pie.workspace) {
      let workspace = {
        ...pie.workspace,
      };

      delete workspace.boards;
      delete workspace.ads;
      store.commit("SET", workspace);
    }
  },

  /**
   * Gets all resources from the server
   */
  async get(store, resources = []) {
    if (resources.length === 0) {
      try {
        resources = await api.venues.get();
      } catch (err) {
        throw err;
      }
    }
    store.commit("SET", resources);
    return resources;
  },

  async getContact(store, venue_id) {
    let resources = [];
    if (venue_id > 0) {
      try {
        resources = await api.contacts.getByVenue(venue_id);
      } catch (err) {
        throw err;
      }
    }
    store.commit("SET", resources);
    return resources;
  },

  async getBathrooms(store, venue_id) {
    let resources = [];
    if (venue_id > 0) {
      try {
        resources = await api.bathrooms.getByVenue(venue_id);
      } catch (err) {
        throw err;
      }
    }
    store.commit("SET", resources);
    return resources;
  },

  async createContact(store, newContact) {
    let response = await api.contacts.add(newContact).catch((err) => {
      console.log("Error thrown by create contact :", err);
      throw err;
    });
    console.log("Returned contact data :", response);
    let venue = store.getters["one"](response.reference_id);
    console.log("Venue :", venue);
    venue = JSON.parse(JSON.stringify(venue));
    venue.contacts = [response];

    store.commit("UPDATE", venue);

    return response;
  },

  async updateContact(store, contact) {
    let response = await api.contacts.update(contact).catch((err) => {
      console.log(err);
      throw err;
    });

    return response;
  },

  async createSite(store, newSite) {
    let response = await api.sites.add(newSite).catch((err) => {
      throw err;
    });

    let venue = store.getters["one"](response.venue_id);

    venue = JSON.parse(JSON.stringify(venue));

    venue.venue_sites.push(response);

    store.commit("UPDATE", venue);

    return response;
  },

  async createBathroom(store, newBathroom) {
    let response = await api.bathrooms.add(newBathroom).catch((err) => {
      throw err;
    });

    let venue = store.getters["one"](response.venue_id);

    venue = JSON.parse(JSON.stringify(venue));

    venue.bathrooms.push(response);

    store.commit("UPDATE", venue);

    return response;
  },

  async updateBathroom(store, bathroom) {
    let response = await api.bathrooms.update(bathroom).catch((err) => {
      console.log(err);
      throw err;
    });

    let venue = store.getters["one"](response.venue_id);

    venue = JSON.parse(JSON.stringify(venue));

    venue.bathrooms = venue.bathrooms.map((bathroom) => bathroom.bathroom_id === response.bathroom_id ? response : bathroom);

    store.commit("UPDATE", venue);

    return response;
  },

  async createContract(store, newContract) {
    let response = await api.contracts.add(newContract).catch((err) => {
      throw err;
    });

    let venue = store.getters["one"](newContract.reference_id);

    venue = JSON.parse(JSON.stringify(venue));

    venue.contract = response;

    store.commit("UPDATE", venue);

    return response;
  },

  async updateSite(store, site) {
    let response = await api.sites.update(site).catch((err) => {
      throw err;
      console.log(err);
    });

    return response;
  },

  async completeVenue(store, venue) {
    let response = await api.venues.update(venue).catch((err) => {
      console.log(err);
      throw err;
    });

    return response;
  },
};
const mutations = {
  ...crud.mutations,

  UPDATE: function (state, resource) {
    if (state.isSingleton) {
      state.resource = resource;
      return;
    }
    let id = resource.venue_id;

    // Updates object in array and this method will also trigger vuex to re-render - which is exactly what we want
    state.resources = [...state.resources.filter((resource) => resource.venue_id !== id), resource];
  },
};

let store = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default store;
