<template>
  <div class="form-group c-input-camera">
    <label
      v-if="label"
      class="form__label"
      :for="slug"
    >
      {{ label }}
    </label>
    <div class="c-input-camera__inner">
      <div class="c-input-camera__wrapper c-input-camera__wrapper--image">
        <div class="c-input-camera__image">
          <img
            v-if="fileName"
            :src="fileName"
          />
          <span></span>
        </div>
      </div>
      <div class="c-input-camera__wrapper">
        <video
          ref="video"
          autoplay="true"
          playsinline
        ></video>
      </div>
      <canvas ref="canvas"></canvas>
    </div>
    <div class="c-input-camera__buttons">
      <button
        type="button"
        class="btn btn-box btn-primary"
        @click="onStartCamera"
      >
        {{ this.cameraOn ? "Take Photo" : "Start Camera" }}
      </button>
    </div>
    <div class="c-input-camera__buttons">
      <CameraPermissionsHelp />
    </div>
    <InputError
      v-if="hasError"
      :message="errorMessage"
    ></InputError>
    <InputDescription
      v-if="description"
      :message="description"
    ></InputDescription>
  </div>
</template>

<script>
import InputError from "./input-error";
import InputDescription from "./input-description";
import slugify from "@swipekit/lib/slugify.js";

import CameraPermissionsHelp from "@swipekit/components/app/camera-permissions-help";

export default {
  components: {
    CameraPermissionsHelp,
    InputError,
    InputDescription,
  },

  data: function () {
    return {
      stream: null,
      cameraOn: false,
      fileName: "",
      extensions: ["pdf", "docx", "xlsx", "txt", "zip", "jpeg", "png", "mp4", "mp4", ""],
    };
  },

  props: {
    label: {
      type: String,
      default: "",
    },
    validation: {},
    value: {},
    placeholder: {
      type: String,
      default: "",
    },
    noStyle: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: "",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {},
    form: {},
  },

  watch: {
    fileName: function () {
      if (!this.fileName.includes("http")) {
        this.$emit("input", this.fileName);
      }
    },
  },

  computed: {
    assetUrl: function () {
      return this.$store.getters["config/assetUrl"];
    },
    slug: function () {
      return slugify(this.label || `input_${Math.floor(Math.random() * 100)}`);
    },
  },

  methods: {
    stopCamera: function () {
      this.$refs.video.srcObject = undefined;

      if (!this.stream) return;

      this.stream.getTracks().forEach(function (track) {
        track.stop();
      });
      this.cameraOn = false;
    },
    onChange: function (e) {
      let file = e.target.files[0];

      if (!file) return;

      let reader = new FileReader();

      reader.onload = (ee) => {
        this.fileName = ee.target.result;
      };

      reader.readAsDataURL(file);

      this.$emit("input", file);
    },
    onStartCamera: function () {
      if (!this.cameraOn) {
        this.startCamera();
      } else {
        this.takePhoto();
      }
    },
    videoDimensions: function (video, ratio) {
      var actualRatio = video.videoWidth / video.videoHeight; // Ratio of the video's intrisic dimensions
      var offsetRatio = video.offsetWidth / video.offsetHeight; // The ratio of the element's width to its height

      const dimensions = {
        width: video.offsetWidth * ratio,
        height: video.offsetHeight * ratio,
      };

      if (offsetRatio > actualRatio) {
        // If the video element is short and wide
        dimensions.width = dimensions.height * actualRatio;
      } else {
        // It must be tall and thin, or exactly equal to the original ratio
        dimensions.height = dimensions.width / actualRatio;
      }

      return dimensions;
    },
    takePhoto: function () {
      const dims = this.videoDimensions(this.$refs.video, window.devicePixelRatio);

      this.$refs.canvas.width = dims.width;
      this.$refs.canvas.height = dims.height;
      this.$refs.canvas.getContext("2d").drawImage(this.$refs.video, 0, 0, this.$refs.canvas.width, this.$refs.canvas.height);

      this.fileName = this.$refs.canvas.toDataURL("image/jpeg");

      this.$nextTick(() => this.stopCamera());
    },
    async startCamera() {
      try {
        this.stream = await navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: "environment",
          },
          audio: false,
        });
        this.$refs.video.srcObject = this.stream;
        this.cameraOn = true;
      } catch (err) {
        console.error("Error accessing device camera", err);
        switch (err?.name) {
          case "NotAllowedError":
            this.$store.dispatch("message/onCameraPermissionDeniedError");
            break;
          case "NotFoundError":
            this.$store.dispatch("message/onCameraNotFoundError");
            break;
          default:
            this.$store.dispatch("message/onCameraAccessError");
            break;
        }
      }
    },
  },

  mounted: function () {
    if (this.value) {
      if (typeof this.value === "object") {
        this.fileName = this.value.name;
      } else {
        this.fileName = this.value;
      }
    }
    if (this.form.media) {
      console.log(this.assetUrl);
      this.fileName = `${this.assetUrl}/media/${this.form.media.media_id}.${this.extensions[this.form.media.content_type]}`;
    }
  },

  beforeDestroy: function () {
    this.stopCamera();
  },
};
</script>

<style lang="scss">
.clearfix {
  clear: both;
}
.c-input-camera {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: var(--margin-lg);

  &__image {
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    margin-right: var(--margin-lg);
    background-color: var(--color-bg-5);

    overflow: hidden;

    img {
      width: 100% !important;
      height: 100% !important;
      max-width: initial !important;
      object-fit: contain;
      object-position: center center;
    }
  }
  video {
    width: 100%;
    height: 100%;
    background-color: var(--color-bg-5);
    border-radius: var(--border-radius);
    margin-right: var(--margin-lg);
  }

  &__inner, &__buttons {
    width: auto;
    display: grid;
    grid-template-columns: 350px 350px;
    grid-column-gap: 10px;
    margin-bottom: 10px;

    & > * {
      min-width: 140px;
      max-width: 350px;
      width: 100%;
    }
  }

  &__wrapper {
    position: relative;

    &:after {
      display: inline-block;
      content: "Preview";
      position: absolute;
      top: var(--margin);
      left: calc(50% - 40px);
      width: 80px;
      padding: var(--margin-sm) var(--margin);
      text-transform: uppercase;
      text-align: center;
      font-size: var(--font-size-xs);
      font-weight: 500;
      background-color: var(--color-bg-1);
      border-radius: var(--border-radius-full);
    }

    &--image {
      &:after {
        content: "Photo";
        left: calc(50% - 30px);
        width: 60px;
      }
    }
  }

  canvas {
    display: none;
  }

  @media screen and (max-width: 720px) {
    &__inner {
      width: 100%;
      grid-template-columns: 1fr 1fr;
    }

    &__buttons {
      width: 100%;
      grid-template-columns: 1fr 1fr;
    }

    &__image {
      width: 100%;
    }

    video {
      width: 100%;
    }
  }
}
</style>
