<template>
  <div class="c-modal-404">
    <Modal v-model="options.active" @input="closeModal" :closable="true">
      <h2>This Venue is not available</h2>
      <p>
        Either this Venue doesn't exist, or you're viewing this page from the
        wrong app version.
      </p>
      <p>
        If this was not intended, screenshot this page and send it to the Caams developer.
      </p>
      <p>ENV: {{ env }}</p>

      <p>
        Current url: {{url}}
      </p>
    </Modal>
  </div>
</template>

<script>
import Modal from "./index.vue";

export default {
  components: {
    Modal,
  },

  data: function () {
    return {
      options: {
        name: "modal-tracker-create",
        active: false,
      },
      env: null,
    };
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    active: function () {
      this.options.active = this.active;
    },
    "options.active": {
      handler: function () {},
    },
    addId: function () {
      if (this.addId) {
        this.options.active = true;
      } else {
        this.options.active = false;
      }
    },
  },

  computed: {
    url : function() {
      return window.location.href;
    },
    addId: function () {
      return this.$store.getters["boards/addId"];
    },
  },

  methods: {
    onSubmit: function () {
      this.$store.dispatch("triggerExtension", { type: "boards:update" });
      this.$store.dispatch("message/onBoardCreate");
      this.options.active = false;
    },
    activate: function () {
      this.options.active = true;
    },
    closeModal: function () {
      this.$emit("close");
      //this.$store.dispatch("boards/setAdd", null);
    },
  },

  mounted: function () {
    this.env = process.env.VUE_APP_ENV || "";
    if (this.active) {
      this.options.active = true;
    }
  },
};
</script>

<style lang="scss">
.c-modal-404 {
  .modal__content {
    padding: var(--spacer);
  }
}
</style>
