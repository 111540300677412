<template>
  <div class="form-group c-input-password">
    <label v-if="label" class="form__label" :for="slug">{{ label }}</label>
    <input
      class="form-control"
      :id="slug"
      type="password"
      :value="value"
      @change="onChange"
      :placeholder="placeholder"
    />
    <InputError v-if="hasError" :message="errorMessage"></InputError>
  </div>
</template>

<script>
import InputError from "./input-error";
import slugify from "@swipekit/lib/slugify.js";

export default {
  components: {
    InputError,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    validation: {},
    value: {},
    placeholder: {
      type: String,
      default: "",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {},
  },
  computed: {
    slug: function () {
      return slugify(this.label);
    },
  },
  methods: {
    onChange: function (e) {
      let value = e.target.value;
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss">
.c-input-password {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: var(--margin-lg);
}
</style>
