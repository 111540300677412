<template>
  <div class="c-map-view">
    <Map apiKey="AIzaSyDwk1iGkIIbSAZzgsoz_LcJAbdFO8Waxy4"></Map>
  </div>
</template>

<script>
import Map from "@swipekit/components/map";

export default {
  name: "Home",
  components: {
    Map,
  },

  data: function () {
    return {};
  },

  computed: {},

  methods: {},

  mounted: function () {},
};
</script>

<style lang="scss">
.c-map-view {
}
</style>
