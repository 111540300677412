<template>
  <div class="c-venue-card">
    <h3>
      {{ venue.venue_name }}
    </h3>
    <article class="article-text-sm">
      <p>
        Located at {{ venue.address }}, {{ venue.suburb }}, {{ venue.postcode }}
      </p>
      <template v-if="primaryContact">
        <h4>Contact</h4>
        <span>
          {{ primaryContact.first_name }} {{ primaryContact.last_name }}
        </span>
      </template>
    </article>
  </div>
</template>

<script>
export default {
  props: {
    venue: {},
    contacts: {},
  },

  computed: {
    primaryContact: function () {
      let contacts = this.contacts;

      if (!contacts) {
        return;
      }

      if (contacts.length === 0) {
        return;
      }

      // for now, just get the first contact assigned to this venue
      let contact = contacts[0];

      if (!contact) {
        return;
      }

      return contact;
    },
  },
};
</script>

<style lang="scss">
.c-venue-card {
  display: inline-block;
  width: 100%;
  margin-top: var(--spacer-sm);
  border-radius: var(--border-radius);
  border: var(--color-bg-4) solid 1px;
  background-color: var(--color-bg-2);
  padding: var(--margin-lg);

  h3 {
    font-size: var(--font-size-lg);
  }
}
</style>
