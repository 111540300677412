<template>
  <form class="c-form-contact-update" @submit.prevent="onSubmit">
    <Form
      ref="form"
      :schema="schema"
      v-model="currentForm"
      @status="saveStatus"
      buttonText="Create Contact"
      :processing="processing"
      :errors="errors"
    ></Form>
  </form>
</template>

<script>
import ageEncoder from "@swipekit/lib/age-encoder";

const schema = {
  first_name: {
    component: "InputText",
    label: "*First name",
    properties: {
      columnSize: 6,
    },
    validations: {
      required: {
        params: null,
        message: "First name is required",
      },
      maxLength: {
        params: 64,
        message: `That's a insanely long name. Maximum 64 characters`,
      },
    },
  },

  last_name: {
    component: "InputText",
    label: "*Last name",
    properties: {
      columnSize: 6,
    },
    validations: {
      required: {
        params: null,
        message: "Last name is required",
      },
      maxLength: {
        params: 64,
        message: `That's a insanely long area name. Maximum 64 characters`,
      },
    },
  },

  salutation: {
    component: "InputSelect",
    label: "*Salutation",
    options: [
      {
        key: "Mr",
        value: "Mr",
      },
      {
        key: "Mrs",
        value: "Mrs",
      },
      {
        key: "Miss",
        value: "Miss",
      },
      {
        key: "Ms",
        value: "Ms",
      },
      {
        key: "Dr",
        value: "Dr",
      },
      {
        key: "Master",
        value: "Mstr",
      },
      {
        key: "Other",
        value: "Other",
      },
    ],
    validations: {
      required: {
        params: null,
        message: "Salutationw  is required",
      },
    },
  },

  title: {
    component: "InputText",
    label: "*Title",
    description: "Their occupation, eg: Receptionist, Bartender, etc.",
    validations: {
      required: {
        params: null,
        message: "Title is required",
      },
      maxLength: {
        params: 64,
        message: `That's a insanely long tile. Maximum 64 characters`,
      },
    },
  },

  phone: {
    component: "InputText",
    label: "*Phone",
    description: "No spaces",
    validations: {
      required: {
        params: null,
        message: "A phone is required",
      },
    },
  },

  mobile: {
    component: "InputText",
    label: "Mobile",
    description: "No spaces",
    // validations: {
    //   required: {
    //     params: null,
    //     message: "A mobile is required",
    //   },
    // },
  },

  email: {
    component: "InputEmail",
    label: "*Email",
    validations: {
      required: {
        params: null,
        message: "A email is required",
      },
    },
  },
};

export default {
  components: {
    Form: () => {
      return import("./index.vue");
    },
  },

  data: function () {
    return {
      errors: [],
      processing: false,
      currentForm: {},
      status: null,
    };
  },

  props: {
    form: {},
    venue_id: {},
    isCope: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    form: {
      immediate: true,
      handler: function () {
        if (this.form) {
          let temp = JSON.parse(JSON.stringify(this.form));

          temp.age_group = ageEncoder.decode(temp.age_group);

          temp.hours = [];

          if (temp.additional_data && temp.additional_data.hours) {
            temp.hours = temp.additional_data.hours || [];

            delete temp.additional_data;
          }

          this.currentForm = temp;
        }
      },
    },
  },

  computed: {
    schema: function () {
      let s = schema;

      if (this.isCope) {
        s.title.description = `Their occupation, eg: Receptionist, Practice Manager, etc.`;
      }

      return s;
    },
  },

  methods: {
    reset: function () {},
    saveStatus: function (saveStatus) {
      this.status = saveStatus;
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      this.processing = true;

      let form = this.currentForm;

      delete form.hours;

      delete form.position;

      let newContact = {
        ...form,
        contact_type: 0,
        reference_id: this.venue_id,
        reference_type: 0,
      };

      let response = await this.$store
        .dispatch("venues/createContact", newContact)
        .catch((err) => {
          if (err.response.data) {
            this.errors = err.response.data;
          }

          this.$store.dispatch("message/onFail", this.errors);
        });

      this.processing = false;

      if (!response) {
        return;
      }

      this.$store.dispatch("message/onContactUpdated");

      this.$emit("onSubmit", form);
    },
  },

  created: function () {
    if (this.form) {
      this.currentForm = this.form;
    }
  },
};
</script>

<style></style>
