let store = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async init(store) {
      const env =
        process.env.VUE_APP_NODE_ENV || process.env.VUE_APP_ENV || "development";
    },

    onClientError(store, err) {
      console.log("CLIENT ERROR");
      //console.log(err);
    },

    onServerError(store, err) {
      console.log("SERVER ERROR");
      //console.log(err);
    },
  },
};

export default store;
