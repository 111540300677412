const caams_media_content_type = [
  {
    label: 0,
    value: "pdf",
  },
  {
    label: 1,
    value: "word",
  },
  {
    label: 2,
    value: "excel",
  },
  {
    label: 3,
    value: "text",
  },
  {
    label: 4,
    value: "zip",
  },
  {
    label: 5,
    value: "jpg",
  },
  {
    label: 5,
    value: "jpeg",
  },
  {
    label: 6,
    value: "png",
  },
  {
    label: 7,
    value: "general_image",
  },
  {
    label: 8,
    value: "mp4",
  },
  {
    label: 9,
    value: "general_video",
  },
  {
    label: 10,
    value: "other",
  },
  {
    label: 11,
    value: "webm",
  },
];

const caams_media_media_type = [
  {
    label: 0,
    value: "CAMPAIGN_VIDEO",
  },
  {
    label: 1,
    value: "CAMPAIGN_IMAGE",
  },
  {
    label: 2,
    value: "PROOF_OF_POSTING",
  },
  {
    label: 3,
    value: "USER_QUALIFICATION",
  },
  {
    label: 4,
    value: "UNASSIGNED",
  },
  {
    label: 5,
    value: "CLAIM_RECEIPT",
  },
  {
    label: 6,
    value: "MAINTAINENCE_IMAGE",
  },
];

const mo_users_status = [
  {
    label: 1,
    value: "ONBOARD_START",
  },
  {
    label: 2,
    value: "ONBOARD_CORE",
  },
  {
    label: 3,
    value: "ONBOARD_TACS",
  },
  {
    label: 4,
    value: "ONBOARD_COMPLETE",
  },
  {
    label: 5,
    value: "PENDING",
  },
  {
    label: 6,
    value: "APPROVED",
  },
  {
    label: 7,
    value: "REJECTED",
  },
];

const maintenance_runs_status = [
  {
    label: 0,
    value: "PENDING",
  },
  {
    label: 1,
    value: "IN_PROGRESS",
  },
  {
    label: 2,
    value: "COMPLETED",
  },
  {
    label: 3,
    value: "INCOMPLETE",
  },
];

exports = module.exports = {
  caams_media_content_type,
  caams_media_media_type,
  mo_users_status,
  maintenance_runs_status,
};
