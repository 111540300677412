<template>
  <div class="form-group c-input-area">
    <label v-if="label" class="form__label" :for="slug">{{ label }}</label>
    {{ text }}
    <select
      v-if="options"
      :class="[{ 'form-control': noStyle === false }]"
      :id="slug"
      v-model="text"
      :placeholder="placeholder"
    >
      <option
        v-for="option in computedOptions"
        :key="option.value"
        :value="option.value"
        :selected="option.value === text"
      >
        {{ option.key || option.value }}
      </option>
    </select>
    <InputText
      v-model="other"
      placeholder="Enter Area name"
      v-if="text === 'other'"
    ></InputText>
    <InputError v-if="hasError" :message="errorMessage"></InputError>
    <InputDescription
      v-if="description"
      :message="description"
    ></InputDescription>
  </div>
</template>

<script>
import InputError from "./input-error";
import InputDescription from "./input-description";
import slugify from "@swipekit/lib/slugify.js";
import InputText from "@swipekit/components/form/input-text";

export default {
  components: {
    InputError,
    InputDescription,
    InputText,
  },

  data: function () {
    return {
      other: "",
      text: "",
    };
  },

  watch: {},

  props: {
    label: {
      type: String,
      default: "",
    },
    validation: {},
    value: {},
    placeholder: {
      type: String,
      default: "",
    },
    noStyle: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: "",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {},
    options: {},
    parentId: {},
  },

  watch: {
    other: function () {
      if (this.text === "other") {
        this.$emit("input", this.other);
      } else {
        // blank out input
        this.other = "";
      }
    },
    text: function () {
      this.$emit("input", this.text);
    },
    value: function () {
      let currentValue = this.text;

      // don't do anything other than set text's value
      if (typeof this.value === "string") {
        this.text = "other";
        if (this.other !== this.value && this.value !== "other") {
          this.other = this.value;
        }
        return;
      }

      let value = this.value;

      if (value !== currentValue) {
        this.text = value;
      }
    },
    options: {
      handler: function () {
        console.log(this.options);
      },
      deep: true,
    },
  },

  computed: {
    slug: function () {
      return slugify(this.label || `input_${Math.floor(Math.random() * 100)}`);
    },
    computedOptions: function () {
      let options = this.options;

      let parentId = this.parentId;

      if (!parentId) {
        return options;
      }

      let category = this.$store.getters["listTypes/category"](parentId);

      if (!category) {
        // hack, show blank but submit parentid as the value
        this.$emit("input", parentId);
        return [];
      }

      let items = this.$store.getters["lists/subCategory"](category.type_id);

      items = JSON.parse(JSON.stringify(items));

      items = items.map((i) => {
        return {
          key: i.label,
          value: i.item_id,
        };
      });

      items.push({
        key: "Other",
        value: "other",
      });

      return items;
    },
  },

  mounted: function () {
    if (this.value) {
      this.text = this.value;
    }

    if (typeof this.value === "number" && this.value === 0) {
      this.text = this.value;
    }
  },
};
</script>

<style lang="scss">
.c-input-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: var(--margin-lg);

  .c-input-text {
    width: 100%;
  }
}
</style>
