import api from "@swipekit/lib/api";

let store = {
  namespaced: true,
  state: {
    init: false,

    options: {
      onboarded: false,
      gridSize: 2,
    },

    showOnboarding: false,

    showLeave: false,

    showSettings: false,

    extensionHelperId: null,

    shareId: false,
  },
  getters: {
    init: function (state) {
      return state.init;
    },
    windowActive: function (state) {
      return state.windowActive;
    },
    showSettings: function (state) {
      return state.showSettings;
    },
    showOnboarding: function (state) {
      return state.showOnboarding;
    },
    showLeave: function (state) {
      return state.showLeave;
    },
    options: function (state) {
      return state.options;
    },
    extensionHelperId: function (state) {
      return state.extensionHelperId;
    },
    shareId: function (state) {
      return state.shareId;
    },
  },
  mutations: {
    SET_OPTIONS: function (state, options) {
      state.options = options;
    },
    SET_OPTION: function (state, data) {
      let key = data.key;
      let value = data.value;

      state.options[key] = value;
    },
    SET_WINDOW_ACTIVE: function (state, condition) {
      state.windowActive = condition;
    },
    SET_SHOW_SETTINGS: (state, condition) => {
      state.showSettings = condition;
    },
    SET_SHOW_ONBOARDING: (state, condition) => {
      state.showOnboarding = condition;
    },
    SET_SHOW_LEAVE: (state, condition) => {
      state.showLeave = condition;
    },
    SET_INIT: function (state) {
      state.init = true;
    },
    SET_GRIDSIZE: (state, gridSize) => {
      state.options.gridSize = gridSize;
    },
    SET_SHARE: (state, id) => {
      state.shareId = id;
    },
  },
  actions: {
    async init(store) {
      let options = await api.app.get();

      let currentOptions = {
        ...store.state.options,
        ...options,
      };
      store.commit("SET_OPTIONS", currentOptions);
    },

    hideWindow: function (store) {
      store.commit("SET_WINDOW_ACTIVE", false);
    },

    toggleSettings: function (store, condition) {
      store.commit("SET_SHOW_SETTINGS", condition);
    },
    toggleOnboarding: function (store, condition) {
      store.commit("SET_SHOW_ONBOARDING", condition);
    },
    toggleLeave: function (store, condition) {
      store.commit("SET_SHOW_LEAVE", condition);
    },

    setInit: function (store) {
      store.commit("SET_INIT");
    },

    setGridSize: function (store, gridSize) {
      store.commit("SET_GRIDSIZE", gridSize);
    },

    showExtensionHelper: function (store, condition = false) {
      store.state.extensionHelperId = condition;
    },

    async setShare(store, id) {
      store.commit("SET_SHARE", id);
    },

    async sendContactForm(store, form) {
      return await api.api.doAction(form.id, form.action, form.payload);
    },
  },
  modules: {},
};

export default store;
