<template>
  <form
    class="c-form-venue-photo"
    @submit.prevent="onSubmit"
  >
    <div class="c-form-venue-photo__img">
      <img src="/images/outside-venue.jpg" />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <polyline points="20 6 9 17 4 12"></polyline>
      </svg>
    </div>
    <p>Example photo</p>
    <Form
      ref="form"
      :schema="computedSchema"
      v-model="formData"
      @status="saveStatus"
      buttonText="Save Photo and Move to Next"
      :processing="processing"
    >
      <br />
      <article
        v-if="isCope"
        class="article-text"
      >
        <p>
          Go in to the venue. Speak to the reception staff and outline that you’re from Convenience Advertising, and you’re here to install the free educational poster for the Centre of Perinatal
          Excellence (COPE).
        </p>
        <p>Follow the installation instructions on where to put the posters.</p>
      </article>
      <article
        v-else
        class="article-text"
      >
        <p>Go in to the venue and ask for the Venue Manager. Advise the Venue Manager that you’re from Convenience Advertising and you’ll be conducting an audit of the bathrooms and signage.</p>
        <p>
          Ask the Venue Manager how many bathrooms there are in the venue, and where they are located, so you know how many to audit. Please flag to the Venue Manager that on completion of the audit,
          you’ll require other information like contact, demographic, agreement and payment details.
        </p>
      </article>
    </Form>
  </form>
</template>

<script>
import Form from "./index.vue";
import venueToDay from "@swipekit/lib/venue-to-day";
import dayToVenue from "@swipekit/lib/day-to-venue";

const schema = {
  overview_image: {
    component: "InputCamera",
    label: "Venue photo",
  },
};

export default {
  components: {
    Form,
  },

  data: function () {
    return {
      processing: false,
      status: null,
      formData: {},
    };
  },

  watch: {
    form: {
      handler: function () {
        let f = JSON.parse(JSON.stringify(this.form));

        let data = venueToDay(f);

        this.formData = data;
      },
      immediate: true,
      deep: true,
    },
  },

  props: {
    form: {},
    isCope: {},
  },

  computed: {
    computedSchema: function () {
      let s = schema;

      return s;
    },
  },

  methods: {
    reset: function () {},
    saveStatus: function (saveStatus) {
      this.status = saveStatus;
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      this.processing = true;

      let form = this.formData;

      console.log(form);

      let obj = dayToVenue(form.days);

      form = {
        ...obj,
        ...form,
      };

      form = {
        venue_id: form.venue_id,
        business_number: form.business_number,
        overview_image: form.overview_image,
        main_category_id: form.main_category_id,
        days: form.days,
      };

      if (!form.days) {
        delete form.days;
      }

      if (typeof form.overview_image === "object") {
        delete form.overview_image;
      }

      let res = await this.$store.dispatch("venues/update", form).catch((err) => {
        console.error(err);
        if (err.response.data) {
          this.errors = err.response.data;
        }
        this.processing = false;

        this.$store.dispatch("message/onFail", this.errors);
      });

      if (!res) {
        return;
      }

      this.$store.dispatch("message/onVenueUpdated");

      this.reset();

      this.$emit("onSubmit");

      this.processing = false;
    },
  },

  mounted: function () {},
};
</script>

<style lang="scss">
.c-form-venue-photo {
  position: relative;

  &__img {
    position: relative;

    img {
      display: block;
      width: 100%;
      max-width: 150px;
      height: auto;
      border-radius: var(--border-radius);
    }

    svg {
      position: absolute;
      bottom: var(--margin);
      left: var(--margin);
      border-radius: var(--border-radius-full);
      background-color: var(--color-success);
      color: var(--color-bg-1);
      border: var(--color-success) solid 4px;
    }
  }
}
</style>
