<template>
  <div class="form-group c-input-button">
    <button :class="['btn', type, buttonClass, { disabled: disabled === true || processing === true }]">
      <span class="c-spinner" v-if="processing"></span>
      {{ label }}
    </button>
    <InputError v-if="hasError" :message="errorMessage"></InputError>
  </div>
</template>

<script>
import InputError from "./input-error";
import slugify from "@swipekit/lib/slugify.js";

export default {
  components: {
    InputError,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    validation: {},
    value: {},
    placeholder: {
      type: String,
      default: "",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    processing: {
      type: Boolean,
      default: false,
    },
    buttonClass: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
  },
  computed: {
    slug: function () {
      return slugify(this.label);
    },
    // hasError: function() {
    //   if (
    //     this.validation &&
    //     this.validation.$dirty === true &&
    //     this.validation.$invalid === true
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    errorMessage: function () {
      if (!this.hasError) {
        return "";
      }
      return "Please fill in this input";
    },
  },
  methods: {
    onChange: function (e) {
      if (this.disabled || this.processing) {
        return;
      }
      let value = e.target.value;
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss">
.c-input-button {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: var(--grid);

  button {
    flex-grow: 0;
  }
  .c-spinner {
    margin-right: var(--margin-lg);
  }
}
</style>
