function encode(arr) {
  if (!arr) {
    return "";
  }

  if (arr.length === 0) {
    return "";
  }

  if (typeof arr === "string") {
    arr = [arr];
  }

  let str = arr.join(":");

  return str;
}

function decode(str) {
  if (!str) {
    return [];
  }

  if (str.length === 0) {
    return [];
  }

  let arr = str.split(":");

  return arr;
}

export default {
  encode,
  decode,
};
