<template>
  <div class="camera-permissions-help">
    <button
      type="button"
      class="btn btn-box camera-permissions-help-toggle"
      @click="activate"
    >
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="100"
          height="100"
          viewBox="0,0,256,256"
        >
          <path
            transform="translate(-24,-24) scale(12,12)"
            d="M12,2c-5.511,0 -10,4.489 -10,10c0,5.511 4.489,10 10,10c5.511,0 10,-4.489 10,-10c0,-5.511 -4.489,-10 -10,-10zM12,4c4.43012,0 8,3.56988 8,8c0,4.43012 -3.56988,8 -8,8c-4.43012,0 -8,-3.56988 -8,-8c0,-4.43012 3.56988,-8 8,-8zM11,7v2h2v-2zM11,11v6h2v-6z"
          />
        </svg>
        Having issues?
      </span>
    </button>
    <Modal
      v-model="options.active"
      @input="closeModal"
      :closable="true"
    >
      <h1>Having camera issues?</h1>
      <p>
        If your camera isn't working and the preview screen is black, or there's an icon like
        <img
          class="camera-permissions-icon"
          src="/images/camera-disabled.jpg"
        />
        on the top right corner of your screen,
        <strong>
          please follow these steps to allow camera permissions on your
          <span :hidden="isUnknownDevice">{{ deviceType }}</span>
          device:
        </strong>
      </p>
      <h2 :hidden="!isUnknownDevice">iOS (iPhone/iPad):</h2>
      <ol :hidden="!isIOS && !isUnknownDevice">
        <li>
          Tap on the
          <img
            class="camera-permissions-icon"
            src="/images/webview-settings-ios.jpg"
          />
          icon next to the search/URL bar.
          <img
            class="img-instructional"
            src="/images/webview-settings-icon-location-ios.png"
          />
        </li>
        <li>
          Then select
          <strong>'Website Settings'</strong>
          .
          <img
            class="img-instructional"
            src="/images/webview-settings-menu-ios.png"
          />
        </li>
        <li>
          Ensure your
          <strong>'Camera'</strong>
          permission is set to
          <strong>'Allow'</strong>
          .
          <img
            class="img-instructional"
            src="/images/webview-settings-permissions-menu-ios.png"
          />
        </li>
        <li>
          Select
          <strong>'Done'</strong>
          , then
          <strong>Refresh</strong>
          the page.
        </li>
      </ol>
      <h2 :hidden="!isUnknownDevice">Android:</h2>
      <ol :hidden="!isAndroid && !isUnknownDevice">
        <li>
          Tap on the
          <img
            class="camera-permissions-icon"
            src="/images/webview-settings-android.jpg"
          />
          icon next to the search/URL bar.
          <img
            class="img-instructional"
            src="/images/webview-settings-icon-location-android.png"
          />
        </li>
        <li>
          Then select
          <strong>'Permissions'</strong>
          .
          <img
            class="img-instructional"
            src="/images/webview-settings-menu-android.png"
          />
        </li>
        <li>
          Ensure your
          <strong>'Camera'</strong>
          permission is set to
          <strong>'Allowed'</strong>
          .
          <img
            class="img-instructional"
            src="/images/webview-settings-permissions-menu-android.png"
          />
        </li>
        <li>
          Close the settings, then
          <strong>Refresh</strong>
          the page.
        </li>
      </ol>
    </Modal>
  </div>
</template>

<script>
import Modal from "@swipekit/components/modal/index.vue";

export default {
  components: {
    Modal,
  },

  data: function () {
    return {
      options: {
        name: "camera-permissions-help",
        active: false,
      },
    };
  },

  watch: {},

  computed: {
    userAgent: function () {
      return navigator.userAgent;
    },
    isIOS: function () {
      return /iPhone|iPad|iPod/i.test(this.userAgent);
    },
    isAndroid: function () {
      return /Android/i.test(this.userAgent);
    },
    isUnknownDevice: function () {
      return (!this.isIOS && !this.isAndroid) || (this.isIOS && this.isAndroid);
    },
    deviceType: function () {
      if (this.isUnknownDevice) {
        return "Unknown";
      }
      if (this.isIOS) {
        return "iOS";
      }
      if (this.isAndroid) {
        return "Android";
      }
      return "Unknown";
    },
  },

  methods: {
    activate: function () {
      this.options.active = true;
    },
    closeModal: function () {
      this.options.active = false;
    },
  },

  mounted: function () {},
};
</script>

<style lang="scss">
.camera-permissions-help {
  .camera-permissions-help-toggle {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
    cursor: pointer;
    width: 100%;
    padding-left: 5px !important;
    padding-right: 5px !important;

    span {
      vertical-align: middle;
      font-size: var(--font-size-sm);
      font-weight: bold;
      color: var(--color-danger);
      border-bottom: 1px solid var(--color-danger);

      svg {
        stroke: var(--color-danger);
        fill: var(--color-danger);
        width: 10px;
        height: 10px;
      }
    }
  }

  .modal__content {
    padding: var(--spacer);
    padding-top: var(--spacer-lg);
    align-items: center;
    opacity: 0.85;
    max-width: 500px;
    margin: auto;

    h1 {
      font-size: var(--font-size-xl);
    }

    h2 {
      font-size: var(--font-size-lg);
    }

    li,
    p {
      font-size: var(--font-size);
      line-height: 1.4;
    }

    ol {
      padding: 0 var(--spacer-sm);
      li {
        margin: var(--spacer-sm) 0;
      }
    }

    img.camera-permissions-icon {
      display: inline-block;
      vertical-align: text-top;
      width: 16px;
      height: 16px;
      margin: 0 var(--margin-sm);
    }

    img.img-instructional {
      display: block;
      width: 100%;
      max-width: 300px;
      margin: auto;
      margin-top: var(--margin-lg);
      box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    }
  }
}
</style>
