<template>
  <div class="c-input-error">
    <span>{{ message }}</span>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.c-input-error {
  display: flex;
  padding-bottom: var(--margin-lg);
  margin: 0;
  font-size: var(--font-size-sm);
  color: var(--color-danger);
}
</style>
