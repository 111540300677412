<template>
  <div class="form-group c-input-stars">
    <label v-if="label" class="form__label" :for="slug">{{ label }}</label>
    <div class="c-input-stars__container">
      <div
        :class="['c-input-stars__star', { active: i <= text }]"
        v-for="i in maxStars"
        :key="i"
        @click.prevent="onClick(i)"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <polygon
            points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"
          ></polygon>
        </svg>
      </div>
    </div>
    <span v-if="ratingGiven">Rating given.</span>
    <InputError v-if="hasError" :message="errorMessage"></InputError>
    <InputDescription
      v-if="description"
      :message="description"
    ></InputDescription>
  </div>
</template>

<script>
import InputError from "./input-error";
import InputDescription from "./input-description";
import slugify from "@swipekit/lib/slugify.js";

export default {
  components: {
    InputError,
    InputDescription,
  },

  data: function () {
    return {
      ratingGiven: false,
      maxStars: 5,
      text: 0,
    };
  },

  watch: {},

  props: {
    label: {
      type: String,
      default: "",
    },
    validation: {},
    value: {},
    placeholder: {
      type: String,
      default: "",
    },
    noStyle: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: "",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {},
  },

  watch: {
    text: function () {
      if (this.ratingGiven) {
        return;
      }
      if (!this.ratingGiven) {
        this.ratingGiven = true;
      }
      this.$emit("input", this.text);
    },
    value: function () {
      let currentValue = this.text;

      let value = this.value;

      if (value !== currentValue) {
        this.text = value;
      }
    },
  },

  computed: {
    slug: function () {
      return slugify(this.label || `input_${Math.floor(Math.random() * 100)}`);
    },
  },

  methods: {
    onClick: function (i) {
      if (this.ratingGiven) {
        return;
      }
      this.text = i;
    },
  },

  mounted: function () {
    if (this.value) {
      this.text = this.value;
    }
  },
};
</script>

<style lang="scss">
.c-input-stars {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: var(--margin-lg);

  --main-color: #eee248;

  &__container {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }

  &__star {
    color: var(--color-bg-6);
    cursor: pointer;
    transition: all var(--transition-time) linear;
    user-select: none;

    svg {
      width: 32px;
      height: 32px;

      polygon {
        fill: currentColor;
      }
    }

    &:hover {
      transform: scale(1.25);
    }

    &.active {
      color: var(--main-color);
    }
  }
}
</style>
