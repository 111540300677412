<template>
  <div id="app">
    <div :class="['c-layout', { sidebar: sidebar === true }]">
      <router-view v-if="init" />
    </div>

    <Message></Message>

    <portal-target name="app"></portal-target>
  </div>
</template>

<script>
import Message from "@swipekit/components/message";

export default {
  components: {
    Message,
  },

  computed: {
    appOptions: function () {
      return this.$store.getters["app/options"];
    },
    init: function () {
      return this.$store.getters["app/init"];
    },
    onboarded: function () {
      return this.$store.getters["app/onboarded"];
    },
    isAuth: function () {
      return this.$store.getters["users/isAuth"];
    },
    processing: function () {
      return this.$store.getters["heartbeat/isProcessing"];
    },
    standalone: function () {
      return this.$store.getters["standalone"];
    },
    sidebar: function () {
      return false;
    },
    auth: function () {
      let isAuth = this.$store.getters["users/isAuth"];

      return isAuth;
    },
    extensionId: function () {
      return this.$store.getters["extensionId"];
    },
    query: function () {
      let query = this.$route.query;
      return query;
    },
  },

  watch: {
    query: {
      handler: function () {
        this.parseQuery();
      },
      immediate: true,
    },
    auth: function () {},
    appOptions: {
      deep: true,
      handler: function () {
        return;
        let gridSize = this.appOptions.gridSize;
        if (typeof this.$el !== undefined) {
          let root = document.documentElement;
          let width = 16.6;
          if (gridSize === 1) {
            width = 14.28;
          }
          if (gridSize === 3) {
            width = 20;
          }
          if (gridSize === 4) {
            width = 25;
          }
          if (gridSize === 5) {
            width = 33.3;
          }
          if (this.$el && this.$el.style) {
            this.$el.style.setProperty("--item-width", width + "%");
          }
        }
      },
      immediate: true,
    },
  },

  methods: {
    parseQuery: function () {
      let query = this.query;

      if (query.leave) {
        this.$store.dispatch("app/toggleLeave", true);
      }
    },

    async load() {
      let jwt = null;

      if (this.$route.query.jwt) {
        jwt = this.$route.query.jwt;
      }

      await this.$store.dispatch("init", jwt);
    },
  },

  mounted: function () {
    this.load();
    //crop:active
    window.addEventListener("message", (e) => {
      //console.log(e);
    });
  },
};
</script>

<style lang="scss">
@import "../node_modules/bootstrap/scss/bootstrap-grid.scss";
@import "assets/styles/main.scss";
#app {
  * {
    touch-action: manipulation;
  }

  .c-layout {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    &.sidebar {
      width: calc(100% - var(--sidebar-width));
      left: var(--sidebar-width);
    }
  }
}
</style>
