<template>
  <form class="c-form-site-update" @submit.prevent="onSubmit">
    <h4>Update {{ form.name }}'s Demographics</h4>
    <article class="article-text">
      <p>
        Please ask the Venue licensee/owner to verify the trading hours of this
        part of the Venue, in the table below.
      </p>
    </article>
    <Form
      ref="form"
      :schema="schema"
      v-model="currentForm"
      @status="saveStatus"
      @formInput="onFormInput"
      buttonText="Update Area"
      :processing="processing"
    ></Form>
  </form>
</template>

<script>
import ageEncoder from "@swipekit/lib/age-encoder";

const schema = {
  item_id: {
    component: "InputCategory",
    label: "Category",
    parentId: null,
    // validations: {
    //   required: {
    //     params: null,
    //     message: "Category required",
    //   },
    // },
  },

  hours: {
    component: "InputHours",
    askQuestion: true,
    label: "",
    hideMaint: true,
    showPatronage: true,
    showEstimatedPatrons: false,
    hideButtons: false,
  },

  age_group: {
    component: "InputCheckboxGroup",
    label: "Which age groups frequently visit this venue?",
    options: [
      {
        key: "Under 18",
        value: "UNDER_18",
      },
      {
        key: "18 - 19",
        value: "UNDER_20",
      },
      {
        key: "20 - 24",
        value: "UNDER_25",
      },
      {
        key: "25 - 39",
        value: "UNDER_30",
      },
      {
        key: "30 - 34",
        value: "UNDER_35",
      },
      {
        key: "35 - 39",
        value: "UNDER_40",
      },
      {
        key: "40 - 44",
        value: "UNDER_45",
      },
      {
        key: "45 - 49",
        value: "UNDER_50",
      },
      {
        key: "50 - 54",
        value: "UNDER_55",
      },
      {
        key: "55 - 59",
        value: "UNDER_60",
      },
      {
        key: "60 - 64",
        value: "UNDER_65",
      },
      {
        key: "65 -  69",
        value: "UNDER_70",
      },
      {
        key: "70 and over",
        value: "OVER_70",
      },
    ],
    validations: {
      required: {
        params: null,
        message: "Age group is required",
      },
    },
  },

  gender_skew: {
    component: "InputSelect",
    label: "What is the gender ratio of patrons who visit this venue?",
    options: [
      {
        key: "100:0 (100% male - 0% female)",
        value: 100,
      },
      {
        key: "90:10",
        value: 90,
      },
      {
        key: "80:20",
        value: 80,
      },
      {
        key: "70:30",
        value: 70,
      },
      {
        key: "60:40",
        value: 60,
      },
      {
        key: "50:50",
        value: 50,
      },
      {
        key: "40:60",
        value: 40,
      },
      {
        key: "30:70",
        value: 30,
      },
      {
        key: "20:80",
        value: 20,
      },
      {
        key: "10:90",
        value: 10,
      },
      {
        key: "0:100 (0% male - 100% female)",
        value: 0,
      },
    ],
    validations: {
      required: {
        params: null,
        message: "Gender skew is required",
      },
    },
  },

  SiteBathrooms: {
    component: "InputCheckboxGroup",
    label: "Bathrooms",
    options: [],
  },
};

export default {
  components: {
    Form: () => {
      return import("./index.vue");
    },
  },

  data: function () {
    return {
      processing: false,
      currentForm: {},
      status: null,
      always_open: false,
    };
  },

  props: {
    form: {},
  },

  watch: {
    form: {
      immediate: true,
      handler: function () {
        if (this.form) {
          let temp = JSON.parse(JSON.stringify(this.form));

          temp.age_group = ageEncoder.decode(temp.age_group);

          temp.hours = [];

          if (temp.additional_data && temp.additional_data.hours) {
            temp.hours = temp.additional_data.hours || [];

            temp.always_open = temp.additional_data.always_open || false;

            delete temp.additional_data;
          }

          this.currentForm = temp;
        }
      },
    },
  },

  computed: {
    schema: function () {
      let s = schema;

      let venue = this.$store.getters["venues/one"](this.form.venue_id);

      s.item_id.parentId = venue.main_category_id;

      let bathrooms = venue.bathrooms || [];

      let options = [];

      for (let i = 0; i < bathrooms.length; i++) {
        options.push({
          key: bathrooms[i].name,
          value: bathrooms[i].bathroom_id,
        });
      }

      s.SiteBathrooms.options = options;

      return s;
    },
  },

  methods: {
    onFormInput: function (data) {
      this.always_open = data.value;
    },
    reset: function () {},
    saveStatus: function (saveStatus) {
      this.status = saveStatus;
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      this.processing = true;

      let form = this.currentForm;

      form.age_group = ageEncoder.encode(form.age_group);

      form.additional_data = form.additional_data || {};

      form.additional_data.hours = form.hours || [];

      form.additional_data.always_open = this.always_open || false;

      console.log(this.always_open);
      console.log("---");

      delete form.hours;

      delete form.always_open;

      let newSite = {
        additional_data: form.additional_data,
        age_group: form.age_group,
        gender_skew: form.gender_skew,
        name: form.name,
        site_id: this.form.site_id,
        item_id: form.item_id,
        SiteBathrooms: form.SiteBathrooms,
      };

      let response = await this.$store
        .dispatch("venues/updateSite", newSite)
        .catch((err) => {
          if (err.response.data) {
            this.errors = err.response.data;
          }
          this.processing = false;

          console.log(this.errors);

          this.$store.dispatch("message/onFail", this.errors);

          return;
        });

      if (response) {
        this.$store.dispatch("message/onSiteUpdated");

        this.processing = false;

        this.$emit("onSubmit", response);
      } else {
        this.errors.push({
          message: "server side error",
        });
        this.$store.dispatch("message/onFail", this.errors);
      }
    },
  },

  mounted: function () {
    // resolve many to many
    let venue = this.$store.getters["venues/one"](this.form.venue_id);
    let SiteBathrooms = [];
    for (let i = 0; i < venue.venue_sites.length; i++) {
      let v = venue.venue_sites[i];

      if (v.site_id !== this.form.site_id) {
        continue;
      }

      if (v.SiteBathrooms) {
        SiteBathrooms = v.SiteBathrooms.map((b) => {
          return b.bathroom_id;
        });
      }

      if (v.additional_data && v.additional_data.always_open) {
        this.always_open = true;
      }
    }

    if (SiteBathrooms.length > 0) {
      this.currentForm.SiteBathrooms = SiteBathrooms;
    }
  },
};
</script>

<style></style>
