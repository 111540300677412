<template>
  <form class="c-form-contract-blank" @submit.prevent="onSubmit">
    <Form
      ref="form"
      :schema="schema"
      v-model="form"
      @status="saveStatus"
      buttonText="Update Venue Details"
      :processing="processing"
    ></Form>
  </form>
</template>

<script>
import moment from "moment";
import api from "@swipekit/lib";

const schema = {
  first_name: {
    component: "InputText",
    label: "*First name",
    properties: {
      columnSize: 6,
    },
    validations: {
      required: {
        params: null,
        message: "First name is required",
      },
      maxLength: {
        params: 64,
        message: `That's a insanely long name. Maximum 64 characters`,
      },
    },
  },

  last_name: {
    component: "InputText",
    label: "*Last name",
    properties: {
      columnSize: 6,
    },
    validations: {
      required: {
        params: null,
        message: "Last name is required",
      },
      maxLength: {
        params: 64,
        message: `That's a insanely long area name. Maximum 64 characters`,
      },
    },
  },

  title: {
    component: "InputText",
    label: "*Title",
    description: "Occupation, eg: Receptionist, Bartender, etc.",
    validations: {
      required: {
        params: null,
        message: "Title is required",
      },
      maxLength: {
        params: 64,
        message: `That's a insanely long tile. Maximum 64 characters`,
      },
    },
  },

  signature: {
    component: "InputSignature",
    label: "*Signature",
    description: "Use your finger to sign",
    properties: {
      columnSize: 6,
    },
    validations: {
      required: {
        params: null,
        message: "A signature is required",
      },
    },
  },

  notes: {
    component: "InputEditor",
    label: "Notes",
  },

  rating: {
    component: "InputStars",
    label: "*Ask the Venue Manager to give a star rating out of 5 (Note: this cannot be changed after a rating is selected)",
    validations: {
      required: {
        params: null,
        message: "A rating is required",
      },
    },
  },
};

export default {
  components: {
    Form: () => {
      return import("./index.vue");
    },
  },

  data: function () {
    return {
      processing: false,
      schema: schema,
      form: {},
      status: null,
    };
  },

  props: {
    venue_id: {},
  },

  methods: {
    reset: function () {},
    saveStatus: function (saveStatus) {
      this.status = saveStatus;
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      this.processing = true;

      let form = this.form;

      let start_date = moment().toISOString();

      let expiry_date = moment().add(3, "years").toISOString();

      let user = this.$store.getters["users/user"];

      let user_id = user.user_id;

      let ip_address = "0.0.0.0";

      let params = {
        venue_id: this.venue_id || -1,
      };

      // get the first contact for this venue
      let contact = await api.contacts.getByVenue(params);
      console.log("Returned contact details :", contact);
      let contact_id = -1;
      if (contact !== undefined && contact.length > 0) {
        contact_id = contact[0].contact_id;
      }

      let additional_data = {
        rating: form.rating || 0,
        notes: form.notes || "",
      };

      let venue = this.$store.getters["venues/one"](this.venue_id);

      let newContract = {
        reference_id: this.venue_id,
        reference_type: 0,
        contract_type: 0,
        rent_model: 0,
        payment_model: 2,
        contact_id: contact_id || -1,
        term: 3,
        status: 1,
        start_date: start_date,
        expiry_date: expiry_date,
        user_id: user_id,
        account_name: form.account_name || "n/a",
        account_bsb: form.account_bsb || "n/a",
        account_number: form.account_number || "n/a",
        signature: form.signature.signature,
        signature_svg: form.signature.signature_svg,
        date_signed: start_date,
        ip_address: ip_address,
        additional_data: additional_data,
      };

      console.log(venue);

      let new_additional_data = venue.additional_data || {};

      new_additional_data.contract = newContract;

      let updateVenue = {
        venue_id: this.venue_id,
        additional_data: new_additional_data,
      };

      // create contract as part of venue update additional_data
      let response = await this.$store
        .dispatch("venues/update", updateVenue)
        .catch((err) => {
          if (err.response.data) {
            this.errors = err.response.data;
          }

          this.$store.dispatch("message/onFail", this.errors);

          return;
        });

      this.processing = false;

      if (!response) {
        return;
      }

      this.$store.dispatch("message/onContractCreated");

      this.$emit("onSubmit", form);
    },
  },

  mounted: function () {},
};
</script>

<style></style>
