<template>
  <div class="c-page-header">
    <div class="c-page-header__inner">
      <h1 v-if="name">Conads / {{ name }}</h1>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {};
  },

  watch: {},

  props: {
    title: {},
  },

  computed: {
    name: function () {
      let route = this.$route;

      let name = this.title || route.name || "?";

      return name;
    },
  },

  methods: {},

  mounted: function () {},
};
</script>

<style lang="scss">
.c-page-header {
  width: 100%;
  max-height: 65px;

  border-bottom: var(--color-bg-4) solid 1px;

  &__inner {
    height: 100%;

    margin: 0 auto;
    padding: var(--spacer-sm);

    h1 {
      margin: 0;
      text-align: center;
      line-height: 1;
      font-size: 26px;
    }

    .article-text {
      margin-top: var(--spacer-sm);
      margin-bottom: 0;
    }

    img {
      width: 32px;
      height: 32px;
      border-radius: var(--border-radius-full);
    }
  }

  &__user-blurb {
    display: flex;
    align-items: center;
    margin-bottom: var(--spacer-sm);

    span {
      margin-left: var(--margin-lg);
    }
  }
}
</style>
