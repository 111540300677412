<template>
  <form class="c-form-bathroom-update" @submit.prevent="onSubmit">
    <Form
      ref="form"
      :schema="computedSchema"
      v-model="currentForm"
      :venue_id="venue_id"
      @status="saveStatus"
      :buttonText="buttonText"
      :processing="processing"
    ></Form>
  </form>
</template>

<script>
const schema = {
  name: {
    component: "InputText",
    label: "Bathroom Name",
    description:
      "Add Descriptive name, Eg Sports bar, Gaming, Level 1 Foodcourt, Gate 22",
    validations: {
      required: {
        params: null,
        message: "A bathroom name is required",
      },
      minLength: {
        params: 1,
        message: "Give a descriptive name to your area. Minimum 1 character",
      },
      maxLength: {
        params: 64,
        message: `That's a insanely long area name. Maximum 64 characters`,
      },
    },
  },

  bathroom_category: {
    component: "InputSelect",
    label: "Bathroom Type",
    options: [
      {
        key: "Female",
        value: 0,
      },
      {
        key: "Male",
        value: 1,
      },
      {
        key: "Disabled",
        value: 2,
      },
      {
        key: "Parent Room",
        value: 3,
      },
      {
        key: "Unisex",
        value: 4,
      },
      {
        key: "Non Bathrooms",
        value: 5,
      },
      // {
      //   key: "Other",
      //   value: 6,
      // },
    ],
    validations: {
      required: {
        params: null,
        message: "Please set a bathroom category",
      },
    },
  },

  // site_id: {
  //   component: "InputSelect",
  //   label: "Area",
  //   description: "What area is this bathroom located in?",
  //   options: [],
  //   validations: {
  //     required: {
  //       params: null,
  //       message: "Please set a area",
  //     },
  //   },
  // },

  walkthrough_video: {
    component: "InputWebcam",
    label: "Walkthrough video",
    description:
      "Walk in to the bathroom and take 2 steps from the entry. Turn to face the door / entry location. Video from right to left as you look at the door. The video should be 10 to 15 seconds in length and capture the whole bathroom.",
  },

  hand_dryers: {
    component: "InputNumber",
    label: "Hand Dryers",
    description: "How many hand-dryers are there?",
    min: 0,
    max: 30,
  },

  cubicles: {
    component: "InputNumber",
    label: "Cubicles",
    description: "How many cubicles are there?",
    min: 0,
    max: 30,
  },

  urinals: {
    component: "InputNumber",
    label: "Urinals",
    description: "How many urinals are there?",
    min: 0,
    max: 30,
  },

  entry_exits: {
    component: "InputNumber",
    label: "Entry / Exits",
    description: "How many entries are there?",
    min: 0,
    max: 30,
  },

  non_baths: {
    component: "InputNumber",
    label: "Other",
    description:
      "How many other locations are there? Please enter a description.",
    min: 0,
    max: 30,
  },

  description: {
    component: "InputEditor",
    label: "Other description, Eg Next to Basin, Next to Full Length Mirror.",
  },

  nappy_changes: {
    component: "InputNumber",
    label: "Nappy Change Areas",
    description:
      "How many nappy change Areas are there? (Look for the number of grooves)",
    min: 0,
    max: 30,
  },

  breast_feedings: {
    component: "InputNumber",
    label: "Breast Feeding Cubicles",
    description: "How many Breast Feeding Cubicles are there?",
    min: 0,
    max: 30,
  },

  kitchenette: {
    component: "InputNumber",
    label: "Kitchenette",
    min: 0,
    max: 30,
  },
};

export default {
  components: {
    Form: () => {
      return import("./index.vue");
    },
  },

  data: function () {
    return {
      processing: false,
      currentForm: {},
      status: null,
      bathrooms: [],
      buttonText: "Save Bathroom",
    };
  },

  props: {
    venue_id: {},
    form: {},
  },

  watch: {},

  computed: {
    computedSchema: function () {
      let s = schema;
      let venue = this.$store.getters["venues/one"](this.venue_id);

      // let options = sites.map((site) => {
      //   return {
      //     key: site.name,
      //     value: site.site_id,
      //   };
      // });

      // s.site_id.options = options;
      let params = {
        venue_id: this.venue_id || -1,
      };
      // get the first contact for this venue
      let bathroom_category = this.currentForm.bathroom_category || null;

      if (!bathroom_category) {
        bathroom_category = 0;
      }

      // female
      if (bathroom_category === 0) {
        s.hand_dryers.disabled = false;
        s.cubicles.disabled = false;
        s.urinals.disabled = true;
        s.non_baths.disabled = false;
        s.nappy_changes.disabled = true;
        s.breast_feedings.disabled = true;
        s.kitchenette.disabled = true;
        s.entry_exits.disabled = false;
      }

      // male
      if (bathroom_category === 1) {
        s.hand_dryers.disabled = false;
        s.cubicles.disabled = false;
        s.urinals.disabled = false;
        s.non_baths.disabled = false;
        s.nappy_changes.disabled = true;
        s.breast_feedings.disabled = true;
        s.kitchenette.disabled = true;
        s.entry_exits.disabled = false;
      }

      // disabled
      if (bathroom_category === 2) {
        s.hand_dryers.disabled = false;
        s.cubicles.disabled = true;
        s.urinals.disabled = true;
        s.non_baths.disabled = true;
        s.nappy_changes.disabled = false;
        s.breast_feedings.disabled = true;
        s.kitchenette.disabled = true;
        s.entry_exits.disabled = false;
      }

      // parents
      if (bathroom_category === 3) {
        s.hand_dryers.disabled = false;
        s.cubicles.disabled = true;
        s.urinals.disabled = true;
        s.non_baths.disabled = false;
        s.nappy_changes.disabled = false;
        s.breast_feedings.disabled = false;
        s.kitchenette.disabled = false;
        s.entry_exits.disabled = false;
      }

      // unisex
      if (bathroom_category === 4) {
        s.hand_dryers.disabled = false;
        s.cubicles.disabled = false;
        s.urinals.disabled = true;
        s.non_baths.disabled = false;
        s.nappy_changes.disabled = true;
        s.breast_feedings.disabled = true;
        s.kitchenette.disabled = true;
        s.entry_exits.disabled = false;
      }

      // non-bathrooms
      if (bathroom_category === 5) {
        s.hand_dryers.disabled = true;
        s.cubicles.disabled = true;
        s.urinals.disabled = true;
        s.non_baths.disabled = false;
        s.nappy_changes.disabled = true;
        s.breast_feedings.disabled = true;
        s.kitchenette.disabled = true;
        s.entry_exits.disabled = true;
      }

      if (this.currentForm.non_baths && this.currentForm.non_baths > 0) {
        s.description.disabled = false;
      } else {
        s.description.disabled = true;
      }

      // if (venue) {
      //   s.site.parentId = venue.main_category_id;
      // }

      this.$forceUpdate();

      return s;
    },
  },

  methods: {
    reset: function () {},
    saveStatus: function (saveStatus) {
      this.status = saveStatus;
    },
    async onSubmit() {
      this.$refs.form.validate();

      if (this.status.invalid) {
        return;
      }

      this.buttonText = "Saving Bathroom...";

      this.processing = true;

      let form = this.currentForm;

      let newBathroom = {
        ...form,
        venue_id: this.venue_id,
      };

      // only update video if a new video is created
      if (
        newBathroom.walkthrough_video &&
        typeof newBathroom.walkthrough_video === "number"
      ) {
        delete newBathroom.walkthrough_video;
      }

      // set a default name
      let cat = newBathroom.bathroom_category;

      let s = this.computedSchema;

      let options = s.bathroom_category.options;

      let tempName = "";

      for (let i = 0; i < options.length; i++) {
        if (options[i].value === cat) {
          tempName = options[i].key;
        }
      }

      // remove legacy fields
      delete newBathroom.default_tag;
      delete newBathroom.default_action;
      delete newBathroom.external_reference;
      delete newBathroom.additional_data;
      delete newBathroom.displayPoints; // API returns displayPoints when we fetch the bathroom, but it can't accept it back when we update

      // only append the category if they are both seperate strings
      if (!newBathroom.name.includes(tempName)) {
        newBathroom.name = `${newBathroom.name} ${tempName}`;
      }

      let cancelled = false;

      newBathroom.onUploadProgress = (progressEvent) => {
        if (cancelled) {
          return;
        }

        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        if (percentCompleted === 100) {
          this.buttonText = "Processing Bathroom Data...";
        } else {
          this.buttonText = `Uploading Bathroom Data... (${percentCompleted}%)`;
        }
      };

      let response = await this.$store
        .dispatch("venues/updateBathroom", newBathroom)
        .catch((err) => {
          if (err.response && err.response.data) {
            this.errors = err.response.data;
          }

          this.$store.dispatch("message/onFail", this.errors);
        })
        .finally(() => {
          cancelled = true;
          this.buttonText = "Save Bathroom";
        });

      this.processing = false;

      if (!response) {
        return;
      }

      this.$store.dispatch("message/onBathroomUpdated");

      this.$emit("onSubmit", form);
    },
  },

  created: function () {
    if (this.form) {
      this.currentForm = this.form;

      if (this.currentForm.other_dps) {
        this.currentForm.non_baths = this.currentForm.other_dps;
        delete this.currentForm.other_dps;
      }
    }
  },
};
</script>

<style></style>
