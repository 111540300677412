let store = {
  namespaced: true,
  state: {
    messages: {
      serverError: {
        message: "Something went server side",
      },
      clientError: {
        message: "Couldn't handle your request",
      },
      genericError: {
        message: "Something went wrong",
      },
    },

    message: null,
    messageTrigger: false,
  },
  getters: {
    message: function (state) {
      return state.message;
    },
    messageTrigger: function (state) {
      return state.messageTrigger;
    },
  },
  mutations: {
    SET_MESSAGE: function (state, message) {
      state.message = message;
    },
    TRIGGER: function (state) {
      state.messageTrigger = !state.messageTrigger;
    },
  },
  actions: {
    async init(store) {},

    async sendMessage(store, name) {
      let message = null;
      if (typeof name === "string") {
        message = store.state.messages[name];
      } else {
        message = name;
      }
      store.commit("SET_MESSAGE", message);
      store.commit("TRIGGER");
    },

    // 400, 401, etc
    async onServerError(store, err) {
      store.dispatch("sendMessage", "serverError");
    },

    // timeout, etc
    async onClientError(store, err) {
      store.dispatch("sendMessage", "clientError");
    },

    // When yolo
    async onGenericError(store, err) {
      store.dispatch("sendMessage", "genericError");
    },

    async onLogin(store) {
      let userName = store.rootGetters["users/userName"];
      let message = {
        message: `Welcome back ${userName}`,
        status: "success",
      };
      store.dispatch("sendMessage", message);
    },

    async onSignup(store) {
      let user = store.rootGetters["users/user"];
      let message = {
        message: `Welcome ${user.name}!`,
        status: "success",
      };
      store.dispatch("sendMessage", message);
    },

    async onLogout(store) {
      let user = store.rootGetters["users/user"];
      let message = {
        message: `See you soon ${user.name}`,
      };
      store.dispatch("sendMessage", message);
    },

    async onPasswordReset(store) {
      let message = {
        message: `Your password has been reset successfully!`,
        status: "success",
      };
      store.dispatch("sendMessage", message);
    },

    async onShowWidget(store) {
      let message = {
        timer: 2500,
        message: `Crop tool is active. Drag to select an area. Press Esc to disable it.`,
      };
      store.dispatch("sendMessage", message);
    },

    async onCropInactive(store) {
      let message = {
        timer: 2500,
        message: `Crop tool is disabled.`,
      };
      store.dispatch("sendMessage", message);
    },

    async onExtensionAuth(store) {
      let message = {
        status: "success",
        message: `Swipekit extension is authenticated!`,
      };
      store.dispatch("sendMessage", message);
    },

    async onAssetError(store, err) {
      let message = {
        timer: 3000,
        status: "failure",
        message: `Screenshot couldn't be saved :( Please try again`,
      };
      store.dispatch("sendMessage", message);
    },

    async onCameraPermissionDeniedError(store) {
      let message = {
        timer: 5000,
        status: "failure",
        message: `Error accessing the device camera - camera access is not allowed. Please ensure you have allowed camera permissions.`,
      };
      store.dispatch("sendMessage", message);
    },

    async onCameraNotFoundError(store) {
      let message = {
        timer: 4000,
        status: "failure",
        message: `Error accessing the device camera - no camera was found. Please ensure you have a camera connected.`,
      };
      store.dispatch("sendMessage", message);
    },

    async onCameraAccessError(store) {
      let message = {
        timer: 3000,
        status: "failure",
        message: `Error accessing the device camera.`,
      };
      store.dispatch("sendMessage", message);
    },

    async onAssetSaved(store, obj) {
      let message = {
        message: `Screenshot saved!`,
        timer: 2000,
        status: "success",
      };
      store.dispatch("sendMessage", message);
    },

    async onAssetSaving(store, obj) {
      let message = {
        message: `Saving screenshot..`,
        timer: 2000,
      };
      store.dispatch("sendMessage", message);
    },

    async onBoardCreate(store) {
      let message = {
        message: `New board created!`,
        status: "success",
      };
      store.dispatch("sendMessage", message);
    },

    async onBulkUpdate(store) {
      let message = {
        message: `Items have been bulk updated`,
        status: "success",
      };
      store.dispatch("sendMessage", message);
    },

    async onUserUpdate(store) {
      let message = {
        message: `Your settings have been updated`,
        status: "success",
      };
      store.dispatch("sendMessage", message);
    },

    async onAcceptInvite(store) {
      let message = {
        message: `Welcome ${store.getters["users/user"].name}!`,
        status: "success",
      };
      store.dispatch("sendMessage", message);
    },

    async onUserInvited(store) {
      let message = {
        message: `Teammate invited to Workspace!`,
        status: "success",
      };
      store.dispatch("sendMessage", message);
    },

    async onVenueUpdated(store) {
      let message = {
        message: `Venue detail has been updated!`,
        status: "success",
      };
      store.dispatch("sendMessage", message);
    },

    async onSiteUpdated(store) {
      let message = {
        message: `Area has been updated!`,
        status: "success",
      };
      store.dispatch("sendMessage", message);
    },

    async onSiteCreated(store) {
      let message = {
        message: `New area created!`,
        status: "success",
      };
      store.dispatch("sendMessage", message);
    },

    async onBathroomCreated(store) {
      let message = {
        message: `New bathroom created!`,
        status: "success",
      };
      store.dispatch("sendMessage", message);
    },

    async onBathroomUpdated(store) {
      let message = {
        message: `Bathroom updated!`,
        status: "success",
      };
      store.dispatch("sendMessage", message);
    },

    async onContractCreated(store) {
      let message = {
        message: `Contract signed!`,
        status: "success",
      };
      store.dispatch("sendMessage", message);
    },

    async onFail(store, errors) {
      if (!errors) {
        errors = [];
      }
      if (typeof errors === "string") {
        errors = [];
      }
      errors = errors.map((err) => {
        return err.message || "";
      });
      let message = {
        message: `Form submission failed. Errors: ${errors.join(",")}`,
        status: "failure",
      };
      store.dispatch("sendMessage", message);
    },

    async onContactUpdated(store) {
      let message = {
        message: `Contact updated!`,
        status: "success",
      };
      store.dispatch("sendMessage", message);
    },
  },
};

export default store;
