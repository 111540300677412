function hasKeyInArray(arr, slug) {
  let indexObj = null;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].slug === slug) {
      indexObj = arr[i];
      break;
    }
  }

  return indexObj;
}

function venueToDay(venue) {
  venue = JSON.parse(JSON.stringify(venue));
  let daysStrs = ["sun", "mon", "tue", "wed", "thur", "fri", "sat"];
  let daysLongStrs = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  let days = {};
  let selected = [];
  for (const p in venue) {
    for (let i = 0; i < daysStrs.length; i++) {
      let day = daysStrs[i];

      if (venue[p] && p.startsWith(day)) {
        let concatedProp = p.replace(day + "_", "");
        if (days[day]) {
        } else {
          days[day] = {
            i: i,
          };
        }
        days[day][concatedProp] = venue[p];

        let indexObj = hasKeyInArray(selected, day);
        let temp = null;

        if (!indexObj) {
          temp = {
            slug: day,
            name: daysLongStrs[i],
          };
          temp[concatedProp] = venue[p];
          selected.push(temp);
        } else {
          indexObj[concatedProp] = venue[p];

          selected = selected.map((sel) => {
            if (sel.slug === indexObj.slug) {
              sel = indexObj;
            }
            return sel;
          });
        }
        // remove the key from venue
        delete venue[p];
      }
    }
  }

  venue.days = selected;

  return venue;
}

export default venueToDay;
