<template>
  <div class="c-profile">
    <PageHeader></PageHeader>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <Venues></Venues>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@swipekit/components/app/page-header.vue";

import Venues from "@swipekit/components/app/venues";

export default {
  name: "Home",
  components: {
    PageHeader,

    Venues,
  },

  data: function () {
    return {
      processing: {},
    };
  },

  computed: {},

  methods: {},
};
</script>

<style lang="scss">
.c-venues {
  padding-bottom: var(--spacer);

  .container {
    padding: 0 var(--spacer-sm);
    margin: 0 auto;
  }

  &__header {
    padding-top: var(--spacer);
  }
}
</style>
